import React, { useState, useEffect } from "react";
import Bolt from "../../assets/bolt.png";
import { Fireplace } from "@material-ui/icons";
import thunder from "../../assets/thunder.svg";
import chart from "../../assets/chart.svg";
import Fire from "../../assets/fire.svg";

const ThreeBannerResponsive = ({ bannerType }) => {
  const [showChartNum, setshowChartNum] = useState(1);
  useEffect(() => {
    setshowChartNum(bannerType);
  }, [bannerType]);
  return (
    <>
      <div
        className="row d-flex w-100 flex-row mx-0 my-3 customerBuyClass"
        style={{
          border:
            "solid 1px " +
            (showChartNum == 1
              ? "orange"
              : showChartNum == 2
              ? "green"
              : showChartNum == 3
              ? "red"
              : "red"),
          borderRadius: 7,
        }}
      >
        <div className="col-2 h-100 d-flex align-items-center justify-content-center">
          {showChartNum === 1 && (
            <img
              src={thunder}
              style={{ height: "53px", objectFit: "contain" }}
              alt="fire"
            />
          )}
          {showChartNum === 2 && (
            <img
              src={chart}
              style={{ height: "53px", objectFit: "contain" }}
              alt="fire"
            />
          )}
          {showChartNum === 3 && (
            <img
              src={Fire}
              style={{ height: "53px", objectFit: "contain" }}
              alt="fire"
            />
          )}
        </div>
        <div className="col-10 d-flex justify-content-center align-items-start flex-column">
          <p className="m-0" style={{ fontSize: "1rem" }}>
            <b>
              {showChartNum === 1 && "This product is selling fast"}
              {showChartNum === 2 && "Price rising"}
              {showChartNum === 3 && "Hot right now"}
            </b>
          </p>
          <span style={{ fontSize: "0.8rem" }}>
            {showChartNum === 1 && "There is high demand for this item"}
            {showChartNum === 2 && "The price increased by 33% this month"}
            {showChartNum === 3 && "There is high demand for this item"}
          </span>
        </div>
      </div>
    </>
  );
};

export default ThreeBannerResponsive;
