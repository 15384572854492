import React, { useState, useEffect } from "react";
import "./login-signup-page.styles.scss";

import Login from "../../components/login/login.component";
import Signup from "../../components/signup/signup.component";

export const LoginSignupPage = () => {
  const [loginSelected, toggleLoginSelected] = useState(
    !window.location.search.includes("signup")
  );

  return (
    <div className="login-signup-container">
      <div className="box-container">
        <div className="button-container">
          <button
            onClick={() => toggleLoginSelected(true)}
            className={loginSelected ? "active" : "in-active"}
          >
            Log In
          </button>
          <button
            onClick={() => toggleLoginSelected(false)}
            className={!loginSelected ? "active" : "in-active"}
          >
            Sign Up
          </button>
        </div>
        {loginSelected ? <Login /> : <Signup />}
      </div>
    </div>
  );
};
