import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Actions from "./../Actions";

const initialState = {
  // user: window.localStorage.getItem("user")
  //   ? JSON.parse(window.localStorage.getItem("user"))
  //   : null,
  user: null,
};

const AuthReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (!!state.user) {
    let initialStateEvent = new CustomEvent("onThrillerUserLoaded", {
      detail: state.user,
    });
    document.dispatchEvent(initialStateEvent);
  }

  if (type === Actions.SAVE_USER) {
    // window.localStorage.setItem("user", JSON.stringify(payload));

    let updateEmailEvent = new CustomEvent("onThrillerUserLoaded", {
      detail: payload,
    });
    document.dispatchEvent(updateEmailEvent);

    return {
      user: payload,
    };
  } else if (type === Actions.UPDATE_USER) {
    let updatedUser = {
      ...state.user,
      ...payload,
    };

    // window.localStorage.setItem("user", JSON.stringify(updatedUser));
    console.log("PAYLOAD:", payload);
    if ("email" in payload) {
      let updateEmailEvent = new CustomEvent("onThrillerUserLoaded", {
        detail: updatedUser,
      });
      document.dispatchEvent(updateEmailEvent);
    }

    return {
      user: updatedUser,
    };
  } else if (type === Actions.LOGOUT) {
    // window.localStorage.removeItem("user");

    return {
      user: null,
    };
  }

  return state;
};

export default AuthReducer;
