import React, { useState } from "react";
import "./login.styles.scss";
import { Row, Col } from "react-simple-flex-grid";
import "react-simple-flex-grid/lib/main.css";
import { CustomButton } from "../custom-button/custome-button.component";

import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router";
import { validateEmail, validatePassword } from "../../Constants/Functions";
import * as Actions from "../../Redux/Actions";
import { connect } from "react-redux";
import swal from "sweetalert";
import facebook from "../../assets/Home/facebook.jpg";
import insta from "../../assets/Home/insta.png";
import twitter from "../../assets/Home/twitter.png";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useHistory,
} from "react-router-dom";

import {
  getPayout,
  getRegistrations,
  getSellers,
  registrationsLogin,
} from "../../utils/api";

const Login = ({ history, user, setUser }) => {
  let sellVar = useParams().sell;

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasShipping, setHasShipping] = useState(false);
  const [hasPayout, setHasPayout] = useState(false);
  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
  });
  const [fieldStatus, setFieldStatus] = useState({
    email: true,
    password: true,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserCredentials({ ...userCredentials, [name]: value });
  };

  const activateField = (event) => {
    const { name } = event.target;
    setFieldStatus({ ...fieldStatus, [name]: true });
  };

  const initialSetup = async () => {
    return Promise.all([
      getRegistrations(user.id)
        .then((response) => {
          if (response.isAuthenticated === 1) {
            setIsAuthenticated(true);
          }
        })
        .catch((error) => {
          console.error(error);
        }),

      getSellers(user.id)
        .then((response) => {
          if (response !== "") {
            setHasShipping(true);
          }
        })
        .catch((error) => {
          console.error(error);
        }),

      getPayout(user.id)
        .then((response) => {
          if (response !== "") {
            setHasPayout(true);
          }
        })
        .catch((error) => {
          console.error(error);
        }),
    ]);
  };

  const setupChecks = async () => {
    await initialSetup();

    getRegistrations(user.id)
      .then((response) => {
        if (!response.isAuthenticated) {
          history.push({
            pathname: "/twoFactorAuth",
            state: {
              hasShipping: hasShipping,
              hasPayout: hasPayout,
              historyShoe: true,
            },
          });
        } else if (!hasShipping) {
          history.push({
            pathname: "/shippingInfo/1",
            state: {
              hasPayout: hasPayout,
              historyShoe: true,
            },
          });
        } else if (!hasPayout) {
          history.push({
            pathname: "/payoutInfo",
            state: {
              historyShoe: true,
            },
          });
        } else {
          history.push("/sell");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const login = async () => {
    const { email, password } = userCredentials;

    if (email === "" || password === "") {
      swal({
        title: "Error!",
        text: "Please Enter the Required Credentials",
        icon: "error",
      });
      return;
    }
    if (!validateEmail(email)) {
      swal({
        title: "Error!",
        text: "Please Enter the Correct Email Address",
        icon: "error",
      });
      return;
    }
    if (password.length < 6) {
      swal({
        title: "Error!",
        text: "Your Password Must have atleast six digits",
        icon: "error",
      });
      return;
    }
    if (!validatePassword(password)) {
      swal({
        title: "Error!",
        text: "Please Enter the Correct Password",
        icon: "error",
      });
      return;
    }

    setLoading(true);
    registrationsLogin(userCredentials)
      .then((response) => {
        setUser(response);

        setTimeout(() => {
          if (sellVar === "1") {
            setupChecks();
          } else {
            if (
              history.location.state !== undefined &&
              (history.location.state.historyProduct !== undefined ||
                history.location.state.historyProduct !== null) &&
              history.location.state.historyProduct
            ) {
              let size = history.location.state.size;
              let product = history.location.state.productId;
              history.push(`/buy/${product}/${size}/0`);
            } else {
              history.goBack();
            }
          }
        }, 500); //Not trigged in first attempt
      })
      .catch((error) => {
        console.error("LOGIN:", error);
        swal({
          title: "Error!",
          text: "Invalid credentials, please try again.",
          icon: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="login-container">
      {!loading ? (
        <div className="login-form">
          <div className="form-container">
            <label
              className={
                fieldStatus.email || userCredentials.email
                  ? "float-label"
                  : null
              }
              htmlFor={1}
            >
              Email Address
            </label>
            <input
              id={1}
              name="email"
              type="email"
              onChange={handleChange}
              onFocus={activateField}
              placeholder="Enter your email"
              value={userCredentials.email}
            />
          </div>
          <div className="form-container">
            <label
              className={
                fieldStatus.password || userCredentials.password
                  ? "float-label"
                  : null
              }
              htmlFor={2}
            >
              Password
            </label>
            <input
              id={2}
              name="password"
              type="password"
              onChange={handleChange}
              onFocus={activateField}
              placeholder="Enter your password"
              value={userCredentials.password}
            />
          </div>
          <CustomButton size="large" onClick={login}>
            Log in
          </CustomButton>
          <Row align="middle">
            <Col span={12}>
              <a href="https://www.facebook.com/ThrillerMiddleEast/?__tn__=-UC*F">
                <img src={facebook} style={{ width: 40 }} />
              </a>
              <a href="https://twitter.com/THRILLER_ME">
                <img src={twitter} style={{ width: 40 }} />
              </a>
              <a href="https://www.instagram.com/thrillerme/?hl=en">
                <img src={insta} style={{ width: 33 }} />
              </a>
            </Col>
          </Row>
        </div>
      ) : (
        <CircularProgress color="inherit" />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (data) => {
      dispatch({ type: Actions.SAVE_USER, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
