import * as Actions from "./../Actions";

const initialState = {
  products: [],
  brands: [],
  collections: [],
};

const ProductsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === Actions.SET_PRODUCTS) {
    return {
      ...state,
      products: payload,
    };
  } else if (type === Actions.SET_BRANDS) {
    return {
      ...state,
      brands: payload,
    };
  } else if (type === Actions.SET_COLLECTIONS) {
    return {
      ...state,
      collections: payload,
    };
  }

  return state;
};

export default ProductsReducer;
