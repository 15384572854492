import React, { useState, useEffect } from "react";
import "./sell-page.styles.scss";

import SearchResult from "../../components/search-result/search-result.component";
import Footer from "../../components/footer/Footer";
import Links from "../../components/links/Links";
import { makingValidName } from "../../Constants/Functions";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchItemComponent from "../../components/search-item/search-item.component";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SellPage = ({ allProducts }) => {
  const location = useLocation();

  const [searchValue, setSearchValue] = useState("");
  const [shoes, setShoes] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isSearching, setSearching] = useState(false);

  useEffect(() => {
    setSearching(true);

    var enterdValue = makingValidName(`${searchValue}`);
    setShoes(
      allProducts?.filter(
        (dat, index) =>
          makingValidName(`${dat.name}`)?.includes(enterdValue) ||
          makingValidName(`${dat.name}`) === enterdValue ||
          makingValidName(`${dat.colorway}`)?.includes(enterdValue) ||
          makingValidName(`${dat.colorway}`) === enterdValue ||
          makingValidName(`${dat.sku_number}`)?.includes(enterdValue) ||
          makingValidName(`${dat.sku_number}`) === enterdValue
      )
    );
    setSearching(false);
  }, [searchValue, allProducts]);

  useEffect(() => {
    setItems(filterProducts.slice(0, 20));
  }, [filterProducts]);

  const handleChange = async (e) => {
    setSearchValue(e.target.value);

    var enterdValue = makingValidName(`${e.target.value}`);
    setFilterProducts(
      allProducts?.filter(
        (dat, index) =>
          makingValidName(`${dat.name}`)?.includes(enterdValue) ||
          makingValidName(`${dat.name}`) === enterdValue ||
          makingValidName(`${dat.colorway}`)?.includes(enterdValue) ||
          makingValidName(`${dat.colorway}`) === enterdValue ||
          makingValidName(`${dat.sku_number}`)?.includes(enterdValue) ||
          makingValidName(`${dat.sku_number}`) === enterdValue
      )
    );
  };

  const fetchMoreData = () => {
    if (items.length >= filterProducts.length) {
      setHasMore(false);
    }

    setTimeout(() => {
      setItems((prevItems) => [
        ...prevItems,
        ...filterProducts.slice(prevItems.length, prevItems.length + 20),
      ]);
    }, 500);
  };

  return (
    <>
      <div className="container sell-container">
        <div className="secondary-container">
          <div className="text-container">
            <h3>Choose a product</h3>
          </div>
          <hr></hr>
        </div>
        <div className="search-container-sell">
          <input placeholder="Search..." onChange={handleChange} />
          <FontAwesomeIcon icon={["fa", "search"]} className="search" />
          {searchValue.length > 0 ? (
            <InfiniteScroll
              dataLength={items.length}
              next={fetchMoreData}
              hasMore={hasMore}
              height={400}
              loader={
                <div className="d-flex justify-content-center bg-white align-items-center py-4">
                  <FontAwesomeIcon icon={["fa", "circle-notch"]} spin />
                </div>
              }
            >
              {items.map((item, index) => (
                <SearchItemComponent
                  key={item.shoe_id}
                  shoe={item}
                  linkCheck={false}
                />
              ))}
            </InfiniteScroll>
          ) : null}
          {isSearching ? (
            <i
              className="fas fa-circle-notch fa-spin"
              style={{
                textAlign: "center",
                position: "absolute",
                right: "-90%",
              }}
            ></i>
          ) : null}
        </div>
      </div>
      <a href="mailto:thefuture@thrillerme.com">
        <div className="bottomx">
          <p className="bottom">Can't find your product?</p>
        </div>
      </a>
      <Links />
      <Footer />
    </>
  );
};
