import React from "react";
import { Link } from "react-router-dom";
import { BLOGS_BASE_URL } from "../../../Constants/Global";

import "./GuideBlogSlide.scss";

export default function GuideBlogSlide({ blog }) {
  const { Heading = "", Author: author, thumbnail, slug = "" } = blog;

  let banner = null;
  let bannerAlt = "Blog Cover";

  if (thumbnail) {
    banner = `${BLOGS_BASE_URL}${thumbnail.data.attributes.url}`;
    bannerAlt = thumbnail.data.attributes.name;
  }

  return (
    <Link to={`/blogs/${slug}`} className="buyers-blog-slide">
      <div className="image-wrapper">
        <img
          // src="/images/loading_image.svg"
          // data-splide-lazy={banner}
          src={banner}
          alt={bannerAlt}
        />
      </div>
      <p>{Heading}</p>
    </Link>
  );
}
