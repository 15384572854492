import React, { Fragment, useState } from "react";
import "./SizeBox.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../Constants/Global";
import { connect } from "react-redux";
// import localStorage from "redux-persist/es/storage";

const SizeBox = ({
  user,
  size,
  price,
  setSizeChart,
  id,
  shoe,
  fav,
  setSizechartFav,
}) => {
  let history = useHistory();
  let lowestAsk = 0;
  async function GetLowsetAsk(sizz) {
    var urlL = `${BASE_URL}listing/lowest/${id}/${sizz}`;
    var encodedURLL = encodeURI(urlL);
    await axios.get(encodedURLL).then((res) => {
      if (res.data.lowest !== null) {
        lowestAsk = res.data.lowest;
      }
    });
  }

  function GoToBuy() {
    GetLowsetAsk(size);
    setTimeout(() => {
      window.localStorage.setItem("price", lowestAsk);
      history.push(`/buy/${id}/${size}/${lowestAsk}`);
    }, 1000);
    setSizeChart(false);
  }

  function GoToFav() {
    if (user !== null || user !== undefined) {
      var userID = JSON.parse(user).user_id;
      const data = {
        shoe_id: shoe.shoe_id,
        user_id: userID,
        size: size,
      };

      axios
        .post(`${BASE_URL}fav`, data)
        .then((res) => {
          window.location.reload();
          setSizechartFav(false);
        })
        .catch((err) => {
          alert("Something Went Wrong");
        });
    } else {
      history.push("/login");
    }
    setSizechartFav(false);
  }

  return (
    <div>
      {fav ? (
        <div className="size-box-container" onClick={GoToFav}>
          <span>{size}</span>
          {price > 0 ? (
            <span style={{ fontSize: "10px", color: "red" }}>
              {Math.floor(price)}
            </span>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="size-box-container" onClick={GoToBuy}>
          <span>{size}</span>
          {price > 0 ? (
            <span style={{ fontSize: "10px", color: "red" }}>
              AED {Math.floor(price)}
            </span>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SizeBox);
