import React from "react";
import moment from "moment";

import Author from "../Author";

export default function BlogHeadingSection({
  publishDate,
  updatedAt,
  heading,
  author,
}) {
  return (
    <div className="main">
      <div className="container">
        <div className="content">
          <div className="content-block">
            <div className="titles">
              <p className="publish-date headline headline--6">
                {moment(publishDate).format("MMMM D, YYYY")}
              </p>
              <p className="last-updated headline headline--6">
                Last updated on {moment(updatedAt).format("MMMM D, YYYY")}
              </p>
              <h1 className="headline headline--1">{heading}</h1>
            </div>
            <Author author={author?.data?.attributes} />
          </div>
          <div className="content-image"></div>
        </div>
      </div>
    </div>
  );
}
