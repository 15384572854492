import React, { useState, useEffect } from "react";
import "./buying-history-table.styles.scss";
import axios from "axios";
import SettingItem from "../setting-item/setting-item.component";

import SHOE_DATA from "../../temporary-data/shoe-data";
import { BASE_URL } from "../../Constants/Global";
import { connect } from "react-redux";

const BuyingHistoryTable = ({ searchValue, user }) => {
  const [buy, setBuy] = useState([]);

  useEffect(() => {
    var url = BASE_URL + `orders/buyer/${user.user_id}`;
    axios
      .get(encodeURI(url))
      .then((res) => {
        setBuy(res.data);
      })
      .catch((error) => {
        //console.error(error);
      });
  }, []);

  return (
    <div className="buying-history-table-container">
      <div className="table-header">
        <p className="heading-title">Item</p>
        <p className="heading-title tier-two">Order Number</p>
        <p className="heading-title tier-two">Purchase Date</p>
        <p className="heading-title">Price</p>
        <p className="heading-title tier-two">Status</p>
      </div>
      {searchValue === "" && buy
        ? buy.map((shoe, ind) => (
            <SettingItem history={true} key={shoe.id} shoe={shoe} />
          ))
        : null}

      {searchValue !== "" &&
        buy !== null &&
        buy
          .filter((shoe) =>
            shoe.name
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase())
              ? shoe
              : null
          )
          .map((shoe, ind) => (
            <SettingItem history={true} key={shoe.id} shoe={shoe} />
          ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    banner1: state.banners.banner1,
    banner2: state.banners.banner2,
  };
};
export default connect(mapStateToProps)(BuyingHistoryTable);
