import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import Links from "../../components/links/Links";
import CustomRecentcs from "../../components/recent/CustomRecentcs";
import NewCustomRecentcs from "../../components/recent/NewCustomRecentcs";
import SlideShow from "../../components/slideshow/Slideshow";
import MultiBrandsRecentcs from "../../components/recent/MultiBrandsRecentcs";
import { getBanners } from "../../utils/api";
import * as Actions from "../../Redux/Actions";
import "./home-page.styles.scss";

const HomePage = ({
  allBrands,
  allProducts,
  allCollections,
  setBanners,
  banner1,
  banner2,
}) => {
  const newhistory = useHistory();
  const topGreyNavigation = [
    { to: "collections/nike/", title: "Nike" },
    { to: "collections/jordan/", title: "Jordan" },
    { to: "collections/adidas/", title: "Adidas" },
    { to: "collections/supreme/", title: "Supreme" },
    { to: "collections/hoodies/", title: "Hoodies" }, // TODO: hoodies
    { to: "collections/travis-scott/", title: "Travis Scott" },
  ];

  useEffect(() => {
    getBanners()
      .then((response) => {
        if (Array.isArray(response)) {
          setBanners(response);
        }
      })
      .catch((error) => {
        console.error("getBanners():", error);
      });
  }, [setBanners]);

  const setLocalStorage = (name, to) => {
    window.localStorage.setItem("selectedSection", JSON.stringify(name));
    newhistory.push(to);
  };

  return (
    <>
      <div className="greyNavigation">
        <div className="linkContainber">
          {topGreyNavigation.map((dat, index) => (
            <div
              className="custBtnsNav"
              key={index}
              onClick={() => setLocalStorage(`${dat.title}`, dat.to)}
            >
              {dat.title}
            </div>
          ))}
        </div>
      </div>
      <div className="col-lg-9 col-md-10 col-sm-11 mx-auto">
        <div className="home">
          <div className="mt-3 p-0 m-0 mainSliderHome">
            <SlideShow />
          </div>

          <NewCustomRecentcs
            title={"Just Dropped"}
            type={"tag"}
            tag={"Just Dropped"}
            toolTip={"The latest drops from retailers across the globe"}
            productData={allProducts}
          />

          <CustomRecentcs
            tag="Popular Brands"
            brands={allCollections}
            from={0}
            to={5}
          />

          <NewCustomRecentcs
            title={"Trending Sneakers"}
            toolTip={
              "'Trending Sneakers' products are a curated collection of our best selling items"
            }
            type={"tag"}
            tag={"Most Popular"}
            productData={allProducts}
          />

          <div>
            <Link to={banner1.buttonURL}>
              <img
                src={banner1.imageURL}
                style={{ width: "100%", marginTop: 40 }}
                alt={banner1.imageURL}
              />
            </Link>
          </div>

          <CustomRecentcs
            tag="Seasonal Favorites"
            brands={allCollections}
            from={5}
            to={10}
          />

          <NewCustomRecentcs
            title={"Featured Apparel"}
            type={"tag"}
            tag={"Featured"}
            toolTip={null}
            productData={allProducts}
          />

          <NewCustomRecentcs
            title={"Nike Dunk"}
            toolTip={null}
            tag={"Dunks"}
            productData={allProducts}
          />

          <div>
            <Link to={banner2.buttonURL}>
              <img
                src={banner2.imageURL}
                style={{ width: "100%", marginTop: 40 }}
                alt={banner2.imageURL}
              />
            </Link>
          </div>

          <CustomRecentcs
            tag="Designer Collection"
            brands={allCollections}
            from={10}
            to={15}
          />

          <NewCustomRecentcs
            title={"Travis Scott"}
            toolTip={null}
            tag={"Travis Scot"}
            productData={allProducts}
          />

          <NewCustomRecentcs
            title={"Collectibles Staff Picks"}
            type={"tag"}
            tag={"Popular"}
            productData={allProducts}
          />

          <MultiBrandsRecentcs
            allProducts={allProducts}
            allBrands={allBrands}
            tagsArry={[
              "Jordan",
              "Essentials",
              "Travis Scot",
              "Yeezy",
              "Off-White",
            ]}
          />

          <br />
          <br />
        </div>
      </div>
      <Links />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    banner1: state.banners.banner1,
    banner2: state.banners.banner2,
    products: state.products.products,
    brands: state.products.brands,
    collections: state.products.collections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBanners: (data) => {
      dispatch({ type: Actions.SET_BANNER, payload: data });
    },
    setProducts: (data) => {
      dispatch({ type: Actions.SET_PRODUCTS, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
