import React, { useState, useEffect } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import "./shoe-page.styles.scss";

import { CustomButton } from "../../components/custom-button/custome-button.component";
import { ShoeSizeList } from "../../components/shoe-size-list/shoe-size-list.component";
import ShoeSell from "../../components/shoe-sell/shoe-sell.component";

import { connect } from "react-redux";
import axios from "axios";
import { BASE_URL, LISTING } from "../../Constants/Global";

import LocalStorage from "redux-persist/es/storage";
import Footer from "../../components/footer/Footer";
import Links from "../../components/links/Links";
import ReactPixel from "react-facebook-pixel";
import swal from "sweetalert";

const ShoePage = ({ user, orderDetail }) => {
  var shoe = JSON.parse(window.localStorage.getItem("sellShoe"));
  const [displayShoeSize, toggleShoeDisplay] = useState([true, shoe]);
  const [buttonCheck, setCheck] = useState(false);
  // const [isAuthenticated, setAuthenticated] = useState(false);
  // const [hasShipping, setShipping] = useState(false);
  // const [hasPayout, setPayout] = useState(false);
  const [shoeSize, setShowSize] = useState(null);
  const [offer, setOffers] = useState({
    highest: 0,
    lowest: 0,
  });
  const [lowestAsk, setLowestAsk] = useState("0");
  const [locationKeys, setLocationKeys] = useState([]);
  const [hasPayout, sethasPayout] = useState(false);
  const [hasShipping, setHasShipping] = useState(false);
  const [md, setMD] = useState(false);

  const history = useHistory();


  useEffect(() => {
    console.log("shoe DETAIL:", shoe);
    initialSetup();
  }, []);

  const initialSetup = async () => {
    if (!user) {
      history.push({
        pathname: "/logins/1",
        state: {
          historyShoe: true,
        },
      });
    } else if (user?.isAuthenticated !== 1) {
      history.push({
        pathname: "/twoFactorAuth",
        state: {
          hasShipping: hasShipping,
          hasPayout: hasPayout,
          historyShoe: true,
        },
      });
      try {
        const res1 = await axios.get(`${BASE_URL}payout/${user.user_id}`);
        if (
          res1.data === null ||
          res1.data === "" ||
          res1.data === [] ||
          res1.data === {} ||
          res1.data.length <= 0
        ) {
          history.push({
            pathname: "/payoutInfo",
            state: {
              historyShoe: true,
            },
          });
        } else {
          try {
            const restt = await axios.get(`${BASE_URL}sellers/${user.user_id}`);
            if (
              restt.data === null ||
              restt.data === "" ||
              restt.data === [] ||
              restt.data === {} ||
              restt.data.length <= 0
            ) {
              history.push({
                pathname: "/shippingInfo/1",
                state: {
                  hasPayout: hasPayout,
                  historyShoe: true,
                },
              });
            }
          } catch (err) {
            console.log(err);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    try {
      if (
        window.localStorage.getItem("price") !== null &&
        window.localStorage.getItem("price") !== undefined
      )
        setLowestAsk(window.localStorage.getItem("price"));
    } catch (e) {}

    if (shoeSize !== null) {
      var url = `${BASE_URL}listing/highest/${shoe.shoe_id}/${shoeSize}`;
      var encodedURL = encodeURI(url);
      axios.get(encodedURL).then((res) => {
        if (res.data.highest !== null) {
          setOffers((prevState) => ({
            ...prevState,
            highest: res.data.highest,
          }));
        } else {
          setOffers((prevState) => ({
            ...prevState,
            highest: 0,
          }));
        }
      });

      var urlL = `${BASE_URL}listing/lowest/${shoe.shoe_id}/${shoeSize}`;
      var encodedURLL = encodeURI(urlL);
      axios.get(encodedURLL).then((res) => {
        if (res.data.lowest !== null) {
          setOffers((prevState) => ({
            ...prevState,
            lowest: res.data.lowest,
          }));
        } else {
          setOffers((prevState) => ({
            ...prevState,
            lowest: 0,
          }));
        }
      });
    }

    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle back event
          if (displayShoeSize[0]) {
            history.push("/sell");
          } else {
            toggleShoeDisplay([!displayShoeSize[0], displayShoeSize[1]]);
          }
        }
      }
    });
  }, [shoeSize, locationKeys, md]);

  function setShowSizeonHook(val) {
    console.log("setShowSizeonHook:", val);
    setShowSize(val);
  }

  const onClickNext = () => {
    console.log("BUTTON CHECK:", buttonCheck);
    setMD(false);
    if (shoeSize == null) {
      swal({
        title: "Error!",
        text: "Please Select a size",
        icon: "error",
      });
      return;
    }
    if (buttonCheck) {
      var url = `${BASE_URL}registrations/${user.user_id}`;
      axios.get(url).then((res) => {
        if (!res.data.isApproved) {
          alert(
            "Please wait, you can start selling once your account is approved."
          );
        } else {
          console.log("Sell now...", offer.highest);

          var date = new Date().toISOString().slice(0, 19).replace("T", " ");
          var expiry_date = new Date();
          expiry_date.setDate(expiry_date.getDate() + orderDetail.expiry);
          expiry_date = expiry_date
            .toISOString()
            .slice(0, 19)
            .replace("T", " ");

          var l_shipping, l_trans, l_processing, l_payout;

          axios.get(`${BASE_URL}settings`).then((res) => {
            var settings = res.data.result[0];
            axios
              .get(`${BASE_URL}sellers/` + user.user_id)
              .then((res) => {
                if (res.data.city !== undefined) {
                  if (
                    res.data.city !== "Dubai" &&
                    res.data.city !== "دبي" &&
                    res.data.city !== null
                  ) {
                    l_shipping = settings.deliveryIn;
                  } else {
                    l_shipping = settings.quidashIn;
                  }
                } else {
                  l_shipping = settings.quidashIn;
                }

                l_trans = (
                  offer.highest * (settings.marketplaceShare / 100).toFixed(4)
                ).toFixed(2);

                l_processing = (
                  offer.highest * (settings.processingFee / 100).toFixed(4)
                ).toFixed(2);

                l_payout = (
                  offer.highest -
                  l_trans -
                  l_processing -
                  l_shipping
                ).toFixed(2);

                var _isReturnable = 0;
                if (orderDetail.returnable) _isReturnable = 1;
                let data = {
                  seller_id: user.user_id,
                  shoe_id: orderDetail.shoe_id,
                  askingPrice: offer.highest,
                  size: shoeSize,
                  postedOn: date,
                  validTill: expiry_date,
                  expiry: orderDetail.expiry,
                  isAuthentic: 0,
                  transactionFee: l_trans,
                  processingFee: l_processing,
                  shippingFee: l_shipping,
                  payout: l_payout,
                  status: "available",
                  soldTo: null,
                  soldDate: null,
                  acceptedOffer_id: null,
                  isReturnable: _isReturnable,
                };

                console.log(data);
                //listing.post
                if (data.askingPrice < 25 || data.askingPrice === undefined) {
                  //console.log(data.askingPrice);
                  alert("Asking price should be atleast AED 25");
                } else {
                  axios
                    .post(LISTING, data)
                    .then((resListing) => {
                      console.log(resListing.data.insertId);

                      //Accept Offer
                      var url = `${BASE_URL}offers/getOffer/${data.shoe_id}/${data.size}/${offer.highest}`;

                      console.log(url);
                      axios
                        .get(url)
                        .then((res) => {
                          console.log(res);
                          var offer = res.data;
                          var offerData = {
                            productID: offer.shoe_id,
                            size: offer.size,
                            buyerID: offer.buyer_id,
                            sellerID: user.user_id,
                            price: offer.totalBill,
                            isAuthentic: 0,
                            notes: null,
                            status: "Pending",
                            quiqupJobID: null,
                            pickupState: null,
                            dropOffstate: null,
                            pickupTrackingURL: null,
                            dropOffTrackingURL: null,
                          };

                          //Capture payment
                          try {
                            console.log(
                              "#### accepting ####",
                              res.data.intentID
                            );
                            var url = `${BASE_URL}stripe/capture`;
                            axios
                              .post(url, {
                                intentID: res.data.intentID,
                              })
                              .then(
                                (response) => {
                                  console.log("#### capResp ####", response);
                                  //Make Order
                                  var urlOrders = `${BASE_URL}orders`;

                                  axios
                                    .post(urlOrders, {
                                      offerData: offerData,
                                      listing_id: resListing.data.insertId,
                                      offer_id: offer.offers_id,
                                      soldTo: offerData.buyerID,
                                      shipping: parseFloat(
                                        res.data.shippingFee
                                      ),
                                      vat: parseFloat(res.data.vat),
                                      processing: parseFloat(
                                        res.data.processingFee
                                      ),
                                      offerAmount: parseFloat(
                                        res.data.offerAmount
                                      ),
                                    })
                                    .then(
                                      (response) => {
                                        console.log("order", response.data);

                                        const advancedMatching = {
                                          em: "some@email.com",
                                        }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
                                        const options = {
                                          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                                          debug: true, // enable logs
                                        };
                                        ReactPixel.init(
                                          "3098857153686189",
                                          advancedMatching,
                                          options
                                        );

                                        ReactPixel.pageView(); // For tracking page view
                                        ReactPixel.trackSingleCustom(
                                          "3098857153686189",
                                          "Purchase",
                                          offerData
                                        ); // For tracking custom events.

                                        history.push("/selling-section");
                                      },
                                      (error) => {
                                        console.log("1", error);
                                      }
                                    );
                                },
                                (error) => {
                                  console.log("2",error);
                                }
                              );
                          } catch (error) { console.log("3",error);}
                        })
                        .catch((err) => {
                          console.log("4" ,err);
                        });
                    })
                    .catch((err) => {
                      alert("Something Went Wrong");
                    });
                }
              })
              .catch((e) => {
                console.error("settings", e);
              });
          });
        }
      });
    } else {
      var date = new Date().toISOString().slice(0, 19).replace("T", " ");
      var expiry_date = new Date();
      expiry_date.setDate(expiry_date.getDate() + orderDetail.expiry);
      expiry_date = expiry_date.toISOString().slice(0, 19).replace("T", " ");

      var url = `${BASE_URL}registrations/${user.user_id}`;

      axios.get(url).then((res) => {
        var url = `${BASE_URL}registrations/${user.user_id}`;

        axios.get(url).then((res) => {
          if (!res.data.isApproved) {
            alert(
              "Please wait, you can start selling once your account is approved."
            );
          } else {
            var l_shipping, l_trans, l_processing, l_payout;

            axios.get(`${BASE_URL}settings`).then((res) => {
              var settings = res.data.result[0];
              axios
                .get(`${BASE_URL}sellers/` + user.user_id)
                .then((res) => {
                  if (res.data.city !== undefined) {
                    if (
                      res.data.city !== "Dubai" &&
                      res.data.city !== "دبي" &&
                      res.data.city !== null
                    ) {
                      l_shipping = settings.deliveryIn;
                    } else {
                      l_shipping = settings.quidashIn;
                    }
                  } else {
                    l_shipping = settings.quidashIn;
                  }

                  l_trans = (
                    orderDetail.askingPrice *
                    (settings.marketplaceShare / 100).toFixed(4)
                  ).toFixed(2);

                  l_processing = (
                    orderDetail.askingPrice *
                    (settings.processingFee / 100).toFixed(4)
                  ).toFixed(2);

                  l_payout = (
                    orderDetail.askingPrice -
                    l_trans -
                    l_processing -
                    l_shipping
                  ).toFixed(2);

                  var _isReturnable = 0;
                  if (orderDetail.returnable) _isReturnable = 1;
                  let data = {
                    seller_id: user.user_id,
                    shoe_id: orderDetail.shoe_id,
                    askingPrice: orderDetail.askingPrice,
                    size: shoeSize,
                    postedOn: date,
                    validTill: expiry_date,
                    expiry: orderDetail.expiry,
                    isAuthentic: 0,
                    transactionFee: l_trans,
                    processingFee: l_processing,
                    shippingFee: l_shipping,
                    payout: l_payout,
                    status: "available",
                    soldTo: null,
                    soldDate: null,
                    acceptedOffer_id: null,
                    isReturnable: _isReturnable,
                  };

                  ////console.log(data);
                  //listing.post
                  if (data.askingPrice < 25 || data.askingPrice === undefined) {
                    //console.log(data.askingPrice);
                    alert("Asking price should be atleast AED 25");
                  } else {
                    axios
                      .post(LISTING, data)
                      .then((res) => {
                        //console.log(data.askingPrice);
                        axios
                          .post(`${BASE_URL}fav/email`, {
                            shoe_id: data.shoe_id,
                            size: data.size,
                            price: data.askingPrice,
                          })
                          .then((ress) => {
                            //console.log(ress);
                            history.push("/selling-section");
                          })
                          .catch((e) => {
                            console.error("favs emails", e);
                          });
                      })
                      .catch((err) => {
                        alert("Something Went Wrong");
                      });
                  }
                })
                .catch((e) => {
                  console.error("settings", e);
                });
            });
          }
        });
        // }
      });
      // }
    }
  };

  return (
    <div className="shoe-page">
      <div className="shoe-content">
        <div className="shoe-image">
          <div className="shoe-info">
            <h1>{shoe.name}</h1>
            <p>
              Highest Offer: <b>AED {offer.highest}</b> | Lowest Ask:{" "}
              <b>AED {offer.lowest}</b>
            </p>
          </div>
          <img
            src={shoe.cover_image}
            alt="item-img"
            style={{ maxHeight: "500px" }}
          />
        </div>
        <div className="shoe-size" style={{ maxWidth: "875px" }}>
          {displayShoeSize[0] ? (
            <ShoeSizeList
              shoe={shoe}
              displayShoeSize={displayShoeSize}
              toggleShoeDisplay={toggleShoeDisplay}
              parentCallBack={setShowSizeonHook}
            />
          ) : (
            <ShoeSell
              displayShoeSize={displayShoeSize}
              toggleShoeDisplay={toggleShoeDisplay}
              buttonCheck={buttonCheck}
              setCheck={setCheck}
              offer={offer}
            />
          )}
        </div>
      </div>
      <hr></hr>
      <div className="button-parent">
        <div className="bottomFAQ">
          <Link to="/FaqSeller">FAQ</Link>
        </div>
        <div className="void-div"></div>

        <div className="control-buttons">
          <CustomButton
            onClick={() => {
              if (displayShoeSize[0]) {
                history.push("/sell");
              } else {
                toggleShoeDisplay([!displayShoeSize[0], displayShoeSize[1]]);
              }
            }}
          >
            {displayShoeSize[0] ? "Cancel" : "Back"}
          </CustomButton>
          <CustomButton
            disabled={md}
            //listing.post here
            onClick={onClickNext}
          >
            Next
          </CustomButton>
        </div>
      </div>
      <Links />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    orderDetail: state.sellerOrder.shoesDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShoePage));
