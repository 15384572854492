import React, { useRef, useState, useEffect } from "react";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import BlogSlide from "./BlogSlide";

import "./RelatedBlogs.scss";

export default function RelatedBlogs({ blogs, className = "" }) {
  const splideRef = useRef();
  const [scrolling, setScrolling] = useState(null);

  useEffect(() => {
    if (scrolling) {
      const scroll = () => {
        const { Move } = splideRef.current.splide.Components;

        if (scrolling === "right") {
          Move.translate(Move.getPosition() - 2); // Scroll right by 1px
        } else if (scrolling === "left") {
          Move.translate(Move.getPosition() + 2); // Scroll left by 1px
        }
      };
      const intervalId = setInterval(scroll, 0.5); // Adjust speed
      return () => clearInterval(intervalId);
    }
  }, [scrolling]);

  return (
    <Splide
      ref={splideRef}
      hasTrack={false}
      tag="section"
      aria-label="Related Blogs"
      className={`related-blogs ${className}`}
      options={{
        perPage: 4,
        gap: "1rem",
        arrows: false,
        drag: "free",
        snap: false,
        pagination: false,
        type: "loop",
      }}
    >
      <div className="custom-wrapper">
        <div className="header">
          <h1>Related Blogs</h1>
        </div>
        <SplideTrack
          options={{
            lazyLoad: "nearby",
          }}
        >
          {blogs.map((blog) => (
            <SplideSlide key={blog.id}>
              <BlogSlide blog={blog.attributes} />
            </SplideSlide>
          ))}
        </SplideTrack>

        <div className="splide__arrows">
          <div
            className="control control-prev"
            onMouseEnter={() => setScrolling("left")}
            onMouseLeave={() => setScrolling(null)}
          ></div>
          <div
            className="control control-next"
            onMouseEnter={() => setScrolling("right")}
            onMouseLeave={() => setScrolling(null)}
          ></div>
        </div>
      </div>
    </Splide>
  );
}
