import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as Actions from "../../Redux/Actions";

import "./filterBtn.css";

function filterBtn({ show, setShow }) {
  if (window.innerWidth > 991 && show) {
    setShow(false);
  }

  return (
    <button
      onClick={() => {
        window.scrollTo(0, 0);
        setShow(true);
      }}
      className="filterBtn_buttonContainerBtn_item"
    >
      Filter
    </button>
  );
}

const mapStateToProps = (state) => {
  return {
    show: state.browse.showMobileFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShow: (data) => {
      dispatch({ type: Actions.SET_SHOW_MOBILE_FILTER, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(filterBtn);
