import React from "react";
import { Fragment } from "react";
import Footer from "../../components/footer/Footer";
import Links from "../../components/links/Links";

export const RefundPolicy = () => {
  return (
    <Fragment>
      <div className="faq-container">
        <h1>Refund Policy of THRILLER</h1>
        <div className="question-container">
          <div className="question">
            <p>WHAT IS YOUR POLICY FOR ONLINE PURCHASES?</p>
            <p>General Conditions Applicable to Returns:</p>

            <ul>
              <li>
                <p>
                  · Subject to meeting the conditions set out in this Returns
                  and Exchanges section, we offer a “no questions asked"
                  free-returns policy which allows you to return delivered items
                  to us for any reason up to 7 days after the delivery of your
                  Order, free of charge.
                </p>
              </li>
              <li>
                <p>
                  · For clothing, returns must be unwashed and unworn. Products
                  must be brought back in original packaging, with all labels
                  attached.
                </p>
              </li>
              <li>
                <p>· For accessories and figures, all sales are final.</p>
              </li>
              <li>
                <p>
                  · To be eligible for a return, your item must be unused and in
                  the same condition that you received it. It must also be in
                  the original packaging with a receipt or proof of purchase.
                </p>
              </li>
              <li>
                <p>
                  · Please note that authenticity tags or stickers must be
                  intact. If removed, the item will not comply with our exchange
                  policy and will not be accepted.
                </p>
              </li>
              <li>
                <p>
                  · If your return is not accepted you will be liable to pay for
                  a shipping label to have the item sent back to you.
                </p>
              </li>
              <li>
                <p>
                  · For international orders, all orders are final. In an
                  unlikely event if the shipment you have received is in a
                  damaged condition or defective, please reach out to us at{" "}
                  <a href="mailto:shipping@thrillerme.com">
                    shipping@thrillerme.com
                  </a>
                </p>
              </li>
              <li>
                <p>
                  · Please do not hesitate to contact us should you require
                  further clarification.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Links />
      <Footer />
    </Fragment>
  );
};
