import React from "react";
import BlogStyle1 from "./BlogStyle1";
import BlogStyle2 from "./BlogStyle2";

export default function BlogSection({ variant = 1, blog, className = "" }) {
  switch (variant) {
    case 1:
      return <BlogStyle1 blog={blog} className={className} />;
    default:
      return <BlogStyle2 blog={blog} className={className} />;
  }
}
