import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import "./buy-page.styles.scss";
import Links from "../../components/links/Links";
import { CustomButton } from "../../components/custom-button/custome-button.component";
import ShoeBuy from "../../components/shoe-buy/shoe-buy.component";
import SHOE_DATA from "../../temporary-data/shoe-data";
import { useHistory, withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import Footer from "../../components/footer/Footer";
import { BASE_URL } from "../../Constants/Global";
import TabbySnippet from "./tabby-snippet";

const BuyPage = ({ user, history }) => {
  const id = useParams().id;
  const size = useParams().size;
  const isBid = useParams().isBid;
  let _lowestAsk = 0;

  // const [isAuthenticated, setAuthenticated] = useState(false);
  // const [hasShipping, setShipping] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [lowestAsk, setLowestAsk] = useState(_lowestAsk);
  const [highestOffer, setHighestOffer] = useState(0);
  const [offerAmount, setOfferAmount] = useState(0);

  const [selectedButton, toggleButton] = useState(
    isBid == "bid" ? true : false
  );

  try {
    _lowestAsk = JSON.parse(window.localStorage.getItem("price"));
  } catch (error) {}

  try {
    var url = `${BASE_URL}listing/highest/${id}/${size}`;
    var urlL = `${BASE_URL}listing/lowest/${id}/${size}`;

    var encodedURL = encodeURI(url);
    var encodedURLL = encodeURI(urlL);

    axios.get(encodedURL).then((res) => {
      if (res.data.highest !== null) {
        setHighestOffer(res.data.highest);
      }
    });
    axios.get(encodedURLL).then((resk) => {
      if (resk.data.lowest !== null) {
        setLowestAsk(resk.data.lowest);
      } else {
        setLowestAsk(0);
      }
    });
  } catch {}

  const Load = useCallback(() => {
    if (product.sku_number === undefined) {
      var url = `${BASE_URL}shoes/${id}`;
      var encodedURL = encodeURI(url);
      axios.get(encodedURL).then((res) => {
        setProduct(res.data);
      });
    }
  }, [id, product.sku_number]);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    Load();
  }, [Load, product, highestOffer]);

  function updateOfferAmount(val) {
    setOfferAmount(val);
  }

  const placeOfferNext = () => {
    console.log("placeOfferNext");
    var _lowestAsk = 0;
    try {
      _lowestAsk = JSON.parse(window.localStorage.getItem("price"));
    } catch (error) {}
    if (!selectedButton) {
      setOfferAmount(_lowestAsk);
    }

    if (
      parseFloat(offerAmount) > parseFloat(lowestAsk) &&
      parseFloat(lowestAsk) > 0
    ) {
      alert("You cannot place an offer that is higher than the asking price");
    }

    // just for now
    else if (offerAmount === "" || (offerAmount <= 0 && selectedButton)) {
      alert("Please Enter the offer amount");
    } else if (parseFloat(offerAmount) < 25 && selectedButton) {
      alert("Minimum offer must be 25");
    } else {
      //just for now
      if (!selectedButton && parseFloat(lowestAsk) === 0) {
        alert(
          "There are no asks to buy now in that size. You may place an offer that a seller may choose to accept at any time, and we'll keep you notified if it get's back in stock"
        );
        return;
      }

      //offers.post
      axios.get(`${BASE_URL}settings`).then((res) => {
        var settings = res.data.result[0];
        settings.marketplaceShare = 0; //marketplace share 0

        var data = {
          buyer_id: user ? user.user_id : "",
          shoe_id: id,
          size: size,
          offerAmount: offerAmount,
          vat: parseFloat(
            offerAmount * (settings.marketplaceShare / 100).toFixed(4)
          ).toFixed(2),
          processingFee: parseFloat(
            offerAmount * (settings.processingFee / 100).toFixed(4)
          ).toFixed(2),
          shippingFee: parseFloat(window.localStorage.getItem("shippingFee")),
          totalBill: 0,
          status: "Pending",
        };
        if (!selectedButton) {
          data.offerAmount = _lowestAsk;
          data.vat = parseFloat(
            _lowestAsk * (settings.marketplaceShare / 100).toFixed(4)
          ).toFixed(2);
          data.processingFee = parseFloat(
            _lowestAsk * (settings.processingFee / 100).toFixed(4)
          ).toFixed(2);
        }

        data.totalBill = (
          parseFloat(data.offerAmount) +
          parseFloat(data.vat) +
          parseFloat(data.processingFee) +
          parseFloat(data.shippingFee)
        ).toFixed(2);

        window.localStorage.setItem("offer", JSON.stringify(data));
        if (!selectedButton) {
          window.localStorage.setItem("buy", "1");
        } else {
          window.localStorage.setItem("buy", "0");
        }

        //just for now
        if (!!user) {
          // if (!user.isAuthenticated) {
          //   history.push({
          //     pathname: "/twoFactorAuth/" + id + "-" + size + "-0",
          //     state: {
          //       hasShippingBuy: user.hasShipping,
          //       id: id,
          //       historyBuy: true,
          //     },
          //   });
          // } else if (!hasShipping) {
          history.push({
            pathname: `/shippingInfo/0/${id}-${size}-0`,
            state: {
              id: id,
              size: size,
              selectedButton: selectedButton,
              historyBuy: true,
            },
          });
          // } else {
          //   history.push(
          //     `/product-review/${id}_size_${size}/${selectedButton}`
          //   );
          // }
        } else {
          history.push({
            pathname: "/login/",
          });
        }
      });
    }
  };

  const buyOptionsNext = () => {
    // setMD(true);
    var _lowestAsk = 0;
    try {
      _lowestAsk = JSON.parse(window.localStorage.getItem("price"));
      //alert(_lowestAsk);
    } catch (error) {}
    if (!selectedButton) {
      setOfferAmount(_lowestAsk);
    }

    if (_lowestAsk === 0) {
      alert("This item is not available at the moment.");
    } else if (
      parseFloat(offerAmount) > parseFloat(lowestAsk) &&
      parseFloat(lowestAsk) > 0
    ) {
      alert("You cannot place an offer that is higher than the asking price");
    }

    // just for now
    else if (offerAmount === "" || (offerAmount <= 0 && selectedButton)) {
      alert("Please Enter the offer amount");
    } else if (parseFloat(offerAmount) < 25 && selectedButton) {
      alert("Minimum offer must be 25");
    } else {
      //just for now
      if (!selectedButton && parseFloat(lowestAsk) === 0) {
        alert(
          "There are no asks to buy now in that size. You may place an offer that a seller may choose to accept at any time, and we'll keep you notified if it get's back in stock"
        );
        return;
      }

      //offers.post
      axios.get(`${BASE_URL}settings`).then((res) => {
        var settings = res.data.result[0];
        settings.marketplaceShare = 0; //marketplace share 0

        var data = {
          buyer_id: user ? user.user_id : "",
          shoe_id: id,
          size: size,
          offerAmount: offerAmount,
          vat: parseFloat(
            offerAmount * (settings.marketplaceShare / 100).toFixed(4)
          ).toFixed(2),
          processingFee: parseFloat(
            offerAmount * (settings.processingFee / 100).toFixed(4)
          ).toFixed(2),
          shippingFee:
            parseFloat(window.localStorage.getItem("shippingFee")) ||
            parseFloat(settings.deliveryIn) ||
            0,
          totalBill: 0,
          status: "Pending",
        };

        if (!selectedButton) {
          data.offerAmount = _lowestAsk;
          data.vat = parseFloat(
            _lowestAsk * (settings.marketplaceShare / 100).toFixed(4)
          ).toFixed(2);
          data.processingFee = parseFloat(
            _lowestAsk * (settings.processingFee / 100).toFixed(4)
          ).toFixed(2);
        }

        data.totalBill = (
          (parseFloat(data.offerAmount) || 0) +
          (parseFloat(data.vat) || 0) +
          (parseFloat(data.processingFee) || 0) +
          (parseFloat(data.shippingFee) || 0)
        ).toFixed(2);

        window.localStorage.setItem("offer", JSON.stringify(data));

        if (!selectedButton) {
          window.localStorage.setItem("buy", "1");
        } else {
          window.localStorage.setItem("buy", "0");
        }

        //just for now
        if (!!user) {
          // if (!user.isAuthenticated) {
          //   history.push({
          //     pathname: "/twoFactorAuth/" + id + "-" + size + "-0",
          //     state: {
          //       hasShippingBuy: user.hasShipping,
          //       id: id,
          //       historyBuy: true,
          //     },
          //   });
          // } else if (!hasShipping) {
          history.push({
            pathname: `/shippingInfo/0/${id}-${size}-0`,
            state: {
              id: id,
              size: size,
              selectedButton: selectedButton,
              historyBuy: true,
            },
          });
          // } else {
          //   history.push(
          //     `/product-review/${id}_size_${size}/${selectedButton}`
          //   );
          // }
        } else {
          const url = "https://api.thrillerme.com/banners/encryptObj";
          const data = { shoeID: id, size: size, price: _lowestAsk };

          try {
            axios.post(url, data).then((response) => {
              const { shoeIDE, sizeE, priceE } = response.data;
              console.log(`shoeIDE=${shoeIDE}`);
              console.log(`sizeE=${sizeE}`);
              console.log(`priceE=${priceE}`);
              window.location.href = `https://checkout.thrillerme.com/?id=${shoeIDE}&size=${sizeE}&price=${priceE}`;
              //alert(id + " size: " + size + " AED " + _lowestAsk);
            }); // Use axios.post if using Axios
          } catch (error) {
            console.error("Error fetching data:", error);
          }

          // history.push({
          //   pathname: "/login/",
          // });
        }
      });
    }
  };

  var shoe = {
    shoe: null,
  };

  SHOE_DATA.map((_shoe) => {
    shoe.shoe = _shoe;
  });

  return (
    <div className="shoe-page">
      <div className="shoe-content">
        <div className="shoe-image">
          <div className="shoe-info">
            <h1>{product.name}</h1>
            <p>
              Lowest Ask:<b>AED {lowestAsk}</b> | Highest Offer:{" "}
              <b>AED {highestOffer}</b>
            </p>
          </div>
          <img src={product.cover_image} alt="item-img" />
        </div>
        <div className="shoe-size">
          <ShoeBuy
            id={id}
            size={size}
            highestOffer={highestOffer}
            setOfferAmount={updateOfferAmount}
            amount={offerAmount}
            lowestAsk={lowestAsk}
            selectedButton={selectedButton}
            toggleButton={toggleButton}
            buyNow={"0"}
            productName={"-"}
          />

          <div className="control-buttons">
            <CustomButton
              onClick={() => {
                history.push("");
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={() => {
                if (selectedButton) {
                  placeOfferNext();
                } else {
                  buyOptionsNext();
                }
              }}
            >
              Next
            </CustomButton>
          </div>
        </div>
      </div>
      <div className="button-parent">
        <Link to="/faq">
          <div className="bottomFAQ" style={{ color: "black" }}>
            <p>FAQ</p>
          </div>
        </Link>

        <div className="void-div"></div>
      </div>
      <hr></hr>

      <div style={{ marginTop: "10px" }}>
        <Fragment>
          <Links />
          <Footer />
        </Fragment>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    buyer: state.buyShoe.buyShoe,
  };
};

export default connect(mapStateToProps)(withRouter(BuyPage));
