import React, { useState, useEffect, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import BlogSection from "../blog-section";
import BlogsBuyersGuideSection from "../blog-buyers-guide-section";

import "./BlogsSection.scss";

function BlogsSection({ blogs }) {
  return (
    <div className="blogs-section">
      {blogs.map((post, index) => (
        <Fragment key={post.id}>
          <BlogSection
            variant={index % 2 == 0 ? 1 : 2}
            blog={post.attributes}
          />

          {index == blogs.length - 2 && <BlogsBuyersGuideSection />}
        </Fragment>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    blogs: state.blogs.blogs.slice(0, 5),
  };
};

export default connect(mapStateToProps)(BlogsSection);
