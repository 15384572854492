import { red } from "@material-ui/core/colors";
import React from "react";
import { connect } from "react-redux";
import "./shoe-size.styles.scss";
import * as Actions from "../../Redux/Actions";

const ShoeSize = ({
  shoe,
  size,
  displayShoeSize,
  toggleShoeDisplay,
  saveShoe,
  parentCallBack,
}) => {
  function handleClick(displayShoeSize, toggleShoeDisplay) {
    let data = {
      ...shoe,
      sizeID: size.size,
    };

    window.localStorage.setItem("shoeSize", size.size);
    saveShoe(data);

    toggleShoeDisplay([!displayShoeSize[0], displayShoeSize[1]]);
    parentCallBack(size.size);
    //Send the size back using this to the parent Shoe Page component
    //So that it can render the sell page for that particular size
    //not being used for much as of right now.
  }

  return (
    <div
      className="shoe-details"
      onClick={() => handleClick(displayShoeSize, toggleShoeDisplay)}
    >
      {/* <p></p> */}
      <p className="price">{size.size}</p>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveShoe: (data) => {
      dispatch({ type: Actions.SAVE_SHOE_SIZE, payload: data });
    },
  };
};

export default connect(null, mapDispatchToProps)(ShoeSize);
