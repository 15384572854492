import React from "react";
import "./SizeBox.scss";

function CustomSizeBox({ size, closeSizeChart, selectSize }) {
  const onSelectFuntion = () => {
    selectSize(size);
    closeSizeChart();
  };

  return (
    <>
      <div className="size-box-container" onClick={onSelectFuntion}>
        <span>{size.size}</span>
        {size.cost > 0 ? (
          <span style={{ fontSize: "10px", color: "red" }}>
            AED {Math.floor(size.cost)}
          </span>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default CustomSizeBox;
