import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { BLOGS_BASE_URL } from "../../../Constants/Global";

import "./CollageItem.scss";

export default function CollageItem({ blog }) {
  const { cover, Heading, PublishDate, slug } = blog;

  const banner = `${BLOGS_BASE_URL}${cover.data.attributes.url}`;
  const bannerAlt = cover.data.attributes.name;

  return (
    <Link to={`/blogs/${slug}`} className="collage-item">
      <img src={banner} alt={bannerAlt} />

      <div className="collage-item-info">
        <div className="info-block">
          <p className="heading">{Heading}</p>
          <p className="meta">
            By Thrillerme - {moment(PublishDate).format("MMMM D, YYYY")}
          </p>
        </div>
      </div>
    </Link>
  );
}
