// for live
export const BASE_URL = "https://api.thrillerme.com/";

// for staging
// export const BASE_URL = "https://thriller.cloudious.net/";

//for local
// export const BASE_URL = "http://192.168.100.114:3011/";

// Blogs
export const BLOGS_BASE_URL = "https://elixir.cloudious.net";

export const LOGIN = BASE_URL + "registrations/login";

export const SIGNUP = BASE_URL + "registrations/";

export const GETSEARCHEDSHOES = BASE_URL + "shoes/searchShoe/";

export const LISTING = BASE_URL + "listing";

export const WHATSAPP_CONTACT = "+971589441895";
