import React, { useState } from "react";
import "./pending-selling.styles.scss";

import SellingPendingTable from "../selling-pending-table/selling-pending-table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PendingSelling = ({ pendingShoeData }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className="pending-selling-container">
      <div className="search-container">
        <input placeholder="Search name, order no." onChange={handleChange} />
        <FontAwesomeIcon icon={["fa", "search"]} color="#3a4b3c" />
      </div>
      <SellingPendingTable
        pendingShoeData={pendingShoeData}
        searchValue={searchValue}
      />
    </div>
  );
};
