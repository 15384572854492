import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

const CollectionBar = ({ collections, hideSideBar, history }) => {
  const onClickCollection = (collection) => {
    window.localStorage.setItem("filter", null);
    history.push(
      `/collections/${collection.title.replace(/\s+/g, "-").toLowerCase()}/`
    );
    hideSideBar();
  };

  const items = collections.map((collection) => ({
    href: `/collections/${collection.title
      .replace(/\s+/g, "-")
      .toLowerCase()}/`,
    label: collection.title,
  }));

  return (
    <ul className="nav-menu-items">
      <li className="navbar-toogle">
        <Link to="#" className="menu-bars"></Link>
      </li>
      {items.map((item, index) => (
        <li style={{ border: "5px", borderColor: "red" }} key={index}>
          <a href={item.href} className="nav-text">
            <div className="hover">{item.label}</div>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default withRouter(CollectionBar);
