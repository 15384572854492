import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import List from "../list/List";
import ListSkeleton from "../list/ListSkeleton";

import trustpilot from "../../assets/Home/trustpilot.svg";

import "./Links.css";

const Links = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let data = [
      {
        title: "Trending Collections",
        collections: [
          { name: "Air Jordan", url: "collections/jordan" },
          { name: "Nike", url: "collections/nike" },
          { name: "Adidas Yeezy", url: "collections/yeezy" },
          { name: "On Running", url: "collections/oncloud-running-" },
          { name: "Travis Scott", url: "collections/travis-scott" },
        ],
      },
      {
        title: "Air Jordan",
        collections: [
          { name: "Air Jordan 1", url: "#" },
          { name: "Womens Jordans", url: "#" },
          { name: "Jordan 1 Low", url: "collections/jordan-1-lows" },
          { name: "Air Jordan 11", url: "#" },
          { name: "Air Jordan 4", url: "#" },
          { name: "Jordan 1 Mid", url: "#" },
        ],
      },
      {
        title: "Accessories",
        collections: [
          { name: "Swatch x Omega", url: "collections/swatch-x-omega" },
          { name: "Display Cases", url: "collections/display-" },
          { name: "Crep Protect", url: "collections/crep-protect-" },
          { name: "Hermès Accessories", url: "#" },
        ],
      },
      {
        title: "Specialty Collections",
        collections: [
          { name: "Womens Sneakers", url: "collections/women's-shoes" },
          { name: "Kids Sneakers", url: "collections/kids-collection" },
          { name: "Hermès Cyphre (Men)", url: "collections/hermès-" },
          {
            name: "Hermès Sandals (Women)",
            url: "collections/hermès-sandals-(women)",
          },
        ],
      },
      {
        title: "About Us",
        collections: [
          { name: "How It Works", url: "about" },
          { name: "Verification", url: "about" },
          { name: "Become A Seller", url: "sell" },
        ],
      },
      {
        title: "Customer Service",
        collections: [
          { name: "Contact Us", url: "contact" },
          { name: "Customer Care", url: "contact" },
          { name: "FAQ", url: "faq" },
          { name: "Shipping Policy", url: "shipping" },
          { name: "Refund Policy", url: "refund" },
          { name: "Privacy Policy", url: "privacy" },
          { name: "Terms Of Service", url: "terms" },
        ],
      },
    ];
    setItems(data);
    setLoading(false);
  }, []);

  return (
    <div className="top-container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="row justify-content-lg-center">
            {items.map((item, index) => {
              return (
                <div
                  className="col-lg-2 col-md-4 col-sm-6 adjust-footer"
                  key={index}
                >
                  {loading ? (
                    <ListSkeleton />
                  ) : (
                    <List title={item.title} collection_id={item.collections} />
                  )}
                </div>
              );
            })}
            <div className="col-md-12">
              <a
                className="trustpilot"
                href="https://www.trustpilot.com/review/thrillerme.com"
                target="_blank"
                rel="noreferrer"
              >
                <span>Great 4.2 out of 5</span>
                <img src={trustpilot} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Links;
