import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./ShopBanner.scss";
import { getCollectionById } from "../../utils/api";
import * as Actions from "../../Redux/Actions";
import { connect } from "react-redux";
import { textToUrlSlug, titleCase } from "../../Constants/Functions";

const ShopBanner = ({ collections, collectionData, setCollectionData }) => {
  const params = useParams();

  const { id, keyword, sizee } = params;

  const { webBanner, title, description, mobileBanner } = collectionData;

  useEffect(() => {
    if (id && id != 0) {
      const collection = collections.find(
        (c) =>
          textToUrlSlug(c.title) == textToUrlSlug(id) || c.collection_id == id
      );

      if (collection) {
        getCollectionById(collection.collection_id)
          .then((response) => {
            if (response?.title) {
              setCollectionData(response);
            } else {
              setCollectionData({
                webBanner,
                description,
                mobileBanner,
                title: isNaN(id)
                  ? titleCase(id.replace(/-/g, " "))
                  : "Shop All",
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setCollectionData({
          webBanner,
          description: isNaN(id) ? titleCase(id.replace(/-/g, " ")) : "",
          mobileBanner,
          title: "Shop All",
        });
      }
      return;
    }

    setCollectionData({
      collection_id: 0,
      title: "Shop All",
      description: "",
      imageURL: "",
    });
  }, [
    title,
    collections,
    id,
    mobileBanner,
    description,
    setCollectionData,
    webBanner,
    keyword,
    sizee,
  ]);

  return (
    <div>
      <div className="shpbnr">
        {webBanner ? (
          <img className="bnrImg_img" src={webBanner} alt="Banner" />
        ) : (
          <div>
            <div className="bnrtxt">{title}</div>
            <div className="prdTxt">{description}</div>
          </div>
        )}
      </div>
      <div>
        {webBanner && (
          <div className="shpbnrMob">
            <img className="bnrImg_img" src={mobileBanner} alt="Banner" />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    collectionData: state.browse.collectionData,
    collections: state.products.collections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCollectionData: (data) => {
      dispatch({ type: Actions.SET_COLLECTION_DATA, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopBanner);
