import React from "react";
import { Link } from "react-router-dom";
import cardImg6 from "../../temporary-data/6.png";

import "./product-card.styles.scss";

export default function ProductCard({
  newname,
  newshoeid,
  cover_image,
  name,
  lowestAsk,
  lastSold,
}) {
  return (
    <Link to={`/${newname}_id_${newshoeid}/#`}>
      <div className="CustomcardDiv">
        <img
          className="cardImg"
          src={cover_image}
          alt={`/${newname}_id_${newshoeid}/#`}
        />
        <div className="textCont">
          <div className="headingDiv">
            <h6>{name.length > 32 ? name.substring(0, 29) + " .." : name}</h6>
          </div>
          <div className="priceDiv">
            <span>Lowest Price</span>
            <h6>{(lowestAsk && "AED " + lowestAsk) || "--"}</h6>
          </div>

          <div className="lastSoldDiv">
            <span>Last Sold: {(lastSold && "AED " + lastSold) || "--"}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}
