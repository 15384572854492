import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

export default function AuthenticityGurantee() {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <>
      <button
        className="btn"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        onClick={() => setShowDetails(!showDetails)}
      >
        <div className="fM2">AUTHENTICITY GUARANTEE</div>
        <div>
          {showDetails && <KeyboardArrowUp />}
          {!showDetails && <KeyboardArrowDown />}
        </div>
      </button>
      {showDetails && (
        <>
          <div className="mt-3">
            Shop on Thriller with confidence knowing every purchase is Thriller
            Verified. Thriller Verified is our own designation using data points
            and industry experts, as well as machine learning, to Guarantee
            Authenticity every single time.
          </div>
          <div className="mt-3">
            Our team of experts uses a rigorous, multi-step verification process
            to ensure 100% Authenticity. We only allow deadstock on our
            marketplace. That means every item, bought or sold must be brand new
            and never worn.
          </div>
          <div className="mt-3">
            We believe there's no substitute for experience. Since inception,
            we've inspected over AED 2 million worth of items on behalf of our
            customers. As a final check in our verification process, our QA
            experts ensure the product meets{" "}
            <a href="https://thrillerme.com/contact">our high standards</a>.
          </div>
        </>
      )}
    </>
  );
}
