import React from "react";
import "./account-sidebar-2.styles.scss";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export const AccountSidebar2 = connect(mapStateToProps)(
  withRouter(
    ({ sideBarLinks, setPage, selectedPage, sideBarStatus, user, history }) => {
      return (
        <div
          className={
            sideBarStatus
              ? "account-sidebar-container"
              : "small-account-sidebar-container"
          }
        >
          <h3>{user.firstName + " " + user.lastName}</h3>
          {sideBarLinks.map(({ page, name, image, description }, index) => (
            <div
              key={`sidebar-link-${index}`}
              onClick={() => {
                setPage(name);
                if (name === "Security") {
                  history.push({
                    pathname: page,
                    state: {
                      historySetting: true,
                    },
                  });
                } else {
                  history.push(page);
                }
              }}
              className={
                selectedPage === name
                  ? "link-container selected"
                  : "link-container"
              }
            >
              <img src={image} alt="logo" />
              <div className="link-text">
                <h4 className="link-name">{name}</h4>
              </div>
            </div>
          ))}
        </div>
      );
    }
  )
);
