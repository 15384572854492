import React from "react";
import { useHistory } from "react-router-dom";
import thrillerVerified from "../../assets/thrillverf.jpg";

export default function ThrillerVerified() {
  const history = useHistory();
  return (
    <div className="row productVerify">
      <h3 className="text-center py-2">Thriller Verified Sneakers</h3>
      <p className="text-center">We Verify Every Item. Every Time.</p>
      <img
        onClick={() => {
          history.push("/about");
        }}
        src={thrillerVerified}
        className="img-fluid mx-auto mb-4"
        style={{ maxWidth: "500px" }}
        alt="thrillerVerified"
      />
    </div>
  );
}
