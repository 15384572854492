import React, { useState, useEffect } from "react";
import "./buying-info.styles.scss";
import axios from "axios";

import { AccountSettingHeader } from "../account-settings-header/account-settings-header.component";
import { BASE_URL } from "../../Constants/Global";
import { connect } from "react-redux";

const BuyingInfo = ({ user }) => {
  const [buyingData, setBuyingData] = useState({});

  useEffect(() => {
    var url = `${BASE_URL}/buyinginfo/${user.user_id}`;
    axios
      .get(url)
      .then((res) => {
        setBuyingData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="buying-info-section">
      <AccountSettingHeader
        headerTitle="Buying Information"
        pageRoute="shippingInfo/2"
      />
      <div className="shipping-info-content">
        {buyingData === "" ? (
          <p>You do not have any buying information saved.</p>
        ) : (
          <div>
            {buyingData.firstName} <br></br>
            {buyingData.lastName} <br></br>
            {buyingData.phone} <br></br>
            {buyingData.country} <br></br>
            {buyingData.city} <br></br>
            {buyingData.address} <br></br>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    banner1: state.banners.banner1,
    banner2: state.banners.banner2,
  };
};

export default connect(mapStateToProps)(BuyingInfo);
