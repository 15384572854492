import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import { faGhost } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BlogHeroLoader from "../../components/blog-page/BlogHeroLoader";
import RelatedBlogLoader from "../../components/blog-page/RelatedBlogsLoader";
import BlogPage from "./BlogPage";
import BlogCategoryPage from "./BlogCategoryPage";

import "./BlogSlugPage.scss";
import { getBlogBySlug, getBlogCategoryBySlug } from "../../utils/api";

function BlogSlugPage({ blogs, categories }) {
  const { slug } = useParams(); // Extract the slug using useParams

  const [loadingBlog, setLoadingBlog] = useState(true);
  const [blog, setBlog] = useState(null);

  const [loadingCategory, setLoadingCategory] = useState(true);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    setBlog(null);
    setLoadingBlog(true);
    getBlogBySlug(slug)
      .then((response) => {
        if (response.data.length) {
          setBlog({ ...response.data[0].attributes, id: response.data[0].id });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingBlog(false);
      });

    setCategory(null);
    setLoadingCategory(true);
    getBlogCategoryBySlug(slug)
      .then((response) => {
        // console.log("Category Slug Response", response);
        if (response.data.length) {
          setCategory({
            ...response.data[0].attributes,
            id: response.data[0].id,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingCategory(false);
      });
  }, [slug]);

  const showLoading = loadingBlog || loadingCategory;

  if (showLoading) {
    return (
      <>
        <div className="mb-4">
          <BlogHeroLoader width={"100%"} height={"100%"} />
        </div>
        <RelatedBlogLoader width={"100%"} height={"50vh"} />
      </>
    );
  }

  if (category) {
    return <BlogCategoryPage category={category} />;
  }

  if (blog) {
    return <BlogPage blog={blog} />;
  }

  return (
    <div className="blog-slug-page">
      <div className="pg-container" style={{ marginTop: "20vh" }}>
        <div className="container">
          <FontAwesomeIcon icon={faGhost} className="ghost" size={"10x"} />
          <h1 className="mb-4">404</h1>
          <h2 className="mb-1">Oops!</h2>
          <h3>Nothing Here</h3>

          <p className="info">
            RETURN TO OUR <Link to="/blogs">BLOGS HOMEPAGE</Link> OR BROWSE ALL{" "}
            <Link to="/collections/0/">PRODUCTS.</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingBlogs: state.blogs.loadingBlogs,
    blogs: state.blogs.blogs,
    loadingCategories: state.blogs.loadingCategories,
    categories: state.blogs.categories,
  };
};

export default connect(mapStateToProps)(BlogSlugPage);
