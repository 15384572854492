import AuthReducer from "./AuthReducer";
import MainReducer from "./MainReducer";
import SellerOrderReducer from "./SellerOrderItems";
import BuyShoeReducer from "./BuyShoeReducer";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import BannerReducer from "./BannerReducer";
import ProductsReducer from "./ProductsReducer";
import BrowseReducer from "./BrowseReducer";
import BlogsReducer from "./BlogsReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "auth",
    "root",
    "buyShoe",
    "banners",
    "products",
    "browse",
    "blogs",
  ],
};

const rootReducer = combineReducers({
  root: MainReducer,
  auth: AuthReducer,
  sellerOrder: SellerOrderReducer,
  buyShoe: BuyShoeReducer,
  banners: BannerReducer,
  products: ProductsReducer,
  browse: BrowseReducer,
  blogs: BlogsReducer,
});

export default persistReducer(persistConfig, rootReducer);
