import React from "react";
import DefaultAvatar from "../../common/DefaultAvatar";
import { BLOGS_BASE_URL } from "../../../Constants/Global";

import "./AuthorSection.scss";

export default function AuthorSection({ author = {} }) {
  const { name = "Anonymous", avatar = {} } = author;

  let avatarSrc = "";

  if (avatar?.data?.attributes.url) {
    avatarSrc = `${BLOGS_BASE_URL}${avatar?.data?.attributes.url}`;
  }

  return (
    <section className="author-section">
      <div className="content">
        <div className="author-image">
          <div className="author-image-placeholder">
            {avatar?.data ? (
              <img src={avatarSrc} alt={name} />
            ) : (
              <DefaultAvatar />
            )}
          </div>
        </div>
        <div className="author-content">
          <div className="author-content-name">{name}</div>
          <div className="author-content-meta">
            {name} is a Creative Content Strategist & Writer @ Thrillerme
          </div>
        </div>
      </div>
    </section>
  );
}
