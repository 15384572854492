import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import SettingItem from "../setting-item/setting-item.component";
import { getOffersCurrent } from "../../utils/api";

import "./buying-pending-table.styles.scss";

const BuyingPendingTable = ({ searchValue, user }) => {
  const [buy, setBuy] = useState([]);

  useEffect(() => {
    const myObjectString = localStorage.getItem("persist:root");
    const myObjectParsed = JSON.parse(myObjectString);
    const userData = JSON.parse(myObjectParsed.auth);
    const myUserID = userData.user.user_id;

    getOffersCurrent(myUserID)
      .then((response) => {
        setBuy(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="buying-pending-table-container">
      <div className="table-header">
        <p className="heading-title">Item</p>
        <p className="heading-title tier-two">Size</p>
        <p className="heading-title tier-two">Order Price</p>
        <p className="heading-title tier-two">Lowest Asking Price</p>
        <p className="heading-title">Status</p>
      </div>

      {searchValue === "" && buy
        ? buy.map((val, ind) => (
            <SettingItem key={ind + " "} shoe={val} buying={true} />
          ))
        : null}

      {searchValue !== "" &&
        buy !== null &&
        buy
          .filter((shoe) =>
            shoe.name
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase())
              ? shoe
              : null
          )
          .map((val, ind) => (
            <SettingItem key={ind + " "} shoe={val} buying={true} />
          ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(BuyingPendingTable);
