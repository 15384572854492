import React from "react";

export default function BlogPageImage({ banner, bannerAlt }) {
  return (
    <div className="blog-page-image">
      <div className="image-placeholder">
        <img src={banner} alt={bannerAlt} />
      </div>
    </div>
  );
}
