import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import SettingItem from "../setting-item/setting-item.component";
import { Fragment } from "react";
import { getOrderSellerPending } from "../../utils/api";

import "./selling-pending-table.styles.scss";

const SellingPendingTable = ({ user, searchValue }) => {
  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width;
  }

  var widthScreen = getWindowDimensions();

  const [pendingData, setPendingData] = useState([]);

  useEffect(() => {
    if (user) {
      getOrderSellerPending(user.user_id)
        .then((response) => {
          setPendingData(response);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user]);

  return (
    <div className="selling-pending-table-container">
      <div className="table-header">
        {widthScreen <= 481 ? (
          <Fragment>
            <p className="heading-title">Item</p>
            <p className="heading-title">Status</p>
          </Fragment>
        ) : (
          <Fragment>
            <p className="heading-title">Item</p>
            <p className="heading-title tier-two">Order Number</p>
            <p className="heading-title tier-two">Sale Date</p>
            <p className="heading-title">Price</p>
            <p className="heading-title tier-two">Status</p>
          </Fragment>
        )}
      </div>

      {searchValue === "" && pendingData
        ? pendingData.map((shoe, ind) => (
            <SettingItem
              history={false}
              datePicker={true}
              key={shoe.id}
              shoe={shoe}
              pending={true}
              setBuy={setPendingData}
            />
          ))
        : null}

      {searchValue !== "" &&
        pendingData !== null &&
        pendingData
          .filter((shoe) =>
            shoe.name
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase())
              ? shoe
              : null
          )
          .map((shoe) => (
            <SettingItem
              history={false}
              datePicker={true}
              key={shoe.id}
              shoe={shoe}
              pending={true}
              setBuy={setPendingData}
            />
          ))}

      {/* {SHOE_DATA.map((shoe) => (
        <SettingItem history={true} key={shoe.id} shoe={shoe} />
      ))} */}
      {/* {SHOE_DATA.filter((shoe) =>
        shoe.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
          ? shoe
          : null
      ).map((shoe) => (
        <SettingItem
          history={true}
          datePicker={true}
          key={shoe.id}
          shoe={shoe}
        />
      ))} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellingPendingTable);
