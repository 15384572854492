import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import BlogsSection from "../../components/blogs-page/blogs-sections";
import BlogsSectionLoader from "../../components/blogs-page/blog-section-loader";
import BlogCollage from "../../components/blogs-page/blog-collage";
import Footer from "../../components/footer/Footer";
import Links from "../../components/links/Links";

import "./BlogsPage.scss";

function BlogsPage({ loading }) {
  return (
    <div className="main">
      <Helmet>
        <title>Thrillerme - Thriller Digest - Blogs</title>
        <meta name="title" content="Thriller Digest | Thrillerme" />
        <meta
          name="description"
          content="The Thriller Digest is the world's most authentic destination for a transparent breakdown of all the relevant news, data, and analysis moving the market."
        />
        <meta property="og:url" content={`https://thrillerme.com/blogs`} />
        <link rel="canonical" href="https://thrillerme.com/blogs" />
      </Helmet>

      {loading ? (
        [1, 2, 3].map((num) => (
          <BlogsSectionLoader key={num} width={"100%"} height={"80vh"} />
        ))
      ) : (
        <BlogsSection />
      )}

      <BlogCollage />

      <Links />
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.blogs.loadingBlogs,
  };
};

export default connect(mapStateToProps)(BlogsPage);
