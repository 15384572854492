import React from "react";
import { Link } from "react-router-dom";
import { BLOGS_BASE_URL } from "../../../Constants/Global";

import "./BlogStyle2.scss";

export default function BlogStyle1({ blog, className = "" }) {
  const { Heading, summary, slug, cover } = blog;

  const banner = `${BLOGS_BASE_URL}${cover.data.attributes.url}`;
  const bannerAlt = cover.data.attributes.name;

  return (
    <section className={`blog blog-2 ${className}`}>
      <div className="blog-container">
        <div className="content-wrapper">
          <div className="content">
            <div className="image">
              <div className="placeholder">
                <img src={banner} alt={bannerAlt} />
              </div>
            </div>
            <div className="info">
              <h3 className="headline">{Heading}</h3>
              <p className="summary">{summary}</p>

              <Link className="link" to={`/blogs/${slug}`}>
                <span className="label">Read Now</span>
                <span className="plus">+ + + + +</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
