import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AccountSettingHeader } from "../account-settings-header/account-settings-header.component";
import "./profile-info.styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProfileInfo = ({ user, history }) => {
  return (
    <div>
      <div className="profile-info-section">
        <AccountSettingHeader headerTitle="Profile" pageRoute="profileEdit" />
        <div className="profile-info-content">
          <div className="user-profile-column">
            <div className="profile-content">
              <h6>Name</h6>
              <p>{user.firstName + " " + user.lastName}</p>
            </div>
            <div className="profile-content">
              <h6>Email Address</h6>
              <p>{user.email}</p>
            </div>
            <div className="profile-content">
              <h6>Selected Currency</h6>
              <p>AED</p>
            </div>
          </div>
          <div className="user-profile-column">
            <div className="profile-content">
              <h6>Shoe Size</h6>
              <p>{user.defaultSize}</p>
            </div>
            <div className="profile-content">
              <h6>
                Username
                <span
                  className="ms-2"
                  data-tip="This is just for you, No <br> Buyer or Seller will ever see this information.<br> Whether you buy or sell you will remain anonymous. <br> Happy Trading!"
                >
                  <FontAwesomeIcon icon={["fa", "question-circle"]} />
                  <ReactTooltip
                    multiline={true}
                    place="top"
                    type="light"
                    effect="solid"
                    backgroundColor="white"
                    textColor="black"
                    className="profile-tooltip"
                    border={true}
                    borderColor="black"
                  />
                </span>
              </h6>
              <p>{user.userInfo}</p>
            </div>
            <div className="profile-content">
              <h6
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => history.push("reset")}
              >
                Reset Password
              </h6>
            </div>
          </div>
          <div className="user-profile-column"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileInfo));
