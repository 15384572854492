import React from "react";
import { Link } from "react-router-dom";
import { BLOGS_BASE_URL } from "../../../Constants/Global";

import "./BlogStyle1.scss";

export default function BlogStyle1({ blog, className = "" }) {
  const { Heading, summary, slug, cover } = blog;

  const banner = `${BLOGS_BASE_URL}${cover.data.attributes.url}`;
  const bannerAlt = cover.data.attributes.name;

  return (
    <section className={`blog blog-1 ${className}`}>
      <div className="blog-container">
        <div className="content-wrapper">
          <div className="container">
            <div className="block">
              <div className="block-image">
                <div className="image">
                  <img src={banner} alt={bannerAlt} />
                </div>
              </div>

              <div className="block-content">
                <div className="block-content-text">
                  <h3 className="headline">{Heading}</h3>

                  <p className="summary">{summary}</p>
                </div>
                <div className="block-content-link">
                  <Link className="blog-link" to={`/blogs/${slug}`}>
                    <span className="label">Read Now</span>
                    <span className="plus">+ + + + +</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-mobile">
          <h3 className="headline">Buyer's Guide: Nike Kobe Shoes</h3>
          <p className="summary">
            Nike and Kobe Bryant had a partnership unlike any other. We go into
            Kobe's sneaker journey and what's next to come from the Swoosh in
            our Nike Kobe Shoes Buyer's Guide.
          </p>
          <Link className="blog-link" to={`/blogs/${slug}`}>
            <span className="article-link__label">Read Now</span>
            <span className="article-link__plus">+ + + + +</span>
          </Link>
        </div>
      </div>
    </section>
  );
}
