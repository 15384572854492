import React from "react";
import ContentLoader from "react-content-loader";

export default function RelatedBlogLoader({
  width = 640,
  height = 213,
  duration = 1,
  ...props
}) {
  return (
    <ContentLoader
      speed={duration}
      width={width}
      height={height}
      viewBox="0 0 640 213"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="210" height="210" />
      <rect x="215" y="0" rx="0" ry="0" width="210" height="210" />
      <rect x="430" y="0" rx="0" ry="0" width="210" height="210" />
    </ContentLoader>
  );
}
