import React from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import "./BlogPagination.scss";
import { connect } from "react-redux";

function BlogPagination({ page, pageCount, pageSize, total }) {
  console.log("BlogPagination:", { page, pageCount, pageSize, total });

  const { slug } = useParams();

  // Calculate total number of pages
  const totalPages = Math.ceil(total / pageSize);

  // Disable buttons if at the beginning or end
  const isPrevDisabled = page <= 1;
  const isNextDisabled = page >= totalPages;

  // Function to generate page numbers with ellipsis logic
  const getPageNumbers = () => {
    let pages = [];

    if (totalPages <= 4) {
      // Show all pages if total pages are less than or equal to 4
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      if (page <= 2) {
        // Show first 4 pages if the current page is in the beginning
        pages = [1, 2, 3, 4];
      } else if (page >= totalPages - 1) {
        // Show last 4 pages if the current page is at the end
        pages = [totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
      } else {
        // Show 2 pages before and after the current page
        pages = [page - 1, page, page + 1, page + 2];
      }
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="blogs-pagination">
      <div className="pagination">
        {!isPrevDisabled && (
          <Link
            to={`/blogs/${slug}?page=${page - 1}`}
            className="pagination__btn pagination__btn--previous"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
        )}
        <div className="pagination-container">
          {/* Always show the first page */}
          {page > 3 && (
            <>
              <Link
                to={`/blogs/${slug}?page=1`}
                className={`pagination__btn pagination__btn--page ${
                  page === 1 ? "active" : ""
                }`}
              >
                1
              </Link>
              {page > 4 && (
                <span class="pagination__btn pagination__btn--ellipsis">
                  ...
                </span>
              )}
            </>
          )}
          {/* Render dynamic page numbers */}
          {pageNumbers.map((pageNum) => (
            <Link
              key={pageNum}
              to={`/blogs/${slug}?page=${pageNum}`}
              className={`pagination__btn pagination__btn--page ${
                page === pageNum ? "active" : ""
              }`}
            >
              {pageNum}
            </Link>
          ))}

          {/* Always show the last page */}
          {page < totalPages - 2 && (
            <>
              {page < totalPages - 3 && (
                <span class="pagination__btn pagination__btn--ellipsis">
                  ...
                </span>
              )}

              <Link
                to={`/blogs/${slug}?page=${totalPages}`}
                className={`pagination__btn pagination__btn--page ${
                  page === totalPages ? "active" : ""
                }`}
              >
                {totalPages}
              </Link>
            </>
          )}
        </div>
        {!isNextDisabled && (
          <Link
            to={`/blogs/${slug}?page=${page + 1}`}
            className="pagination__btn pagination__btn--next"
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    meta: state.blogs.blogsMeta,
  };
};

export default connect(mapStateToProps)(BlogPagination);
