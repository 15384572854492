import React from "react";
import { Fragment } from "react";
import Footer from "../../components/footer/Footer";
import Links from "../../components/links/Links";
import spTable from "../../assets/shipping-table.jpeg";

export const ShippingPolicy = () => {
  return (
    <Fragment>
      <div className="faq-container">
        <h1>Shipping Policy of THRILLER</h1>
        <div className="question-container">
          <div className="question">
            <h5>Standard Delivery</h5>
            <p>
              Standard Delivery is FREE to the UAE and KSA. Standard Delivery
              times are between 4-7 working days.
            </p>
            <p>
              For standard purchases, you won't receive a shipping confirmation
              until the item has been verified and is en route to your delivery
              address. However, you can always check the status of your order
              prior to receiving it by viewing your pending orders from the
              Account Buying tab, which you can navigate to by clicking My
              Account - Buying.
            </p>
            <p>
              As a Buyer, you can expect to receive the following email
              notifications from Thriller for a standard purchase:
            </p>
            <ul>
              <li>
                <p>
                  · An order confirmation (including order number) when your
                  purchase is completed.
                </p>
              </li>
              <li>
                <p>
                  · A confirmation when the Seller has shipped the item to the
                  Verification Centre.
                </p>
              </li>
              <li>
                <p>
                  · A notification when your item has been verified and shipped.
                </p>
              </li>
              <li>
                <p>· A confirmation that your delivery has been received.</p>
              </li>
            </ul>
          </div>

          <div className="question">
            <h5>Express Delivery</h5>
            <p>
              Express Delivery is 35AED to the UAE. Express Delivery times are
              between 1-3 working days.
            </p>
            <p>
              You can identify Express Ship items by the rocket icon next to the
              product listing. Express Ship items are pre-verified and ready to
              be shipped. Once an order is placed, it gets shipped immediately.
              Express Ship orders are delivered to you within three business
              days maximum.
            </p>
          </div>
          <div className="question">
            <h5>International Delivery</h5>
            <p>
              Please see the countries we ship to and their shipping rates
              below. All International Deliveries will not be subject to any
              additional payments/customs upon delivery.
            </p>
          </div>

          <div className="question">
            <img src={spTable} style={{ width: "100%" }} />
          </div>
        </div>
      </div>
      <Links />
      <Footer />
    </Fragment>
  );
};
