import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import logo from "./marker.png";
import Geocode from "react-geocode";
import axios from "axios";

const AnyReactComponent = ({ text }) => (
  <div>
    <img src={logo}></img>
  </div>
);

const SimpleMap = ({ lati, lngi }) => {
  const [zoom, setZoom] = useState(7);

  function createMapOptions(maps) {
    return {
      panControl: true,
      mapTypeControl: true,
      scrollwheel: true,
      styles: [
        {
          stylers: [{ gamma: 0.8 }, { lightness: 4 }, { visibility: "on" }],
        },
      ],
    };
  }

  function _onClick(obj) {}

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyB5fNADpw-uWy5kw8EYDtxY56DyOtw87zc",
          libraries: ["visualization"],
        }}
        // defaultCenter={{ lat: 23.4241, lng: 53.8478 }}
        defaultCenter={{ lat: lati, lng: lngi }}
        defaultZoom={zoom}
        // options={createMapOptions}
        // onClick={_onClick}
      >
        <AnyReactComponent lat={lati} lng={lngi} />
      </GoogleMapReact>
    </div>
  );
};

export default SimpleMap;
