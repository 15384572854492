import React, { useRef, useState, useEffect, useMemo } from "react";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import { connect } from "react-redux";

import CollageItem from "./CollageItem";

import "./BlogCollage.scss";

function BlogCollage({ blogs, loadingBlogs, className = "" }) {
  const splideRef = useRef();
  const [scrolling, setScrolling] = useState(null);

  useEffect(() => {
    if (scrolling) {
      const scroll = () => {
        const { Move } = splideRef.current.splide.Components;

        if (scrolling === "right") {
          Move.translate(Move.getPosition() - 2); // Scroll right by 1px
        } else if (scrolling === "left") {
          Move.translate(Move.getPosition() + 2); // Scroll left by 1px
        }
      };

      const intervalId = setInterval(scroll, 0.5); // Adjust speed
      return () => clearInterval(intervalId);
    }
  }, [scrolling]);

  const groupedSlides = useMemo(() => {
    let group = [];

    for (let index = 0; index < blogs.length; index += 2) {
      group.push(blogs.slice(index, index + 2));
    }

    return group;
  }, [blogs]);

  return (
    <Splide
      ref={splideRef}
      hasTrack={false}
      tag="section"
      aria-label="Related Blogs"
      className={`blog-collage ${className}`}
      options={{
        autoWidth: true,
        gap: "1rem",
        arrows: false,
        drag: "free",
        snap: false,
        pagination: false,
        lazyLoad: "nearby",
        type: "loop",
      }}
    >
      <div className="custom-wrapper">
        <div className="header">
          <h5>The Latest Stories</h5>
          <p>
            From fashion to electronics to music. We're covering all of current
            culture for you.
          </p>
        </div>
      </div>

      <SplideTrack>
        {groupedSlides.map((pair, index) => (
          <SplideSlide key={index}>
            <div
              className={`collage-item-container container-${(index + 1) % 3} `}
            >
              <CollageItem blog={pair[0].attributes} />
              {pair.length > 1 && <CollageItem blog={pair[1].attributes} />}
            </div>
          </SplideSlide>
        ))}
      </SplideTrack>

      <div className="splide__arrows">
        <div
          className="control control-prev"
          onMouseEnter={() => setScrolling("left")}
          onMouseLeave={() => setScrolling(null)}
        ></div>
        <div
          className="control control-next"
          onMouseEnter={() => setScrolling("right")}
          onMouseLeave={() => setScrolling(null)}
        ></div>
      </div>
    </Splide>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.blogs.loadingBlogs,
    blogs: state.blogs.blogs,
  };
};

export default connect(mapStateToProps)(BlogCollage);
