import React, { useState, useEffect } from "react";
import "./Slideshow.css";

import { Carousel } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";

const Slideshow = ({ banners }) => {
  return (
    <Carousel interval={7000} variant="dark" fade={true}>
      {banners.map((banner, index) => (
        <Carousel.Item key={`slide-${banner.banner_id}`}>
          <a href={banner.buttonURL} key={banner.banner_id}>
            <img
              className="img-fluid"
              src={banner.imageURL}
              alt={`${banner.imageURL}-${index}`}
            />
          </a>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

const mapStateToProps = (state) => {
  return {
    banners: state.banners.slideShowBanners,
  };
};

export default connect(mapStateToProps)(Slideshow);
