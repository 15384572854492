import React, { useState, useEffect, Fragment, useRef } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import { connect } from "react-redux";

import HomePage from "./page-component/Home-page/home-page.component";
import { SellPage } from "./page-component/sell-page/sell-page.component";
import ShoePage from "./page-component/shoe-page/shoe-page.component";
import { HeaderCentered } from "./components/header-centered/header-centered.component";
import { LoginSignupPage } from "./page-component/login-signup-page/login-signup-page.component";
import TwoFactorPage from "./page-component/two-factor-page/two-factor.component";
import Browse from "./page-component/browse-page/browse";
import BillingSettingPage from "./page-component/billing-setting-page/billing-setting-page.component";
import Product from "./page-component/product-page/Product";
import { HelpPage } from "./page-component/help-page/help-page";
import EditProfilePage from "./page-component/edit-profile-page/edit-profile-page.component";
import ShippingInfoPage from "./page-component/shipping-info-page/shipping-info-page.component";
import SellerInfoPage from "./page-component/seller-info-page/seller-info-page.component";
import BuyPage from "./page-component/buy-page/buy-page";
import { FAQPage } from "./page-component/faq-page/faq-page.component";
import { TermsPage } from "./page-component/terms-page/terms-page.component";
import { PrivacyPage } from "./page-component/privacyPage/PrivacyPage";
import SecurityPage from "./page-component/security-page/security-page.component";
import SettingsPage from "./page-component/settings-page/settings-page.component";
import BuyingPage from "./page-component/buying-page/buying-page.component";
import SellingPage from "./page-component/sellling-page/sellling-page.component";
import FavouritePage from "./page-component/favourite-page/favourite-page.component";
import { ShippingPolicy } from "./page-component/shipping-policy/ShippingPolicy.js";
import { RefundPolicy } from "./page-component/refund-policy/RefundPolicy.js";

import Footer from "./components/footer/Footer.js";
import Header from "./components/header/Header.jsx";
import FaqSeller from "./page-component/faq-page/faq-seller.component";
import ScrollToTop from "./components/scroll-to-top";
import ProductReview from "./page-component/product-review-page/product-review";
import ResetEmail from "./page-component/reset-email/Reset-email";
import ContactUs from "./page-component/contact-us/ContactUs";
import StylePage from "./page-component/style-page/style-page";
import Altogether from "./page-component/altogether/Altogether";
import Reset from "./page-component/reset-password/reset";
import BlogsPage from "./page-component/BlogsPage";
import BlogSlugPage from "./page-component/blog-slug-page";
import AboutPage from "./page-component/AboutPage/AboutPage";
import BlogsHeader from "./components/header/BlogsHeader";
import BlogsHeaderOffCanvas from "./components/header/blogs-header-off-canvas/BlogsHeaderOffCanvas";
import BlogsSearchResultOffCanvas from "./components/header/blogs-search-result-off-canvas/BlogsSearchResultOffCanvas";

import {
  getBlogBuyerGuide,
  getBlogCategories,
  getBlogLatest,
  getBlogs,
  getCategories,
  getCollections,
  getCollectionsDropdown,
  getShoes,
} from "./utils/api";
import * as Actions from "./Redux/Actions";

import "./App.scss";

const TRACKING_ID = "UA-198989119-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

/**
 //Frontend
 TODO: - buttons dont go back after clicking on product then size review 
 TODO: - product page is loading from the bottom not the top
 TODO: - shop all page/ accounts page is cut off at the top
 TODO: - shipping page has dissapeared on checkout

  //Seems like backend issue.
  TODO: - Prices are not displaying on shop all for products with a lowest ask (Travis scott fragment)
  TODO: - Popular searches not working fine
  TODO: - Recent searches should be linked with users
 */

const App = ({
  user,
  location,
  products,
  brands,
  collections,
  showBlogsSideMenu,
  showBlogsSearchMenu,
  setProducts,
  setBrands,
  setCollections,
  setCategories,
  history,
  setBlogCategories,
  setBlogLatest,
  setBlogGuides,
  setBlogs,
}) => {
  const [mobileSearchbar, setMobileSearchbar] = useState(false);

  const abortControllerRef = useRef(null);

  useEffect(() => {
    setBlogCategories();
    setBlogLatest();
    setBlogGuides();
    setBlogs();
  }, [setBlogCategories, setBlogGuides, setBlogLatest, setBlogs]);

  useEffect(() => {
    getCategories().then((response) => {
      if (Array.isArray(response)) {
        if (response.length) {
          setCategories(
            response[0].map((cat) => ({
              ...cat,
              name: cat.name.trim(),
              sizes: cat.sizes.split(","),
            }))
          );
        }
      }
    });

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    getShoes(abortControllerRef.current)
      .then((shoes) => {
        if (Array.isArray(shoes)) {
          setProducts(shoes);
        }
      })
      .catch((error) => {
        console.error("SHOES ERROR:", error);
      });

    getCollectionsDropdown()
      .then((response) => {
        if (Array.isArray(response)) {
          setBrands(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    getCollections()
      .then((collections) => {
        if (Array.isArray(collections)) {
          setCollections(collections);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [setBrands, setCategories, setCollections, setProducts]);

  if (!user) {
    if (
      [
        "account",
        "settings-section",
        "buying-section",
        "selling-section",
        "favourites-section",
      ].filter((route) => location.pathname.includes(route)).length
    ) {
      history.push("/login");
    }
  }

  return (
    <div
      className={`app ${
        showBlogsSideMenu || showBlogsSearchMenu ? "offcanvas" : ""
      }`}
    >
      <Helmet>
        <title>
          Live marketplace for exclusive sneakers, streetwear and accessories on
          Thriller.
        </title>
        <meta
          name="description"
          content="Enjoy Same Day delivery on your favourite Jordan 1, Air Force 1, Yeezy and more. Buy and Sell. ThrillerME verified Always in stock and ready to ship."
        />
        <link rel="canonical" href="https://thrillerme.com" />
      </Helmet>

      {["login", "twoFactorAuth"].filter((route) =>
        location.pathname.includes(route)
      ).length ? (
        <HeaderCentered />
      ) : location.pathname.includes("blogs") ? (
        <BlogsHeader brands={brands} />
      ) : (
        <Header
          mobileSearchbar={mobileSearchbar}
          setMobileSearchbar={setMobileSearchbar}
        />
      )}

      {location.pathname.includes("/blogs") && (
        <>
          <BlogsHeaderOffCanvas />
          <BlogsSearchResultOffCanvas />
        </>
      )}
      <Fragment>
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return (
                <HomePage
                  allBrands={brands}
                  allProducts={products}
                  allCollections={collections}
                />
              );
            }}
          />
          <Route
            exact
            path="/sell"
            render={() => {
              return <SellPage allProducts={products} />;
            }}
          />
          <Route exact path="/shoe" component={ShoePage} />
          {/* <Route exact path="/login" component={LoginSignupPage} /> */}
          <Route
            exact
            path="/login"
            render={(props) => {
              return <LoginSignupPage {...props} />;
            }}
          />

          <Route
            exact
            path="/login/:shoeID/:size/:offer"
            render={(props) => <LoginSignupPage {...props} />}
          />

          <Route
            exact
            path="/login/:id"
            render={(props) => <LoginSignupPage {...props} />}
          />

          <Route
            exact
            path="/logins/:sell"
            render={(props) => <LoginSignupPage {...props} />}
          />

          {/* <Route exact path="/account" component={AccountPage} /> */}

          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/altogether" component={Altogether} />
          <Route exact path="/styles" component={StylePage} />

          <Route exact path="/account" component={user ? SecurityPage : null} />
          <Route
            exact
            path="/settings-section"
            component={user ? SettingsPage : null}
          />
          <Route
            exact
            path="/buying-section"
            component={user ? BuyingPage : null}
          />
          <Route
            exact
            path="/selling-section"
            component={user ? SellingPage : null}
          />
          <Route
            exact
            path="/favourites-section"
            component={user ? FavouritePage : null}
          />
          <Route
            exact
            path="/product-review/:id/:selectedButton"
            component={ProductReview}
          />

          <Route exact path="/collections/:id/" component={Browse} />
          <Route exact path="/collections/0/:keyword" component={Browse} />
          <Route exact path="/collections/:id/size/:sizee" component={Browse} />
          {/* <Route exact path="/browse" component={Browse} /> */}

          <Route exact path="/twoFactorAuth" component={TwoFactorPage} />
          <Route
            exact
            path="/twoFactorAuth/:userType"
            component={TwoFactorPage}
          />
          <Route exact path="/buyingInfo">
            <SellerInfoPage title="Buying Info" />
          </Route>
          <Route exact path="/profileEdit" component={EditProfilePage} />
          <Route exact path="/shippingInfo/:id" component={ShippingInfoPage} />
          <Route
            exact
            path="/shippingInfo/:id/:uType"
            component={ShippingInfoPage}
          />
          <Route exact path="/sellerInfo">
            <SellerInfoPage title="Billing" />
          </Route>
          <Route exact path="/payoutInfo" component={BillingSettingPage} />

          <Route exact path="/help" component={HelpPage} />
          <Route exact path="/buy/:id" component={BuyPage} />
          <Route exact path="/buy/:id/:size/:lowestAsk" component={BuyPage} />
          <Route
            exact
            path="/bid/:id/:size/:lowestAsk/:isBid"
            component={BuyPage}
          />
          <Route exact path="/faq" component={FAQPage} />
          <Route exact path="/faqSeller" component={FaqSeller} />
          <Route exact path="/blogs" component={BlogsPage} />
          <Route exact path="/blogs/:slug" component={BlogSlugPage} />
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/terms" component={TermsPage} />
          <Route exact path="/reset" component={ResetEmail} />
          <Route exact path="/resett" component={Reset} />
          <Route exact path="/privacy" component={PrivacyPage} />
          <Route exact path="/shipping" component={ShippingPolicy} />
          <Route exact path="/refund" component={RefundPolicy} />
          <Route exact path="/:id" component={Product} />
          {/* <Route exact path="/forgot" component={Forgot} /> */}
        </Switch>
      </Fragment>

      {location.pathname === "/" ||
      location.pathname === "/faq" ||
      location.pathname === "/FaqSeller" ||
      location.pathname === "/terms" ? (
        <Footer />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    products: state.products.products,
    brands: state.products.brands,
    collections: state.products.collections,
    showBlogsSideMenu: state.blogs.showSideMenu,
    showBlogsSearchMenu: state.blogs.showSearchMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProducts: (data) => {
      dispatch({ type: Actions.SET_PRODUCTS, payload: data });
    },
    setBrands: (data) => {
      dispatch({ type: Actions.SET_BRANDS, payload: data });
    },
    setCollections: (data) => {
      dispatch({ type: Actions.SET_COLLECTIONS, payload: data });
    },
    setCategories: (data) => {
      dispatch({ type: Actions.SET_CATEGORIES, payload: data });
    },
    setBlogCategories: () => {
      getBlogCategories()
        .then((response) => {
          dispatch({
            type: Actions.SET_BLOGS_CATEGORIES,
            payload: response.data,
          });
          dispatch({ type: Actions.SET_LOADING_CATEGORIES, payload: false });
        })
        .catch((error) => {
          console.error("getBlogCategories:", error);
        });
    },
    setBlogLatest: () => {
      getBlogLatest()
        .then((response) => {
          dispatch({ type: Actions.SET_BLOGS_LATEST, payload: response.data });
          dispatch({ type: Actions.SET_LOADING_LATEST, payload: false });
        })
        .catch((error) => {
          console.error("getBlogLatest:", error);
        });
    },
    setBlogGuides: () => {
      getBlogBuyerGuide()
        .then((response) => {
          if (Array.isArray(response.data)) {
            const data = response.data[0];

            dispatch({
              type: Actions.SET_BLOGS_GUIDES,
              payload: data.attributes.Blogs.data,
            });
            dispatch({ type: Actions.SET_LOADING_GUIDES, payload: false });
          }
        })
        .catch((error) => {
          console.error("getBlogBuyerGuide:", error);
        });
    },
    setBlogs: () => {
      getBlogs()
        .then((response) => {
          dispatch({ type: Actions.SET_BLOGS, payload: response.data });
          dispatch({ type: Actions.SET_BLOGS_META, payload: response.meta });
          dispatch({ type: Actions.SET_LOADING_BLOGS, payload: false });
        })
        .catch((error) => {
          console.error("getBlogs:", error);
        });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
