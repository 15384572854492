import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faXTwitter,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

import "./ShareBlog.scss";

export default function ShareBlog({ slug, media, title }) {
  const shareUrl = `https://thrillerme.com/blogs/${slug}/`;

  return (
    <section className="share-blog sidebar">
      <div className="sidebar__contain" style={{ marginTop: "50px" }}>
        <div className="sidebar__block">
          <div className="sidebar__block-share">
            <p className="sidebar__block-share-title">share this article</p>
            <SocialMediaList {...{ slug, media, title }} />
          </div>
        </div>
      </div>
      <div className="sidebar__mobile">
        <div className="container container--v2">
          <div className="sidebar__block-share">
            <p className="sidebar__block-share-title">share this article</p>

            <SocialMediaList
              {...{ slug, media, title, shareUrl, size: "2x" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function SocialMediaList({ shareUrl, media, title, size = "1x" }) {
  return (
    <ul className="sidebar__block-share-list">
      <li className="sidebar__block-share-items">
        <a
          className="sidebar__block-share-link"
          href={`//www.facebook.com/sharer.php?u=${shareUrl}`}
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faFacebookF}
            fixedWidth={true}
            color="black"
            size={size}
          />
        </a>
      </li>
      <li className="sidebar__block-share-items">
        <a
          className="sidebar__block-share-link"
          href={`//twitter.com/share?text=${title}&url=${shareUrl}`}
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faXTwitter}
            fixedWidth={true}
            color="black"
            size={size}
          />
        </a>
      </li>
      <li className="sidebar__block-share-items">
        <a
          className="sidebar__block-share-link"
          href={`https://pinterest.com/pin/create/button/?url=${shareUrl}&media=${media}`}
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faPinterestP}
            fixedWidth={true}
            color="black"
            size={size}
          />
        </a>
      </li>
      <li className="sidebar__block-share-items">
        <a
          className="sidebar__block-share-link"
          href={`mailto:?subject=${title} from Thrillerme&amp;body=Your friend thought you might like this article from Thrillerme:%0D%0A${title} on Thrillerme — ${shareUrl}`}
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            fixedWidth={true}
            color="black"
            size={size}
          />
        </a>
      </li>
    </ul>
  );
}
