import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Select from "react-select";
import { connect } from "react-redux";

import * as Actions from "../../Redux/Actions";

import "./Sdropdown.scss";

const sortOptions = [
  { value: "desc", label: "Descending Price" },
  { value: "", label: "All" },
  { value: "featured", label: "Featured" },
  { value: "popular", label: "Popular" },
  { value: "new", label: "New" },
  { value: "asc", label: "Ascending price" },
];

const customStyles = {
  control: (base) => ({
    ...base,
    height: 35,
    minHeight: 35,
    width: "200px",
  }),
};

const Sdropdown = ({ setProducts }) => {
  const history = useHistory();
  const location = useLocation();

  const queryParameters = new URLSearchParams(window.location.search);
  let sortBy = queryParameters.get("sort_by");

  if (!sortBy) {
    sortBy = "desc";
  }

  const customTheme = (theme) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary50: "whitesmoke",
        primary25: "whitesmoke",
        primary: "gray",
      },
    };
  };

  const handleInputChange = async (selected) => {
    let params = {};

    if (selected.value.trim().length) {
      params.sort_by = selected.value;
    }
    params = new URLSearchParams(params);

    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  const defaultValue = sortOptions.find((s) => s.value === sortBy);

  return (
    <div>
      <Select
        options={sortOptions}
        styles={customStyles}
        theme={customTheme}
        onChange={handleInputChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sdropdown);
