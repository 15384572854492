import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import { Favorite, KeyboardArrowDown } from "@material-ui/icons";

import { connect } from "react-redux";
import * as Actions from "../../Redux/Actions";

import Suggestion from "../../components/suggestions/Suggestion";
import Footer from "../../components/footer/Footer";
import Links from "../../components/links/Links";
import CustomMobileSizeSelector from "../../components/size-selector-mobile/CustomMobileSizeSelector";
import ShareModal from "../../components/product-page-pills/ShareModal";
import ThreeBannerResponsive from "../../components/ThreeBannerResponsive/ThreeBannerResponsive";
import {
  getListingLowest,
  getShoeBannerType,
  getShoeCost,
  getShoeImages,
  postFav,
} from "../../utils/api";
import shareIcon from "../../assets/product/shareIcon.svg";

import { CircularProgress } from "@material-ui/core";
import ProductImageSlider from "../../components/product-image-slider/ProductImageSlider";

import PlaceBidModal from "../../components/place-bid-modal/PlaceBidModal";
import ProductDetails from "../../components/product-page/ProductDetails";
import ProductDescription from "../../components/product-page/ProductDescription";
import ShippingAndReturn from "../../components/product-page/ShippingAndReturn";
import ThrillerVerified from "../../components/product-page/ThrillerVerified";
import InstantShipping from "../../components/product-page/InstantShipping";
import PlaceBidBuyNow from "../../components/product-page/PlaceBidBuyNow";
import ProductHelmet from "../../components/product-page/ProductHelmet";
import PriceGuranteeDetails from "../../components/product-page/PriceGuranteeDetails";
import AuthenticityGurantee from "../../components/product-page/AuthenticityGurantee";
import ProductEndBoxes from "../../components/product-page/ProductEndBoxes";
import ContactCustomerCare from "../../components/product-page/ContactCustomerCare";

import "./Product.scss";

const Product = ({ user, products }) => {
  var rawid = useParams().id;
  var findid = rawid.split("_");
  const index = findid.length - 1;
  var id = findid[index].replace("-", " ");
  const history = useHistory();

  let product = products?.find((item) => `${item?.shoe_id}` === `${id}`);

  const [buyBtnPrice, setBuyBtnPrice] = useState(0);
  const [pimagesTotal, setpimagesTotal] = useState(
    product ? [product.cover_image] : []
  );
  const [bannerType, setBannerType] = useState(1);

  const [loadingSizes, setLoadingSizes] = useState(true);
  const [sizes, setSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);

  const [lowestAsk, setLowestAsk] = useState(0);

  const [showSizeChart, setSizeChart] = useState(false);
  const [shareShow, setshareShow] = useState(false);

  const [showPlaceBidModal, setShowPlaceBidModal] = useState(false);

  const closeMobileChart = () => {
    setSizeChart(false);
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    ReactGA.initialize("UA-198989119-1");
  }, []);

  // Set ShoeCost, ShoeImages and BannerType
  useEffect(() => {
    setSizes([]);
    setSelectedSize(null);

    setLoadingSizes(true);

    getShoeCost(id)
      .then((sizesResponse) => {
        if ("data" in sizesResponse) {
          if (Array.isArray(sizesResponse.data)) {
            setSizes(sizesResponse.data);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingSizes(false);
      });

    getShoeImages(id)
      .then((response) => {
        if (Array.isArray(response)) {
          response.map((dat) => {
            setpimagesTotal((preval) => [...preval, dat.imgURL]);
          });
        }
      })
      .catch((err) => {
        console.error("imgs error", err);
      });

    getShoeBannerType(id)
      .then((response) => {
        setBannerType(response.bannerType);
      })
      .catch((err) => {
        console.error("Banner ERROR:", err);
      });
  }, [id]);

  // Set Buy Button Price
  useEffect(() => {
    if (sizes.length) {
      setSelectedSize(sizes[0]);

      try {
        let lowest = 0;

        sizes.map((size) => {
          if ("cost" in size && size.cost) {
            if (lowest == 0 || lowest > parseInt(size.cost)) {
              lowest = parseInt(size.cost);
            }
            return size;
          }
        });

        setBuyBtnPrice(lowest);
      } catch (error) {
        console.error("Lowest Cost Error:", error);
      }
    }
  }, [sizes]);

  // Set Lowest Ask
  useEffect(() => {
    if (selectedSize) {
      getListingLowest(id, selectedSize.size)
        .then((lowestResponse) => {
          if (lowestResponse.lowest !== null) {
            setLowestAsk(lowestResponse.lowest);
          } else {
            setLowestAsk(0);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id, selectedSize]);

  useEffect(() => {
    if (selectedSize) {
      getListingLowest(id, selectedSize.size)
        .then((lowestResponse) => {
          if (lowestResponse.lowest !== null) {
            setLowestAsk(lowestResponse.lowest);
          } else {
            setLowestAsk(0);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id, selectedSize]);

  const addtoFavorite = () => {
    if (user === null) {
      history.push("/login");
    } else {
      const data = {
        shoe_id: parseInt(id),
        user_id: user.user_id,
        size: JSON.parse(window.localStorage.getItem("favSize")),
      };

      postFav(data)
        .then((response) => {
          if (response === "already added") {
            alert("Already added to favourite!");
          } else {
            alert("Added to favourite!");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const GoToBuy = () => {
    if (selectedSize) {
      window.localStorage.setItem("price", lowestAsk);
      history.push(`/buy/${id}/${selectedSize.size}/0`);
    } else {
      alert("Please select a size");
    }
  };

  const GoToBid = () => {
    if (selectedSize) {
      window.localStorage.setItem("price", lowestAsk);
      history.push(`/bid/${id}/${selectedSize.size}/0/bid`);
    } else {
      alert("Please select a size in order to continue");
    }
  };

  const onClickProceedPlaceBid = () => {
    GoToBid();
  };

  if (product) {
    if (product.release_date) {
      product.release_date = product.release_date.split("T")[0];
    }
  } else {
    product = {};
  }

  const isInstantShipAvailable = product.tag
    ? product.tag.toLowerCase().includes("featured")
    : false;

  return (
    <div className="product-page-container">
      <ProductHelmet product={product} />

      <div className="product-filter">
        <div className="size-selector-container">
          <button
            className="btn btn-outline-dark w-100 my-2 sizeSlctbtn ass size-chart-btn "
            style={{ borderRadius: "0px" }}
            onClick={() => setSizeChart(true)}
          >
            <div className="fill_size">
              Size:
              {loadingSizes ? (
                <CircularProgress size={15} />
              ) : (
                <span>
                  US {selectedSize ? selectedSize.size : ""}
                  <KeyboardArrowDown style={{ fontSize: "22px" }} />
                </span>
              )}
            </div>
          </button>
          {showSizeChart && (
            <CustomMobileSizeSelector
              loading={loadingSizes}
              sizes={sizes}
              closeSizeChart={closeMobileChart}
              selectSize={(size) => setSelectedSize(size)}
            />
          )}
        </div>

        {/* Place Bid & Buy Now */}
        <PlaceBidBuyNow
          showPlaceBidModal={showPlaceBidModal}
          loadingSizes={loadingSizes}
          setShowPlaceBidModal={setShowPlaceBidModal}
          GoToBuy={GoToBuy}
          GoToBid={GoToBid}
          buyBtnPrice={buyBtnPrice}
        />

        <InstantShipping available={isInstantShipAvailable} />
      </div>

      <div className="product-details col-md-9 col-sm-11 mx-auto">
        <div className="row mt-2 noExtraMargins">
          <h1 className="productName">
            {product?.name ? product?.name : "---"}
          </h1>
          <span className="productsSubName">
            {product?.colorway ? product?.colorway : "---"}
          </span>
        </div>
        <div className="row pt-3 flex-wrap">
          <div className="col-5 mobileConTextContainer1 ">
            <div className="mobileConText">
              <span>Verified Authentic</span>
            </div>
          </div>

          <div className="col-5 mobileConTextContainer2">
            <div className="mobileConText blink">
              <span>
                Condition:<span style={{ color: "#01633F" }}> New</span>
              </span>
            </div>
          </div>
        </div>
        <div className="row d-flex flex-row noExtraMargins pt-2">
          <div className="smallBtnsContainer">
            <button
              className="btn smallBtns float-left float-lg-right float-xl-right"
              onClick={addtoFavorite}
            >
              <Favorite />
            </button>
            <button
              className="btn smallBtns float-left float-lg-right float-xl-right"
              onClick={() => setshareShow(!shareShow)}
            >
              <img src={shareIcon} alt="shareIcon" />
            </button>
          </div>
        </div>

        <ShareModal
          open={shareShow}
          onCloseModal={() => setshareShow(!shareShow)}
        />

        <div className="row d-flex flex-row">
          {/* Product Image */}
          <div className="col-lg-6 col-md-12 pb-4">
            <div className="row d-none d-lg-flex d-xl-flex flex-row mb-4">
              <div className="col-6">
                <div className="conText blink">
                  <span>
                    Condition:<span style={{ color: "#ec1d25" }}> New</span>
                  </span>
                </div>
              </div>
              <div className="col-6">
                <div className="conText">
                  <span>100% Authentic</span>
                </div>
              </div>
            </div>

            <ProductImageSlider images={pimagesTotal} />
          </div>

          {/* Product Details */}
          <div className="col-lg-6 col-md-12  d-flex flex-column align-items-center justify-content-evenly h-100">
            <ThreeBannerResponsive bannerType={bannerType} />
            <div className="borderedDiv my-4  px-2 py-1 row m-0 w-100 d-none d-lg-flex d-xl-flex flex-row">
              <div style={{ padding: 0, position: "relative" }}>
                <button
                  className="btn btn-outline-dark w-100 my-2 size-chart-btn"
                  onClick={() => setSizeChart(true)}
                >
                  <div className="fill_size">
                    Size:
                    {loadingSizes ? (
                      <CircularProgress size={15} />
                    ) : (
                      <span>
                        US {selectedSize ? selectedSize.size : ""}
                        <KeyboardArrowDown style={{ fontSize: "22px" }} />
                      </span>
                    )}
                  </div>
                </button>

                {showSizeChart && (
                  <CustomMobileSizeSelector
                    loading={loadingSizes}
                    sizes={sizes}
                    closeSizeChart={closeMobileChart}
                    selectSize={(size) => setSelectedSize(size)}
                  />
                )}
              </div>

              <PlaceBidBuyNow
                showPlaceBidModal={showPlaceBidModal}
                loadingSizes={loadingSizes}
                setShowPlaceBidModal={setShowPlaceBidModal}
                GoToBuy={GoToBuy}
                GoToBid={GoToBid}
                buyBtnPrice={buyBtnPrice}
              />

              <InstantShipping available={isInstantShipAvailable} />
            </div>

            <div className="row w-100 d-flex flex-row mx-0">
              <div
                className="col-lg-6 col-md-12 d-flex align-items-center justify-content-start"
                style={{ padding: 0 }}
              >
                <span
                  className="fM p-0 m-0"
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Last Sale:{" "}
                  <b>{product.lastSold ? ` AED ${product.lastSold}` : "--"}</b>
                </span>
              </div>
            </div>
          </div>
        </div>

        <hr className="my-4 hr" />

        <Suggestion
          allProducts={products}
          collection_id={product?.collection_id ? product?.collection_id : id}
          shoe_id={product?.shoe_id ? product?.shoe_id : id}
          name="Related Products"
        />

        <div className="row">
          <div className="col-lg-6 col-md-12 mt-2">
            <ProductDetails product={product} />
          </div>
          <div className="col-lg-6 col-md-12 d-flex flex-column mt-3 mb-3">
            <ProductDescription product={product} />
          </div>

          {/* <hr className="hrThick" />

          <div className="col-lg-12 col-md-12 d-flex flex-column mt-3">
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Corrupti
              alias eum dolorem perspiciatis temporibus laborum, officiis dicta
              labore eveniet deleniti enim nesciunt adipisci voluptas assumenda
              natus cupiditate illum nulla culpa?
            </p>
          </div> */}

          <hr className="hrThick" />

          <div className="col-lg-12 col-md-12 d-flex flex-column mt-3">
            <ShippingAndReturn />
          </div>
          <hr className="mb-4 mt-3" />
        </div>

        <ThrillerVerified />

        <div className="row ">
          <hr className="hrThick" />
          <div className="col-lg-12 col-md-12 d-flex flex-column mt-3">
            <PriceGuranteeDetails />
          </div>
          <hr className="hrThick" />
          <div className="col-lg-12 col-md-12 d-flex flex-column mt-3">
            <AuthenticityGurantee />
          </div>
          <hr className="hrThick" />
        </div>

        <div className="row ">
          <ProductEndBoxes />
        </div>
      </div>

      <ContactCustomerCare />

      <PlaceBidModal
        show={showPlaceBidModal}
        handleClose={() => setShowPlaceBidModal(false)}
        proceed={onClickProceedPlaceBid}
      />

      <Links />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    products: state.products.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setID: (data) => {
      dispatch({ type: Actions.SET_SHOE_ID, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
