import React, { useRef, useEffect } from "react";
import "./shoe-buy.styles.scss";
import { useHistory, useParams } from "react-router-dom";
import PriceCalculatorBuy from "../price-calculator-buy/price-calculator-buy.component";
import { connect } from "react-redux";
import * as Actions from "../../Redux/Actions";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Pay from "./Pay";
import Hold from "./Hold";

// Stripe Live
const stripepro = loadStripe(
  "pk_live_51IiIJ9BPtBbBG1D4RECcgbhgde32T8O7otN8o4yUWnfEKlowDBlHkGg1KF8YWaAe7bfW7zAM3nrE2S9LHFGnJHbl00rZXzZiqQ"
);

// Stripe Sandbox
// const stripepro = loadStripe(
//   "pk_test_51IiIJ9BPtBbBG1D4Lw59W0SkR7Zn4rnXVN9l5De0uR6wYinpi0lKsikv4M6v3sM4ip1hUOEaPbepjDTU8mGMonhj00edd6puGr"
// );

const ShoeBuy = ({
  user,
  id,
  size,
  offer,
  amount,
  highestOffer,
  buyNow,
  lowestAsk,
  review,
  selectedButton,
  toggleButton,
  setOfferOrOrder,
  setOfferAmount,
}) => {
  const paypalRef = useRef();
  let history = useHistory();

  useEffect(() => {
    if (user === null || user === undefined) {
      history.push({
        path: "/login",
        state: {
          historyProduct: true,
          productId: id,
          size: size,
        },
      });
    } else {
      if (offer !== undefined && offer.totalBill !== undefined) {
      }
    }
  }, []);

  return (
    <div className="shoe-sell">
      {/* Highest offer */}
      <div className="sell-header">
        <div className="size-info">
          <p>Highest offer</p>
        </div>
        <span className="size-info">AED {highestOffer}</span>
      </div>

      {/* US Men Size */}
      <div className="sell-header">
        <div className="size-info">
          <p>US Men Size</p>
        </div>
        <span className="size-info">{size}</span>
      </div>

      {/* Condition */}
      <div className="sell-header">
        <div className="size-info">
          <p>Condition</p>
        </div>
        <span className="size-info">Brand New</span>
      </div>

      {/* Box Condition */}
      <div className="sell-header" style={{}}>
        <div className="size-info">
          <p>Box Condition</p>
        </div>
        <span className="size-info">Good</span>
      </div>

      <div className="sell-body">
        {!review ? (
          <div className="button-group">
            <button
              className={selectedButton ? "active" : "inactive"}
              onClick={() => {
                toggleButton(true);
                setOfferOrOrder(true);
              }}
            >
              Place offer
            </button>
            <button
              className={!selectedButton ? "active" : "inactive"}
              onClick={() => {
                toggleButton(false);
                setOfferOrOrder(false);
              }}
            >
              Buy Now
            </button>
          </div>
        ) : null}
        <PriceCalculatorBuy
          id={id}
          size={size}
          selectedButton={selectedButton}
          review={review}
          setOfferAmount={setOfferAmount}
          amount={amount}
          lowestAsk={lowestAsk}
          offer={offer}
        />
      </div>

      {buyNow === "0" && (
        <div
          className="sell-header"
          style={{
            marginTop: "20px",
          }}
        >
          <div className="size-info">
            <p>Payment Method</p>
          </div>
          <span className="size-info">Credit Card</span>
        </div>
      )}
      <br></br>
      {buyNow === "1" && <div ref={paypalRef}></div>}

      {useParams().selectedButton === "false" && (
        <Elements stripe={stripepro}>
          <Pay id={id} size={size} offer={offer} />
        </Elements>
      )}

      {/* For offers - Hold Payment */}
      {useParams().selectedButton === "true" && (
        <Elements stripe={stripepro}>
          <Hold id={id} size={size} offer={offer} />
        </Elements>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOfferOrOrder: (data) => {
      dispatch({ type: Actions.SET_OFFER_ORDER, payload: data });
    },
  };
};

export default connect(null, mapDispatchToProps)(ShoeBuy);
