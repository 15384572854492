import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentLoader from "react-content-loader";

import * as Actions from "../../../Redux/Actions";
import { getBlogsByKeyword } from "../../../utils/api";

import OffCanvas from "../../off-canvas/OffCanvas";

import { BLOGS_BASE_URL } from "../../../Constants/Global";
import "./BlogsSearchResultOffCanvas.scss";

let source = axios.CancelToken.source();

function BlogsSearchResultOffCanvas({ show, hide, keyword }) {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!show) {
      setPage(1);
    }
  }, [show]);

  useEffect(() => {
    if (keyword.trim().length > 0) {
      setLoading(true);
      setBlogs([]);

      if (source) {
        source.cancel("Operation canceled for new call");
      }
      source = axios.CancelToken.source();

      getBlogsByKeyword(keyword, page).then((response) => {
        setBlogs(response.data);
        setTimeout(() => setLoading(false), 2000);
      });
    }
  }, [keyword, page]);

  return (
    <OffCanvas show={show} hide={hide} className="blogs-search-results">
      <div className="content container">
        <p>
          {loading ? (
            "Loading..."
          ) : (
            <>
              <strong>{blogs.length}</strong> results found in{" "}
              <strong>Blogs</strong>
            </>
          )}
        </p>
        {loading ? (
          Array.from({ length: 9 }, (_, i) => i + 1).map((item) => (
            <LoadingItem key={item} className="loading-item" />
          ))
        ) : (
          <div className="search-group-list">
            {blogs.map((blog) => (
              <ResultListItem key={blog.id} blog={blog.attributes} />
            ))}
          </div>
        )}
      </div>
    </OffCanvas>
  );
}

const mapStateToProps = (state) => {
  return {
    show: state.blogs.showSearchMenu,
    keyword: state.blogs.searchKeyword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hide: () => {
      dispatch({ type: Actions.SET_BLOGS_SIDEMENU, payload: false });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogsSearchResultOffCanvas);

function LoadingItem(props) {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={130}
      viewBox="0 0 1062 130"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="15" rx="3" ry="3" width="110" height="110" />
      <rect x="128" y="35" rx="0" ry="0" width="169" height="24" />
      <rect x="128" y="75" rx="0" ry="0" width="244" height="35" />
    </ContentLoader>
  );
}

function ResultListItem({ blog }) {
  const { slug, Heading, thumbnail } = blog;

  let banner = null;
  let bannerAlt = "Blog Thumbnail";

  if ("data" in thumbnail && thumbnail.data) {
    banner = `${BLOGS_BASE_URL}${thumbnail.data.attributes.url}`;
    bannerAlt = thumbnail.data.attributes.name;
  }

  return (
    <Link to={`/blogs/${slug}/`} className="header__search-group-item">
      <img
        className="header__search-group-item-img"
        src={banner}
        alt={bannerAlt}
      />
      <div>
        <div className="header__search-group-item-top-line">
          Thrillerme Blog
        </div>
        <div className="header__search-group-item-title">{Heading}</div>
        <div className="header__search-group-item-bottom-line"></div>
      </div>
    </Link>
  );
}
