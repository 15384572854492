import React, { useEffect, useState } from "react";
import "./search-item.styles.scss";

import { Link, useHistory, useLocation } from "react-router-dom";
import { Fragment } from "react";
import SizeSelectorMobile from "../size-selector-mobile/SizeSelectorMobile";
import { makingValidName } from "../../Constants/Functions";
import { connect } from "react-redux";

const SearchItem = ({
  user,
  refreshComponent,
  setRefreshCompenent,
  shoe,
  setSearchbar,
  setInputValue,
  searchbar,
  selling,
  pending,
  buying,
  linkCheck,
  history,
}) => {
  const { sku_number, name, detail, colorway, shoe_id } = shoe;

  const History = useHistory();
  const location = useLocation();
  const [sizechart, setSizechartFav] = useState(false);
  const [sizeFav, setSizeFav] = useState(0);

  useEffect(() => {}, []);

  function saveFav(val, refreshComponent) {
    if (location.pathname === "/favourites-section" && linkCheck === false) {
      if (user === null) {
        History.push("/login");
      } else {
        setSizeFav(0);
        setSizechartFav(true);
      }
    } else if (
      location.pathname === "/favourites-section" &&
      linkCheck === true
    ) {
      History.push(`/${val}`);
    } else if (location.pathname === "/sell") {
      window.localStorage.setItem("sellShoe", JSON.stringify(shoe));
      History.push("/shoe");
    } else if (location.pathname === "/buying-section") {
      History.push(`/${val}`);
    } else if (location.pathname === "/selling-section") {
      History.push(`/${val}`);
    } else {
      setInputValue("");
      setSearchbar(!searchbar);
      History.push(`/${val}`);
    }
  }

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width;
  }

  function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  var widthScreen = getWindowDimensions();
  const newname = makingValidName(`${shoe.name}`);
  const newskunumb = makingValidName(`${shoe.sku_number}`);
  const newshoeid = makingValidName(`${shoe.shoe_id}`);

  return (
    <Link
      to={{
        pathname: "",
        state: { shoe: { shoe } },
      }}
      onClick={() =>
        saveFav(
          `${newname}_sku_${newskunumb}_id_${newshoeid}`,
          refreshComponent
        )
      }
    >
      <div className="search-item">
        <img
          src={shoe.cover_image}
          className="item-image"
          alt="item-img"
          loading="lazy"
        />
        <div className="search-text">
          <span className="search-name">{name}</span>
          <span className="search-detail">{sku_number}</span>
          <span className="search-detail">{colorway}</span>

          {widthScreen <= 481 && location.pathname === "/favourites-section" ? (
            <Fragment>
              {shoe.size !== null ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "700", color: "black" }}
                >
                  Size: {shoe.size}
                </span>
              ) : null}
              {shoe.lowestAsk !== null ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "700", color: "black" }}
                >
                  Lowest Ask: {parseFloat(shoe.lowestAsk).toFixed(2)}
                </span>
              ) : null}{" "}
              {shoe.marketValue !== null ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "700", color: "black" }}
                >
                  Market Value: {parseFloat(shoe.marketValue).toFixed(2)}
                </span>
              ) : null}
            </Fragment>
          ) : widthScreen <= 481 && selling === false ? (
            <Fragment>
              {shoe.size !== null ? (
                <span className="search-detail">Size: {shoe.size}</span>
              ) : null}
              {shoe.offerAmount !== null ? (
                <span className="search-detail">
                  Market Value: AED {shoe.offerAmount}
                </span>
              ) : null}
              {shoe.lowestAsk !== null ? (
                <span className="search-detail">
                  Lowest Ask: AED {shoe.lowestAsk}
                </span>
              ) : null}
              {shoe.status !== null ? (
                <span className="search-detail">Status: {shoe.status}</span>
              ) : null}
            </Fragment>
          ) : widthScreen <= 481 && history === true ? (
            <Fragment>
              {shoe.order_id !== null ? (
                <span className="search-detail">Order ID: {shoe.order_id}</span>
              ) : null}
              {shoe.date !== null ? (
                <span className="search-detail">
                  Purchase Date: {shoe.date}
                </span>
              ) : null}
              {shoe.status !== null ? (
                <span className="search-detail">Status: {shoe.status}</span>
              ) : null}
            </Fragment>
          ) : widthScreen <= 481 && selling === true ? (
            <Fragment>
              {shoe.size !== null ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "750", color: "black" }}
                >
                  Size: {shoe.size}
                </span>
              ) : null}
              {shoe.askingPrice !== null ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "750", color: "black" }}
                >
                  Asking Price: AED {shoe.askingPrice}
                </span>
              ) : null}
              {shoe.expiry !== null ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "750", color: "black" }}
                >
                  Expiry: {getNumberOfDays(Date(), shoe.validTill)} days
                  remaining
                </span>
              ) : null}
            </Fragment>
          ) : widthScreen <= 481 && pending === true ? (
            <Fragment>
              {shoe.order_id !== null ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "750", color: "black" }}
                >
                  Order Number: {shoe.order_id}
                </span>
              ) : null}
              {shoe.date !== undefined ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "750", color: "black" }}
                >
                  Sale Date:{" "}
                  {shoe.date !== undefined && shoe.date.split("T")[0]}
                </span>
              ) : null}
              {shoe !== null ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "750", color: "black" }}
                >
                  Price: AED {shoe.offer}
                </span>
              ) : null}
            </Fragment>
          ) : widthScreen <= 481 && buying === true ? (
            <Fragment>
              {shoe.size !== null ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "750", color: "black" }}
                >
                  Size: {shoe.size}
                </span>
              ) : null}
              {shoe.offerAmount !== null ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "750", color: "black" }}
                >
                  Order Price: AED {shoe.offerAmount}
                </span>
              ) : null}{" "}
              {shoe.lowestAsk !== null ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "750", color: "black" }}
                >
                  Lowest Ask: AED {shoe.lowestAsk}
                </span>
              ) : null}
            </Fragment>
          ) : widthScreen > 481 && selling === true ? (
            <Fragment>
              {shoe.expiry !== null ? (
                <span
                  className="search-detail"
                  style={{ fontWeight: "750", color: "black" }}
                >
                  Expiry: {getNumberOfDays(Date(), shoe.validTill)} days
                  remaining
                </span>
              ) : null}
            </Fragment>
          ) : null}
        </div>
        <div className="sizechart-search">
          {sizechart ? (
            <SizeSelectorMobile
              shoe={shoe}
              fav={true}
              setSizechartFav={setSizechartFav}
            />
          ) : null}
        </div>
      </div>
    </Link>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchItem);
