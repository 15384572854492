import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { BLOGS_BASE_URL } from "../../../Constants/Global";

import "./BlogListItem.scss";

export default function BlogListItem({ blog }) {
  const { Heading, author, PublishDate, cover, slug } = blog;

  let banner = null;
  let bannerAlt = "Category Banner";

  if (cover) {
    banner = `${BLOGS_BASE_URL}${cover.data.attributes.url}`;
    bannerAlt = cover.data.attributes.name;
  }

  return (
    <Link to={`/blogs/${slug}`} className="blog-list-item">
      <div className="blog-list-item-image">
        <div className="item-img-placeholder">
          <img src={banner} alt={bannerAlt} />
        </div>
      </div>

      <div className="item-details">
        <h3 className="headline headline--4">{Heading}</h3>
        <div className="item-meta">
          By Thrillerme - {moment(PublishDate).format("MMM dd, yyyy")}
        </div>
      </div>
    </Link>
  );
}
