import React, { useEffect } from "react";
import RP from "./assets/mail_2-01.png";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { signup } from "../../utils/api";

import "./reset-email.css";

const ResetEmail = ({ user, history }) => {
  useEffect(() => {
    //Send email
    signup({
      emailType: "forgot",
      emailTo: user.email,
    })
      .then((response) => {
        console.log("email sent", response);
      })
      .catch((err) => {
        console.error("email error", err);
      });
  }, []);

  return (
    <Grid contained className="reset_main">
      <div className="reset_img">
        <img className="resetPasswordImage" src={RP} alt="" />
      </div>
      <div className="reset_text">
        <h4>
          An email with a link to reset your password has been sent to email
        </h4>
      </div>
      <div className="reset_btn">
        <button
          className="buttonEm"
          onClick={() => history.goBack()}
          alt="Go Back"
        >
          Go Back
        </button>
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetEmail);
