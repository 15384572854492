import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import Markdown from "react-markdown";

import { BLOGS_BASE_URL } from "../../Constants/Global";

import BlogHelmet from "../../components/blog-page/BlogHelmet";
import RelatedBlogs from "../../components/blog-page/RelatedBlogs";
import BlogHeroLoader from "../../components/blog-page/BlogHeroLoader";
import RelatedBlogLoader from "../../components/blog-page/RelatedBlogsLoader";
import BlogHeadingSection from "../../components/blog-page/BlogHeadingSection";
import BlogPageImage from "../../components/blog-page/BlogPageImage";
import BlogIntroWrapper from "../../components/blog-page/BlogIntroWrapper";
import ShareBlog from "../../components/blog-page/ShareBlog";
import BlogProducts from "../../components/blog-page/BlogProducts";
import AuthorSection from "../../components/blog-page/AuthorSection";
import Footer from "../../components/footer/Footer";
import Links from "../../components/links/Links";

import "./BlogPage.scss";
import {
  getBlogsByCategory,
  getBlogsByCategorySlug,
  getRelatedBlogsByCategoryIds,
  getRelatedBlogsByCategorySlugs,
} from "../../utils/api";

export default function BlogPage({ blog }) {
  console.log("Blog Page:", blog);

  const [loading, setLoading] = useState(true);
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  const {
    Meta = {},
    slug = "",
    PublishDate = "",
    updatedAt = "",
    Heading = "",
    Author: author,
    cover,
    summary = "",
    content = "",
    Categories,
    Products,
  } = blog;

  let banner = null;
  let bannerAlt = "Blog Cover";

  useEffect(() => {
    const categoryIds = Categories.data.map((c) => c.id);

    getRelatedBlogsByCategoryIds(blog.id, categoryIds)
      .then((response) => {
        setRelatedBlogs(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [blog]);

  if (cover) {
    banner = `${BLOGS_BASE_URL}${cover.data.attributes.url}`;
    bannerAlt = cover.data.attributes.name;
  }

  return (
    <div className="blog-page">
      {Meta && <BlogHelmet meta={Meta} slug={slug} />}
      <section className="hero">
        <BlogPageImage banner={banner} bannerAlt={bannerAlt} />
        <BlogHeadingSection
          publishDate={PublishDate}
          updatedAt={updatedAt}
          heading={Heading}
          author={author}
        />
        <BlogIntroWrapper summary={summary} />
      </section>

      <section className="blog-content">
        <section>
          <div className="container container--v2">
            <div className="content">
              <Markdown>{content}</Markdown>

              <BlogProducts products={Products.data} />
            </div>
          </div>
        </section>

        <ShareBlog slug={slug} media={banner} title={Heading} />
      </section>

      <RelatedBlogs blogs={relatedBlogs} className="mb-4" />

      <AuthorSection author={author?.data?.attributes} />

      <Links />
      <Footer />
    </div>
  );
}
