import React, { useState, useEffect, useCallback, Fragment } from "react";
import { connect } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import * as Actions from "../../Redux/Actions";

import { textToUrlSlug } from "../../Constants/Functions";
import { getShoeCost } from "../../utils/api.js";

import "./FilterSection.scss";

const FilterSection = ({ loading, brands, show }) => {
  const { id, sizee } = useParams();
  const location = useLocation();

  const queryParameters = new URLSearchParams(location.search);
  let sortBy = queryParameters.get("sort_by");

  const [selectedBrand, setSelectedBrand] = useState(
    brands.find((b) => b.main_category_id == id || textToUrlSlug(b.title) == id)
  );
  const [selectedCollection, setSelectedCollection] = useState(null);

  const selectedSize = sizee;

  useEffect(() => {
    if (id == 0) {
      setSelectedBrand(null);
      setSelectedCollection(null);
      return;
    }

    let brandFound = null;
    let collectionFound = null;

    for (let i = 0; i < brands.length; i++) {
      const brand = brands[i];

      const slug = textToUrlSlug(brand.title);

      if (slug == id || brand.main_category_id == id) {
        brandFound = brand;
        break;
      }

      for (let j = 0; j < brand.children.length; j++) {
        const collection = brand.children[j];

        const slug = collection.url.split("/").pop();

        if (slug == id) {
          collectionFound = collection;
          break;
        }
      }

      if (collectionFound) break;
    }

    if (brandFound) {
      setSelectedBrand(brandFound);
      setSelectedCollection(null);
    } else if (collectionFound) {
      setSelectedCollection(collectionFound);
      setSelectedBrand(null);
    } else {
      setSelectedBrand(null);
      setSelectedCollection(null);
    }
  }, [brands, id]);

  const renderSizes = useCallback(() => {
    let sizesToRender = [];
    let url = "";

    if (selectedBrand) {
      sizesToRender = selectedBrand.sizes;
      url = `${selectedBrand.url}`;
    } else {
      url = `${selectedCollection.url}`;
      let collectionBrand = brands.find((b) =>
        b.children.find(
          (c) => c.collection_id == selectedCollection.collection_id
        )
      );
      sizesToRender = collectionBrand.sizes;
    }

    let query = "";
    if (!!sortBy) {
      query += `?sort_by=${sortBy}`;
    }

    return (
      <div className="size-selection-container">
        {sizesToRender?.map((size, index) => {
          return (
            <Link
              key={`size-${size}-${index}`}
              to={`${url}/size/${size}${query}`}
              className={`btn btn-sm ${
                selectedSize == size ? "btn-danger" : "btn-outline-danger"
              } size`}
            >
              {size}
            </Link>
          );
        })}
      </div>
    );
  }, [brands, selectedBrand, selectedCollection, selectedSize, sortBy]);

  if (!show) {
    return null;
  }

  return (
    <div className="fs-container">
      <div className="filters">
        <h4 className="sec-heading">Categories</h4>

        <ul className="filter-options">
          <li>
            <Link to="/collections/0/">
              Reset
              {selectedBrand == null && loading && (
                <span>
                  <CircularProgress size={10} />
                </span>
              )}
            </Link>
          </li>

          {brands.map((brand) => (
            <li
              key={`category-${brand.main_category_id}`}
              className={
                selectedBrand?.main_category_id === brand.main_category_id
                  ? "selected"
                  : ""
              }
            >
              <Link to={brand.url}>{brand.title}</Link>
            </li>
          ))}

          {!!selectedCollection && (
            <li className="selected">
              <Link to={selectedCollection.url}>
                {selectedCollection.title}
              </Link>
            </li>
          )}
        </ul>

        {(!!selectedBrand || !!selectedCollection) && (
          <>
            <h4 className="sec-heading">Sizes</h4>
            <ul className="filter-options">
              <li>
                <Link
                  to={
                    !!selectedBrand ? selectedBrand.url : selectedCollection.url
                  }
                >
                  Reset
                </Link>
              </li>
            </ul>
            {renderSizes()}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    brands: state.products.brands.map((b) => ({
      ...b,
      sizes: state.browse.categories.find((c) => c.id == b.main_category_id)
        ?.sizes,
    })),
    show: state.browse.showFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSection);
