import React from "react";
import { Helmet } from "react-helmet";

import { makingValidName } from "../../Constants/Functions";

export default function ProductHelmet({ product }) {
  const newname = makingValidName(`${product?.name}`);
  const newshoeid = makingValidName(`${product?.shoe_id}`);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{product?.meta_title}</title>
      <meta name="title" content={product?.meta_title} />
      <meta name="description" content={product?.meta_description} />
      <meta name="keywords" content={product?.meta_keywords} />
      <meta
        property="og:url"
        content={`https://thrillerme.com/${newname}_id_${newshoeid}`}
      />
      <meta
        property="product:retailer_item_id"
        content={product?.collection_id}
      />
      <meta property="product:price:amount" content={product?.average_price} />
      <meta property="product:price:currency" content="AED" />
      <meta property="product:availability" content="available for order" />
      <meta property="product:category" content={product?.collection_id} />
      <meta property="product:brand" content={product?.name} />
      <meta property="product:condition" content="new" />
      <meta
        property="product:item_group_id"
        content={product?.collection_id}
      ></meta>
    </Helmet>
  );
}
