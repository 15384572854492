import * as Actions from "../Actions";

const initialState = {
  loadingCategories: true,
  categories: [],
  loadingLatest: true,
  latest: [],
  loadingGuides: true,
  guides: [],
  loadingBlogs: true,
  blogs: [],
  blogsMeta: {
    page: 1,
    pageCount: 1,
    pageSize: 1,
    total: 1,
  },
  showSideMenu: false,
  searchKeyword: "",
  showSearchMenu: false,
};

const BlogsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.SET_LOADING_CATEGORIES:
      return {
        ...state,
        loadingCategories: payload,
      };
    case Actions.SET_BLOGS_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case Actions.SET_LOADING_LATEST:
      return {
        ...state,
        loadingLatest: payload,
      };
    case Actions.SET_BLOGS_LATEST:
      return {
        ...state,
        latest: payload,
      };
    case Actions.SET_LOADING_GUIDES:
      return {
        ...state,
        loadingGuides: payload,
      };
    case Actions.SET_BLOGS_GUIDES:
      return {
        ...state,
        guides: payload,
      };
    case Actions.SET_LOADING_BLOGS:
      return {
        ...state,
        loadingBlogs: payload,
      };
    case Actions.SET_BLOGS:
      return {
        ...state,
        blogs: payload,
      };
    case Actions.SET_BLOGS_META:
      return {
        ...state,
        blogsMeta: payload,
      };
    case Actions.SET_BLOGS_SIDEMENU:
      return {
        ...state,
        showSideMenu: payload,
      };
    case Actions.SET_BLOGS_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: payload,
      };
    case Actions.SET_BLOGS_SEARCHMENU:
      return {
        ...state,
        showSearchMenu: payload,
      };
    default:
      return state;
  }
};

export default BlogsReducer;
