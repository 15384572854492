import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Sdropdown from "../sort-dropdown/Sdropdown";
import * as Actions from "../../Redux/Actions";

import "./FilterSort.scss";

const FilterSort = ({ count, showFilter, setShowFilter, collectionData }) => {
  return (
    <div>
      <div className="p-container">
        <div className="f-container">
          <div
            style={{ cursor: "pointer", color: "black" }}
            onClick={() => setShowFilter(!showFilter)}
          >
            {showFilter ? (
              <div>
                <span className="filter-toggle">Hide Filters</span>
                <FontAwesomeIcon
                  icon={["fa", "chevron-down"]}
                  size="xs"
                  className="ms-1"
                />
              </div>
            ) : (
              <div>
                <span className="filter-toggle">Show Filters</span>
                <FontAwesomeIcon
                  icon={["fa", "chevron-right"]}
                  size="xs"
                  className="ms-1"
                />
              </div>
            )}
          </div>
          <div className="breadcrum ms-2">
            <span>Collection / {collectionData.title}</span>
          </div>
        </div>
        <div className="s-container">
          <Sdropdown />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    collectionData: state.browse.collectionData,
    showFilter: state.browse.showFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowFilter: (data) => {
      dispatch({ type: Actions.SET_SHOW_FILTER, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSort);
