import React, { useState } from "react";
import "./history-buying.styles.scss";

import BuyingHistoryTable from "../buying-history-table/buying-history-table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const HistoryBuying = () => {
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className="history-buying-container">
      <div className="search-container">
        <input placeholder="Search name, order no." onChange={handleChange} />
        <FontAwesomeIcon icon={["fa", "search"]} color="#3a4b3c" />
      </div>
      <BuyingHistoryTable searchValue={searchValue} />
    </div>
  );
};
