import React, { useState, useEffect } from "react";
import "./shipping-info.styles.scss";
import { AccountSettingHeader } from "../account-settings-header/account-settings-header.component";
import { connect } from "react-redux";
import { getShippings } from "../../utils/api";

const ShippingInfo = ({ user }) => {
  const [shippingData, setShippingData] = useState({});

  useEffect(() => {
    if (user) {
      getShippings(user.user_id)
        .then((response) => {
          setShippingData(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // var url = `${BASE_URL}shippings/${user.user_id}`;
    // axios
    //   .get(url)
    //   .then((res) => {
    //     setShippingData(res.data);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
  }, [user]);

  return (
    <div className="shipping-info-section">
      <AccountSettingHeader
        headerTitle="Shipping Information"
        pageRoute="shippingInfo/0"
      />
      <div className="shipping-info-content">
        {shippingData === "" ? (
          <p>You do not have any shipping information on file.</p>
        ) : (
          <div>
            {shippingData.firstName} <br></br>
            {shippingData.lastName} <br></br>
            {shippingData.phone} <br></br>
            {shippingData.country} <br></br>
            {shippingData.city} <br></br>
            {/* {shippingData.state} <br></br>
            {shippingData.zip} <br></br> */}
            {shippingData.address} <br></br>
            {/* {shippingData.address2} <br></br>{" "} */}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingInfo);
