import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";

import { BLOGS_BASE_URL } from "../../Constants/Global";
import { getBlogsByCategory } from "../../utils/api";

import BlogHelmet from "../../components/blog-page/BlogHelmet";
import RelatedBlogs from "../../components/blog-page/RelatedBlogs";
import BlogHeroLoader from "../../components/blog-page/BlogHeroLoader";
import RelatedBlogLoader from "../../components/blog-page/RelatedBlogsLoader";
import BlogHeadingSection from "../../components/blog-page/BlogHeadingSection";
import BlogPageImage from "../../components/blog-page/BlogPageImage";
import BlogIntroWrapper from "../../components/blog-page/BlogIntroWrapper";
import AuthorSection from "../../components/blog-page/AuthorSection";
import Footer from "../../components/footer/Footer";
import Links from "../../components/links/Links";
import BlogListLoadingItem from "../../components/blog-category-page/BlogListLoadingItem";
import BlogListItem from "../../components/blog-category-page/BlogListItem";
import Pagination from "../../components/blog-category-page/BlogPagination";

import "./BlogCategoryPage.scss";
import BlogCategoryHelmet from "../../components/blog-category-page/BlogCategoryHelmet";

let source = axios.CancelToken.source();

export default function BlogCategoryPage({ category }) {
  const { id, title, summary, cover } = category;
  console.log("category:", category);

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const page = queryParameters.get("page");

  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [pagination, setPagination] = useState({
    page: page ? page : 1,
    pageCount: 0,
    pageSize: 0,
    total: 0,
  });

  const { slug } = useParams();

  useEffect(() => {
    if (source) {
      source.cancel("Operation canceled for new call");
    }

    source = axios.CancelToken.source();
    setLoading(true);

    getBlogsByCategory(id, page)
      .then((response) => {
        setBlogs(response.data);
        setPagination(response.meta.pagination);
        setLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }

        console.error(error);
      });
  }, [id, page]);

  let banner = null;
  let bannerAlt = `${title} Banner`;

  if ("data" in cover && cover.data) {
    banner = `${BLOGS_BASE_URL}${cover.data.attributes.url}`;
    bannerAlt = cover.data.attributes.name;
  }

  const meta = {
    title: `${title} — Thriller Digest — Thrillerme`,
    description: summary,
    keywords: "",
  };

  return (
    <>
      <BlogCategoryHelmet meta={meta} slug={slug} />
      <div className="blog-category-page">
        <div className="main-banner">
          <div className="container">
            <h1 className="headline headline--2">{title}</h1>
            <p className={`description ${banner ? "text-shadow" : ""}`}>
              {summary}
            </p>
          </div>
          {banner && <img src={banner} alt={bannerAlt} />}
        </div>
        <section>
          <div className="container">
            <div className="blog-list">
              {loading ? (
                Array.from({ length: 9 }, (_, i) => i + 1).map((item) => (
                  <BlogListLoadingItem key={item} className="blog-list-item" />
                ))
              ) : blogs.length > 0 ? (
                blogs.map((blog) => (
                  <BlogListItem key={blog.id} blog={blog.attributes} />
                ))
              ) : (
                <p
                  style={{
                    color: "#484848",
                    marginBottom: "80px",
                  }}
                >
                  There are currently no articles in this category.
                </p>
              )}
            </div>

            {blogs.length > 0 && pagination.total > 12 && (
              <Pagination {...pagination} />
            )}
          </div>
        </section>

        <Links />
        <Footer />
      </div>
    </>
  );
}
