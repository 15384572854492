import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

export default function ShippingAndReturn() {
  const [showShippingDetails, setShowShippingDetails] = useState(false);
  return (
    <>
      <button
        className="btn"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        onClick={() => setShowShippingDetails(!showShippingDetails)}
      >
        <div className="fM2">SHIPPING & RETURNS</div>
        <div>
          {showShippingDetails && <KeyboardArrowUp />}
          {!showShippingDetails && <KeyboardArrowDown />}
        </div>
      </button>
      {showShippingDetails && (
        <div>
          <div>
            <div className="mt-3">
              You may return your order on all items marked as Return
              Applicable. You will be able to see if you your item is applicable
              for return on the Final Checkout Page.
            </div>
            <div className="mt-3">
              Delivery and processing speeds are of utmost importance to us.
              Orders ship to Thriller first for verification and typically take
              2-4 days to reach to you. All orders are shipped with expedited
              shipping to you.
            </div>
            <div className="mt-3">
              If you have any questions or concerns with regard to sizing or
              condition of a specific product on our site, please contact
              us before purchasing.
            </div>
            <div className="">
              Estimated delivery times for international orders can be found
              under our{" "}
              <a href="https://thrillerme.com/faq">‘List of Countries’</a> on
              the FAQ page. International customers do NOT pay any additional
              fees or taxes upon arrival. What you see is what you pay.
            </div>
            <div className="mt-3">
              If your country is not on our list, reach out to our logistics
              team <a href="https://thrillerme.com/contact">here</a> for
              support.
            </div>
          </div>
        </div>
      )}
    </>
  );
}
