const SHOE_DATA = [
  {
    id: 3,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 3.5,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 4,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 4.5,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 5,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 5.5,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 6,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 6.5,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 7,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 7.5,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 8,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 8.5,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 9,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 9.5,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 10,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 10.5,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 11,
    name: "Adidas Yeezy",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/2.png",
    price: 280,
  },
  {
    id: 11.5,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 12,
    name: "Black Converse",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/3.png",
    price: 110,
  },
  {
    id: 12.5,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 13,
    name: "Nike White AirForce",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    //imageUrl: 'https://i.ibb.co/1RcFPk0/white-nike-high-tops.png',
    imageUrl: "https://appick.io/u/thriller/4.png",
    price: 160,
  },
  {
    id: 13.5,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
  {
    id: 14,
    name: "Nike Red High Tops",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/5.png",
    price: 160,
  },
  {
    id: 15,
    name: "Nike Brown High Tops",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/6.png",
    price: 160,
  },
  {
    id: 16,
    name: "Air Jordan Limited",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/7.jpg",
    price: 190,
  },
  {
    id: 17,
    name: "Timberlands",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 200,
  },
  {
    id: 18,
    name: "Adidas NMD",
    detail: "Blue Jay / Summit",
    colorway: "White / Blue",
    imageUrl: "https://appick.io/u/thriller/1.png",
    price: 220,
  },
];
export default SHOE_DATA;
