import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Mousewheel,
  Thumbs,
} from "swiper";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import "./ProductImageSlider.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel, Thumbs]);

export default function ProductImageSlider({ images = [] }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="product-image-slider">
      <Swiper
        spaceBetween={10}
        // navigation
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Navigation, Thumbs, Scrollbar]}
        className="main-swiper"
        scrollbar={{ draggable: true }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={`image-${index}`}>
            <img
              className="img-fluid"
              src={image}
              alt={`Product ${index + 1}`}
              style={{ margin: "0px auto 0px 12.5%", width: "70%" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[Navigation, Thumbs]}
        className="thumbs-swiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={`image-${index}`}>
            <img
              className="img-fluid"
              src={image}
              alt={`Product ${index + 1}`}
              style={{ margin: "0px auto 0px 12.5%", width: "70%" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
