import React from "react";
import { Link } from "react-router-dom";

import "./InstantShipping.scss";

export default function InstantShipping({ available }) {
  return (
    <div className="my-2 instant-shipping">
      {available ? (
        <label>
          <Rocket />
          <strong>Instant Shipping </strong>
          Same day delivery at checkout
        </label>
      ) : (
        <Link to="/collections/sneakers/size/10?sort_by=featured">
          <Rocket />
          <strong>Instant Shipping </strong>
          available on selected sizes
        </Link>
      )}
    </div>
  );
}

const Rocket = ({ className, size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      focusable="false"
      className="rocket"
    >
      <path
        d="M19.1001 36.2C17.2001 38.1 14.5001 38.4 13.1001 36.9C11.6001 35.4 11.9001 32.7 13.8001 30.9C14.0001 30.7 14.3001 30.5 14.5001 30.3C12.6001 29.7 10.7001 30 9.40006 31.3C9.40006 31.3 6.70006 34.1 7.40006 42.6C7.40006 42.6 15.1001 44 18.7001 40.6C20.0001 39.4 20.3001 37.4 19.7001 35.5C19.5001 35.7 19.3001 35.9 19.1001 36.2Z"
        className="flame"
      ></path>
      <path d="M42.6999 8.29998L42.5999 7.39998L41.6999 7.29998C41.5999 7.29998 29.9999 5.89998 20.8999 15L15.3999 14.2L8.3999 21.6L15.0999 23.2C14.4999 24.3 13.9999 25.5 13.4999 26.8L13.1999 27.5L22.4999 36.8L23.1999 36.5C24.4999 36 25.6999 35.5 26.7999 34.9L28.3999 41.7L35.7999 34.7L34.9999 29C44.0999 19.9 42.6999 8.39998 42.6999 8.29998ZM23.0999 33.8L16.0999 26.8C22.6999 9.99998 36.4999 9.39998 40.2999 9.59998C40.4999 13.4 39.8999 27.2 23.0999 33.8Z"></path>
      <path d="M26.9001 18C25.5001 19.4 25.5001 21.6 26.9001 22.9C28.3001 24.3 30.5001 24.3 31.8001 22.9C33.2001 21.5 33.2001 19.3 31.8001 18C30.5001 16.7 28.3001 16.7 26.9001 18Z"></path>
    </svg>
  );
};
