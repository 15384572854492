import MenuItems from "./MenuItems";

const Dropdown = ({ children, dropdown, depthLevel }) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

  let hasScroll = false;

  if (Array.isArray(children)) {
    if (children.length > 17) {
      hasScroll = true;
    }
  }

  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
      {children.map((child, index) => {
        child.url = `/collections/${child.title
          .replace(/\s+/g, "-")
          .toLowerCase()}`;
        return <MenuItems items={child} key={index} depthLevel={depthLevel} />;
      })}
      {hasScroll && <div className="scroll-indicator"></div>}
    </ul>
  );
};

export default Dropdown;
