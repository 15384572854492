export const SAVE_USER = "SAVE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const LOGOUT = "LOGOUT";
export const SAVE_SHOE_SIZE = "SAVE_SHOE_SIZE";
export const SAVE_DAYS = "SAVE_DAYS";
export const SAVE_RETURNABLE = "SAVE_RETURNABLE";
export const SAVE_CHARGES = "SAVE_CHARGES";
export const SET_SIZE = "SET_SIZE";
export const SET_SHOE_ID = "SET_SHOE_ID";
export const SET_SHOE_AMMOUNT_DETAILS = "SET_SHOE_AMMOUNT_DETAILS";
export const SET_OFFER_ORDER = "SET_OFFER_ORDER";
export const SET_OFFER_AMMOUNT = "SET_OFFER_AMMOUNT";
export const SET_BANNER = "SET_BANNER";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_BRANDS = "SET_BRANDS";
export const SET_COLLECTIONS = "SET_COLLECTIONS";
export const SET_SELECTED_SECTION = "SET_SELECTED_SECTION";

// Browse Reducer
export const SET_LOADING = "SET_LOADING";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_COLLECTION_DATA = "SET_COLLECTION_DATA";
export const SET_SHOW_FILTER = "SET_SHOW_FILTER";
export const SET_SHOW_MOBILE_FILTER = "SET_SHOW_MOBILE_FILTER";
export const SET_SHOP_PRODUCTS = "SET_SHOP_PRODUCTS";

// Blogs Reducer
export const SET_LOADING_CATEGORIES = "SET_LOADING_CATEGORIES";
export const SET_BLOGS_CATEGORIES = "SET_BLOGS_CATEGORIES";
export const SET_LOADING_LATEST = "SET_LOADING_LATEST";
export const SET_BLOGS_LATEST = "SET_BLOGS_LATEST";
export const SET_LOADING_GUIDES = "SET_LOADING_GUIDES";
export const SET_BLOGS_GUIDES = "SET_BLOGS_GUIDES";
export const SET_LOADING_BLOGS = "SET_LOADING_BLOGS";
export const SET_BLOGS = "SET_BLOGS";
export const SET_BLOGS_META = "SET_BLOGS_META";
export const SET_BLOGS_SIDEMENU = "SET_BLOGS_SIDEMENU";
export const SET_BLOGS_SEARCH_KEYWORD = "SET_BLOGS_SEARCH_KEYWORD";
export const SET_BLOGS_SEARCHMENU = "SET_BLOGS_SEARCHMENU";
