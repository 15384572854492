import React, { useState, useEffect } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import axios from "axios";
import SearchResult from "../../components/search-result/search-result.component";
import { makingValidName } from "../../Constants/Functions";
import { BASE_URL } from "../../Constants/Global";
import {
  getSearchesPopular,
  getSearchesRecent,
  postSearches,
} from "../../utils/api";

import "./Searchbar.scss";
import { connect } from "react-redux";

const Searchbar = ({
  user,
  searchbar,
  setSearchbar,
  allProducts,
  allBrands,
  history,
}) => {
  const [filterProducts, setFilterProducts] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [recent, setRecent] = useState([]);
  const [popular, setPopular] = useState([]);
  const [isSearching, setSearching] = useState(false);

  useEffect(() => {
    let userID = 0;

    if (user !== null) {
      userID = user.user_id;
    }

    getSearchesRecent(userID)
      .then((response) => {
        if (Array.isArray(response)) {
          setRecent(response);
        }
      })
      .catch((error) => {
        console.error("getSearchesRecent:", error);
      });

    getSearchesPopular()
      .then((response) => {
        if (Array.isArray(response)) {
          setPopular(response);
        }
      })
      .catch((error) => {
        console.error("getSearchesPopular:", error);
      });
  }, [searchbar, user]);

  function goTo(rout) {
    setSearchbar(!searchbar);
    history.push(`/collections/0/${rout}/`);
  }

  const handlechange = (e) => {
    if (e.target.value.length === 0) {
      setSearchbar(false);
    } else {
      setSearchbar(true);
    }
    setInputValue(e.target.value);

    var enterdValue = makingValidName(`${e.target.value}`);
    setFilterProducts(
      allProducts?.filter(
        (data) =>
          makingValidName(`${data.name}`)?.includes(enterdValue) ||
          makingValidName(`${data.name}`) === enterdValue ||
          makingValidName(`${data.colorway}`)?.includes(enterdValue) ||
          makingValidName(`${data.colorway}`) === enterdValue ||
          makingValidName(`${data.sku_number}`)?.includes(enterdValue) ||
          makingValidName(`${data.sku_number}`) === enterdValue
      )
    );
  };

  function SaveSearch(key) {
    if (
      inputValue !== null &&
      inputValue !== "" &&
      inputValue.trim().length > 1
    ) {
      let userID = 0;
      if (user !== null) {
        userID = user.user_id;
      }

      window.localStorage.setItem("filter", null);

      postSearches({
        user_id: userID,
        keyword: key,
      })
        .then((response) => {
          const data = { keyword: inputValue, userId: userID };
          console.log("DATA:", data);

          setInputValue("");

          history.replace(`/collections/0/${data.keyword}`);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  const handleSubmit = async (e) => {
    setSearchbar(!searchbar);
    e.preventDefault();

    SaveSearch(inputValue);
  };

  return (
    <div className={`sbar`}>
      <div className="searchBarComponentMain">
        <div
          className={searchbar ? "clear" : ""}
          onClick={() => setSearchbar(!searchbar)}
        ></div>
        <nav
          className={`nav-menus nav-menus-right ${searchbar ? "active" : ""}`}
        >
          <div className="searchBox">
            <div className="search-container">
              <form onSubmit={handleSubmit}>
                <input
                  style={{
                    fontWeight: "1000",
                    fontSize: "18px",
                    textTransform: "uppercase",
                  }}
                  name="input"
                  onChange={handlechange}
                  value={inputValue}
                  placeholder="TYPE TO SEARCH"
                />
                <i className="fa fa-search"></i>
              </form>
            </div>
          </div>

          {isSearching ? (
            <i
              className="fas fa-circle-notch fa-spin"
              style={{
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            ></i>
          ) : null}

          {(inputValue == "" || inputValue == null) && recent.length > 0 && (
            <h6>Recent Searches</h6>
          )}

          {(inputValue == "" || inputValue == null) && (
            <ul className="nav-menu-items">
              {recent.map((item, index) => {
                return (
                  <li
                    key={`nav-item-${item.id}-${index}`}
                    className="search-text"
                    onClick={() => goTo(item.keyword)}
                  >
                    <Link to="#">
                      <span className="nav-text">
                        <div className="hover">{item.keyword}</div>
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}

          {(inputValue == "" || inputValue == null) && (
            <h6>Popular Searches</h6>
          )}
          {(inputValue == "" || inputValue == null) && (
            <ul className="nav-menu-items">
              <li
                className="navbar-toogle"
                onClick={() => setSearchbar(!searchbar)}
              >
                <Link to="#" className="menu-bars"></Link>
              </li>
              {popular.map((item, index) => {
                return (
                  <li
                    key={`item-${index}`}
                    className="search-text"
                    onClick={() => goTo(item.keyword)}
                  >
                    <Link to="#">
                      <span className="nav-text">
                        <div className="hover">{item.keyword}</div>
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}

          {inputValue.length > 0 && (
            <SearchResult
              className="result"
              SearchResult={filterProducts}
              setSearchbar={setSearchbar}
              setInputValue={setInputValue}
              searchbar={searchbar}
            />
          )}
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    allProducts: state.products.products,
  };
};

export default connect(mapStateToProps)(withRouter(Searchbar));
