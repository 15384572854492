import React from "react";

import "./OffCanvas.scss";

export default function OffCanvas({ show, hide, children, className = "" }) {
  return (
    <div className={`off-canvas ${className} ${show ? "show" : ""}`}>
      {children}
    </div>
  );
}
