import React, { useState, useEffect } from "react";
import "./selling-history-table.styles.scss";

import SettingItem from "../setting-item/setting-item.component";

import { getOrderSellerHistory } from "../../utils/api";
import { connect } from "react-redux";

const SellingHistoryTable = ({ user, searchValue }) => {
  const [buy, setBuy] = useState([]);
  const [oData, setOdata] = useState([]);

  useEffect(() => {
    getOrderSellerHistory(user.user_id)
      .then((response) => {
        setBuy(response);
        setOdata(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="selling-history-table-container">
      <div className="table-header">
        <p className="heading-title">Item</p>
        <p className="heading-title tier-two">Order Number</p>
        <p className="heading-title tier-two">Sale Date</p>
        <p className="heading-title">Price</p>
        <p className="heading-title tier-two">Status</p>
      </div>

      {searchValue === "" && buy
        ? buy.map((shoe, ind) => (
            <SettingItem history={true} key={shoe.id} shoe={shoe} />
          ))
        : null}

      {searchValue !== "" &&
        buy !== null &&
        buy
          .filter((shoe) =>
            shoe.name
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase())
              ? shoe
              : null
          )
          .map((shoe) => (
            <SettingItem history={true} key={shoe.id} shoe={shoe} />
          ))}

      {/* {SHOE_DATA.filter((shoe) =>
        shoe.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
          ? shoe
          : null
      ).map((shoe) => (
        <SettingItem history={true} key={shoe.id} shoe={shoe} />
      ))} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellingHistoryTable);
