import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

export default function PriceGuranteeDetails() {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <>
      <button
        className="btn"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        onClick={() => setShowDetails(!showDetails)}
      >
        <div className="fM2">PRICE GUARANTEE</div>
        <div>
          {showDetails && <KeyboardArrowUp />}
          {!showDetails && <KeyboardArrowDown />}
        </div>
      </button>
      {showDetails && (
        <>
          <div className="mt-3">We guarantee your Best Buy, every time.</div>
          <div className="mt-3">
            Due to our unique bidding system and marketplace platform, as well
            as our Authenticity guarantee, we’re giving you access to the most
            limited items in the world at a regulated price, decided by the only
            fair regulator-you. Choose a price that you’re willing to pay, or
            Buy Now.
          </div>
          <div className="mt-3">
            But rest assured that whatever you choose, you’re getting the item
            you want at the fairest price out there. That is our Guarantee
          </div>
          <div className="mt-3">
            Already made your purchase and are not satisfied? Don’t worry! Our
            customer service team will ensure that we make it up to you. Check
            out these{" "}
            <a href="https://uk.trustpilot.com/review/thrillerme.com?languages=all">
              1000’s of customers
            </a>{" "}
            that trust us already.
          </div>
        </>
      )}
    </>
  );
}
