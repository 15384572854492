import React from "react";
import { Link } from "react-router-dom";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";

import { BLOGS_BASE_URL } from "../../../Constants/Global";

import "./BlogProducts.scss";

export default function BlogProducts({ products = [] }) {
  return (
    <div className="blog-products">
      {products.map((product) => (
        <ProductItem key={product.id} product={product.attributes} />
      ))}
    </div>
  );
}

const ProductItem = ({ product }) => {
  console.log("BlogProducts:", product);
  const {
    LowestAsk,
    createdAt,
    description,
    image,
    thumbnail,
    title,
    updatedAt,
    url,
  } = product;

  return (
    <div className="product-item">
      <div className="image-container">
        <ProductImage image={image} />
      </div>
      <Link className="title-link" to={{ pathname: url }} target="_blank">
        <h3>{title}</h3>
      </Link>
    </div>
  );
};

const ProductImage = ({ image }) => {
  if (image?.data == null)
    return <img src="/images/shoe_placeholder.png" alt="blog-product" />;

  if (image?.data?.length == 1) {
    const img = image.data[0].attributes;

    let imgSrc = `${BLOGS_BASE_URL}${img.url}`;
    let imgAlt = img.name;

    return <img src={imgSrc} alt={imgAlt} />;
  }

  return (
    <Splide
      options={{
        perPage: 1,
        arrows: true,
        drag: true,
        snap: true,
        pagination: false,
        type: "loop",
      }}
    >
      {image.data?.map((img) => (
        <SplideSlide key={img.id}>
          <img
            src={`${BLOGS_BASE_URL}${img.attributes.url}`}
            alt={img.attributes.name}
          />
        </SplideSlide>
      ))}
    </Splide>
  );
};
