import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import axios from "axios";
import LocalStorage from "redux-persist/es/storage";
import { sidebarData } from "../../temporary-data/sidebarData";
import CategoryBar from "../cat-sidebar/CategoryBar";
import CollectionBar from "../cat-sidebar/collection-sidebar/CollectionBar";
import "./Sidebar.css";
import { BASE_URL } from "../../Constants/Global";
import { getRegistrations, getSellers } from "../../utils/api";

const Sidebar = ({ allBrands, sidebar, hide, user, history }) => {
  const [showCategoryBar, setShowCategoryBar] = useState(false);
  const [showCollectionBar, setShowCollectionBar] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  let isAuthenticated = false;
  let hasPayout = false;
  let hasShipping = false;

  useEffect(() => {
    initialSetup();
  }, [user]);

  useEffect(() => {
    if (!!selectedCategory) {
      setShowCollectionBar(true);
    }
  }, [selectedCategory]);

  const initialSetup = async () => {
    try {
      let authData = await getRegistrations(user.user_id);

      if (authData.isAuthenticated === 1) {
        isAuthenticated = true;
      }

      let data = await getSellers(user.user_id);

      if (data !== "") {
        hasShipping = true;
      }

      try {
        var payData = await axios.get(BASE_URL + `payout/${user.user_id}`);

        if (payData.data !== "") {
          hasPayout = true;
        }
      } catch (error) {}
    } catch (e) {}
  };

  const setupChecks = async () => {
    await initialSetup();

    if (!user) {
      history.push("/sell");
      hide();
      return;
    }

    var url = BASE_URL + `registrations/${user.user_id}`;
    axios
      .get(url)
      .then((res) => {
        if (!res.data.isAuthenticated) {
          history.push({
            pathname: "/twoFactorAuth",
            state: {
              hasShipping: hasShipping,
              hasPayout: hasPayout,
              historyShoe: true,
            },
          });
        } else if (!hasShipping) {
          history.push({
            pathname: "/shippingInfo/1",
            state: {
              hasPayout: hasPayout,
              historyShoe: true,
            },
          });
        } else if (!hasPayout) {
          history.push({
            pathname: "/payoutInfo",
            state: {
              historyShoe: true,
            },
          });
        } else {
          history.push("/sell");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        hide();
      });
  };

  const menuItems = [
    {
      title: "Sell Now",
      onClick: setupChecks,
    },
    {
      title: "Shop All",
      onClick: () => {
        hide();
        window.window.localStorage.setItem("filter", null);
        window.localStorage.removeItem("selectedSection");
        history.push("/collections/0/");
      },
    },
    {
      title: "Styles",
      onClick: () => {
        history.push("/styles");
        hide();
      },
    },
    {
      title: "Collections",
      onClick: () => setShowCategoryBar(true),
    },
    {
      title: "About",
      onClick: () => {
        hide();
        history.push("/about");
      },
    },
    {
      title: "Blogs",
      onClick: () => {
        hide();
        history.push("/blogs");
      },
    },
    {
      title: "Accounts",
      onClick: () => {
        if (user === null) {
          window.localStorage.setItem("coming", {
            path: "accounts",
            id: null,
          });
          history.push({
            pathname: "/login",
            data: "accounts",
          });
        } else {
          history.push("/settings-section");
        }
        hide();
      },
    },
  ];

  if (user) {
    menuItems.push({
      title: "Logout",
      onClick: () => {
        window.window.localStorage.clear();
        window.location.href = window.location.origin + "/login";
      },
    });
  } else {
    menuItems.push({
      title: "Log In / Sign Up",
      onClick: () => {
        history.push({
          pathname: "/login",
        });
      },
    });
  }

  const hideSideBar = () => {
    setShowCollectionBar(false);
    setShowCategoryBar(false);
    hide();
  };

  const hideSubMenu = () => {
    if (showCategoryBar || showCollectionBar) {
      if (showCollectionBar) {
        setSelectedCategory(null);
        setShowCollectionBar(false);
      } else {
        setShowCategoryBar(false);
      }
    }
  };

  return (
    <div className={`sbar`}>
      <div className={sidebar ? "clear" : ""} onClick={hide}></div>
      <nav
        className={`nav-menus nav-menus-left  ${sidebar ? "active" : ""}`}
        style={{ marginBottom: "150px !important" }}
      >
        <div className="brand">
          <div
            onClick={() => {
              hideSideBar();
              history.push("/#home");
            }}
          >
            <img
              src="/images/logo.svg"
              className="navbrand"
              width="150px"
              alt="logo"
            />
          </div>
        </div>

        {showCategoryBar ? (
          <>
            <div className="heading-container">
              <div className="back-icon" onClick={hideSubMenu}>
                {/* <FontAwesomeIcon icon={faArrowLeft} /> */}
                <img
                  src="https://thrillerme.com/cdn/file-1717688174427-536379320.svg"
                  style={{ marginBottom: "5px" }}
                />
              </div>
              <div className="heading-text">
                {showCollectionBar ? selectedCategory.title : "Collections"}
              </div>
            </div>
            {showCollectionBar ? (
              <CollectionBar
                collections={selectedCategory.children}
                hideSideBar={hideSideBar}
                hide={hideSubMenu}
              />
            ) : (
              <CategoryBar
                allBrands={allBrands}
                hideSideBar={hideSideBar}
                hide={hideSubMenu}
                selectCategory={setSelectedCategory}
              />
            )}
          </>
        ) : (
          <ul className="nav-menu-items">
            <li className="navbar-toogle">
              <Link to="#" className="menu-bars"></Link>
            </li>
            {menuItems.map((item, index) => (
              <li
                style={{ border: "5px", borderColor: "black" }}
                key={index}
                onClick={item.onClick}
              >
                <div className="nav-text">
                  <span>
                    <div className="hover">{item.title}</div>
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    allBrands: state.products.brands,
  };
};

export default connect(mapStateToProps)(withRouter(Sidebar));
