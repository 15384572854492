import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import * as Actions from "../../Redux/Actions";

import NavSearchBar from "../searchbar/NavSearchBar";
import MenuItems from "../nav-item-dropdown/MenuItems";
import Sidebar from "../sidebar/Sidebar";
import Searchbar from "../searchbar/Searchbar";

import HumberMenu from "../../assets/Header/HumberMenu.svg";
import SearchHeader from "../../assets/Header/SearchHeader.svg";

import "react-simple-flex-grid/lib/main.css";
import "./Header.scss";

const Header = ({
  mobileSearchbar,
  setMobileSearchbar,
  dropShadow,
  logout,
  user,
  products,
  brands,
}) => {
  const [sidebar, setSidebar] = useState(false);

  const menuItems = [
    {
      title: "Browse",
      children: brands,
    },
    {
      title: "Shop All",
      url: "/collections/0/",
      onClick: () => {
        window.localStorage.setItem("filter", null);
      },
    },
    {
      title: "Styles",
      url: "/styles",
    },
    {
      title: "About",
      url: "/about",
    },
    {
      title: "Blogs",
      url: "/blogs",
    },
    {
      title: "Sell",
      url: "/sell",
      styles: {
        color: "red",
      },
    },
  ];

  return (
    <Navbar bg="light" expand={"lg"} fixed={"top"}>
      <button onClick={() => setSidebar(true)} className="navbar-toggle-btn">
        <img src={HumberMenu} alt="HumberMenu" />
      </button>
      <Navbar.Brand href="/#home">
        <img
          src="/images/logo.svg"
          className="navbrand"
          width="200px"
          alt="logo"
        />
      </Navbar.Brand>
      <button
        onClick={() => setMobileSearchbar(true)}
        className="navbar-toggle-btn searchBtnHeader"
      >
        <img src={SearchHeader} alt="SearchHeader" />
      </button>

      <Navbar.Collapse>
        <NavSearchBar />
        <Nav navbarScroll>
          <ul className="menus">
            {menuItems.map((item, index) => {
              const depthLevel = 0;
              return (
                <MenuItems
                  items={item}
                  key={index}
                  depthLevel={depthLevel}
                  onClick={
                    "onClick" in item
                      ? item.onClick
                      : () => {
                          console.log(item);
                        }
                  }
                />
              );
            })}
          </ul>

          <div className="auth-buttons">
            {user && user?.user_id ? (
              <>
                <Link
                  to="/account"
                  className="mx-2 navs nav-link centeredBtnsClass"
                >
                  Accounts
                </Link>
                <button className="btn btn-danger" onClick={logout}>
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/login" className="btn btn-outline-secondary ">
                  Login
                </Link>
                <Link to="/login?signup=true" className="btn btn-danger">
                  Signup
                </Link>
              </>
            )}
          </div>
        </Nav>
      </Navbar.Collapse>

      <Sidebar sidebar={sidebar} hide={() => setSidebar(false)} />
      <Searchbar
        searchbar={mobileSearchbar}
        setSearchbar={setMobileSearchbar}
      />
    </Navbar>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    products: state.products.products,
    brands: state.products.brands,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch({ type: Actions.LOGOUT });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
