import React from "react";
import { connect } from "react-redux";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";

import GuideBlogSlide from "./GuideBlogSlide";

import "./BlogsBuyersGuideSection.scss";

function BlogsBuyersGuideSection({ loading, blogs, className = "" }) {
  return (
    <Splide
      hasTrack={false}
      tag="section"
      aria-label="Thrillerme Buyer's Guide"
      className={`buyers-guide ${className}`}
      options={{
        autoWidth: true,
        gap: "1rem",
        arrows: true,
        drag: "free",
        snap: true,
        pagination: false,
        // lazyLoad: "nearby",
        type: "loop",
      }}
    >
      <div className="bg-image">
        <img src="/images/buyers-guide-bg.svg" alt="" />
      </div>
      <div className="custom-wrapper">
        <h5 className="header">Thrillerme Buyer's Guide</h5>
        <SplideTrack>
          {blogs.map((blog) => (
            <SplideSlide key={blog.id}>
              <GuideBlogSlide blog={blog.attributes} />
            </SplideSlide>
          ))}
        </SplideTrack>
      </div>
    </Splide>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.blogs.loadingGuides,
    blogs: state.blogs.guides,
  };
};

export default connect(mapStateToProps)(BlogsBuyersGuideSection);
