import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { BLOGS_BASE_URL } from "../../../Constants/Global";

import "./BlogSlide.scss";

export default function BlogSlide({ blog, className = "" }) {
  const {
    Meta = {},
    slug = "",
    PublishDate = "",
    updatedAt = "",
    Heading = "",
    Author: author,
    cover,
    summary = "",
    content = "",
  } = blog;

  let banner = null;
  let bannerAlt = "Blog Cover";

  if (cover) {
    banner = `${BLOGS_BASE_URL}${cover.data.attributes.url}`;
    bannerAlt = cover.data.attributes.name;
  }

  let infoMeta = "";

  if (author?.data?.attributes) {
    infoMeta += `BY ${author?.data?.attributes.name} `;
  }

  infoMeta += moment(PublishDate).format("MMMM D, YYYY");

  return (
    <Link to={`/blogs/${slug}`} className="blog-slide">
      <img alt={bannerAlt} src={banner} />
      <div className="slide-info">
        <div className="info-block">
          <p className="headline">{Heading}</p>
          <p className="slide-info-meta">{infoMeta}</p>
        </div>
      </div>
    </Link>
  );
}
