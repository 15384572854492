import React from "react";
import ContentLoader from "react-content-loader";

export default function BlogHeroLoader({
  width = 640,
  height = 480,
  duration = 1,
  ...props
}) {
  return (
    <ContentLoader
      speed={duration}
      width={width}
      height={height}
      viewBox="0 0 640 480"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="70" y="259" rx="2" ry="2" width="80" height="6" />
      <rect x="30" y="65" rx="2" ry="2" width="48" height="10" />
      <rect x="320" y="0" rx="2" ry="2" width="320" height="480" />
      <circle cx="45" cy="255" r="15" />
      <rect x="70" y="248" rx="2" ry="2" width="62" height="4" />
      <rect x="30" y="89" rx="2" ry="2" width="269" height="42" />
      <rect x="30" y="144" rx="2" ry="2" width="164" height="42" />
      <rect x="0" y="285" rx="2" ry="2" width="320" height="91" />
    </ContentLoader>
  );
}
