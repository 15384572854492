import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./PlaceBidModal.scss";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export default function PlaceBidModal({ show, handleClose, proceed }) {
  return (
    <Modal
      dialogClassName="place-bid-modal"
      show={show}
      onHide={handleClose}
      dialogAs={"div"}
    >
      <div className="mobile-header">
        <h3 className="title">Place Bid</h3>

        <button className="btn close-modal-btn" onClick={handleClose}>
          <FontAwesomeIcon icon={faClose} fixedWidth />
        </button>
      </div>

      <div className="modal-body">
        <div className="header">
          <div className="close-modal-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} fixedWidth />
          </div>
          <div className="wrapper">
            <h1>Introducing Express Ship</h1>
            <h1>3-Day Delivery</h1>

            <div className="button-group">
              <Link
                to="/collections/sneakers/size/10?sort_by=featured"
                className="btn btn-express-ship"
              >
                Shop Express Ship
              </Link>
              <Link to="#" className="learn-more-link">
                Learn More
              </Link>
            </div>
          </div>
        </div>

        <div className="body">
          <div className="wrapper">
            <h1 className="title">How do I buy on Thriller?</h1>

            <div className="step row bid-or-buy">
              <div className="col-md-6 d-flex justify-content-start align-items-start">
                <div className="step-image">
                  <img
                    src="/images/place-bid-modal/bid-or-buy.png"
                    alt="bid-or-buy"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="content">
                  <h1>01</h1>
                  <h4>Bid or Buy</h4>
                  <p>
                    On Thriller there are two ways to buy: Bid at the price of
                    your choice and watch our dynamic marketplace come to life,
                    or Buy Now at the lowest Ask to secure your item without any
                    gamesmanship.
                  </p>
                </div>
              </div>
            </div>

            <div className="step row we-verify">
              <div className="col-md-6">
                <div className="content">
                  <h1 className="outline">02</h1>
                  <h4>We Verify</h4>
                  <p>
                    Independent authenticators verify the box, sizing labels,
                    soles, stitching, logos, heel tabs, laces, and more. Once a
                    pair is verified as authentic, the left sneaker receives a
                    unique QR-enabled tag that provides detailed information
                    about the pair's authenticity.
                  </p>
                </div>
              </div>
              <div className="col-md-5 d-flex justify-content-end align-items-start">
                <div className="step-image">
                  <img
                    src="/images/place-bid-modal/we-verify.png"
                    alt="we-verify"
                  />
                </div>
              </div>
            </div>

            <div className="step row flex-on-everyone">
              <div className="col-md-6 d-flex justify-content-start align-items-start">
                <div className="step-image">
                  <img
                    src="/images/place-bid-modal/flex-on-everyone.png"
                    alt="we-verify"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="content">
                  <h1 className="outline">03</h1>
                  <h4>Flex on Everyone</h4>
                  <p>
                    After we verify your order, we send it your way ASAP. Once
                    it arrives you should feel free to flex on everyone by
                    sharing with the hashtag #GotItOnThriller. We’ll be sure to
                    show you some love as well
                  </p>
                </div>
              </div>
            </div>
          </div>

          <img
            src="/images/place-bid-modal/+++.png"
            alt="+++"
            className="graphic plus"
          />
          <img
            src="/images/place-bid-modal/body_graphic.png"
            alt="body_graphic"
            className="graphic poly"
          />
        </div>

        <div className="footer">
          <div className="graphic-container">
            <iframe
              className="video-frame"
              src="https://www.youtube.com/embed/hl2zbNd7ocM?si=KNSqNHFtg7KWaVXW"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
            <img
              src="/images/place-bid-modal/footer-bg.png"
              className="footer-bg"
              alt="footer-bg"
            />
            <img
              src="/images/place-bid-modal/footer-shades.png"
              className="footer-shades"
              alt="footer-shades"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
