import React from "react";

export default function ProductDetails({ product }) {
  return (
    <>
      <h2
        style={{ fontSize: 20, fontFamily: "SuisseIntlMedium" }}
        className="productDetails"
      >
        Product Details
      </h2>
      <div className="row d-flex flex-row mb-2">
        <div className="col-6">Style</div>
        <div className="col-6 text-right">
          <span>
            <b>{product?.sku_number ? product?.sku_number : "---"}</b>
          </span>
        </div>
      </div>
      <div className="row d-flex flex-row mb-2">
        <div className="col-6">Colorway</div>
        <div className="col-6 text-right">
          <span>
            <b>{product?.colorway ? product?.colorway : "---"}</b>
          </span>
        </div>
      </div>
      <div className="row d-flex flex-row mb-2">
        <div className="col-6">Retail Price</div>
        <div className="col-6 text-right">
          <span>
            <b>{product?.price ? product?.price : "---"}</b>
          </span>
        </div>
      </div>
      <div className="row d-flex flex-row mb-2">
        <div className="col-6">Release Date</div>
        <div className="col-6 text-right">
          <span>
            <b>{product?.release_date ? product?.release_date : "---"}</b>
          </span>
        </div>
      </div>
    </>
  );
}
