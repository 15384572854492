import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { AccountSettingHeader } from "../account-settings-header/account-settings-header.component";
import { getPayout } from "../../utils/api";
import * as Actions from "../../Redux/Actions";

import "./payout-info.styles.scss";

const PayoutInfo = ({ user, updateUser }) => {
  const [payoutData, setPayoutData] = useState({});

  useEffect(() => {
    getPayout(user.id)
      .then((response) => {
        const { name, nickName, IBAN, paypalEmail } = response;

        setPayoutData(response);

        updateUser({
          BankAccountName: name,
          BankAccountNick: nickName,
          BankAccountIBAN: IBAN,
          paypalEmail: paypalEmail,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [updateUser, user.id]);

  var check = true;
  return (
    <div className="payout-info-section">
      <AccountSettingHeader
        headerTitle="Payout Information"
        pageRoute="payoutInfo"
      />
      <div className="payout-info-content">
        {payoutData.paypalEmail !== undefined &&
        payoutData.paypalEmail !== "" &&
        payoutData.payoutMode === "paypal" ? (
          <span>
            {(check = false)}
            PayPal: {payoutData.paypalEmail}
          </span>
        ) : null}
        {payoutData.name !== undefined &&
        payoutData.name !== "" &&
        payoutData.payoutMode === "bank" ? (
          <span>
            {(check = false)}
            Name: {payoutData.name}
          </span>
        ) : null}{" "}
        {payoutData.nickName !== undefined &&
        payoutData.nickName !== "" &&
        payoutData.payoutMode === "bank" ? (
          <span>
            {(check = false)}
            Nick: {payoutData.nickName}
          </span>
        ) : null}{" "}
        {payoutData.IBAN !== undefined &&
        payoutData.IBAN !== "" &&
        payoutData.payoutMode === "bank" ? (
          <span>
            {(check = false)}
            IBAN: {payoutData.IBAN}
          </span>
        ) : null}
        {check ? <p>You do not have any payout information on file</p> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => {
      dispatch({ type: Actions.UPDATE_USER, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutInfo);
