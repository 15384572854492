import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SellingCurrentItem from "../selling-current-item/selling-current-item.component";
import { getListingCurrent } from "../../utils/api";

import "./selling-current-table.styles.scss";

const SellingCurrentTable = ({ user, searchValue, appendPendingShoeData }) => {
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    if (user) {
      getListingCurrent(user.user_id)
        .then((response) => {
          setCurrentData(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [user]);

  return (
    <div className="selling-current-table-container">
      <div className="table-header">
        <p className="heading-title">Item</p>
        <p className="heading-title tier-two">Size</p>
        <p className="heading-title tier-two">Asking Price</p>
        <p className="heading-title">Highest Offer</p>
      </div>
      {/* {SHOE_DATA.map((shoe) => (
        <SellingCurrentItem key={shoe.id} shoe={shoe} />
      ))} */}

      {/* {searchValue === "" && currentData.length */}
      {searchValue === "" 
        ? currentData.map((shoe, ind) => (
            <SellingCurrentItem
              appendPendingShoeData={appendPendingShoeData}
              key={shoe.shoe_id}
              shoe={shoe}
              setCurrentData={setCurrentData}
            />
          ))
        : null}

      {searchValue !== "" &&
        currentData !== null &&
        currentData
          .filter((shoe) =>
            shoe.name
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase())
              ? shoe
              : null
          )
          .map((shoe) => (
            <SellingCurrentItem
              appendPendingShoeData={appendPendingShoeData}
              key={shoe.shoe_id}
              shoe={shoe}
              setCurrentData={setCurrentData}
            />
          ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellingCurrentTable);
