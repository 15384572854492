import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as Actions from "../../../Redux/Actions";

import "./BlogsHeaderSearch.scss";

function BlogsHeaderSearch({ keyword, setKeyword, setShowSearchMenu }) {
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setShowSearchMenu(keyword.trim().length > 0);
  }, [keyword]);

  useEffect(() => {
    if (!expand && keyword.trim().length > 0) {
      setKeyword("");
    }
  }, [expand, keyword]);

  return (
    <>
      <div className={`header__search ${expand ? "expanded" : ""}`}>
        <div className="form-element">
          <span className="form-element__field">
            <button
              type="button"
              className="header__action-search"
              disabled={expand}
              onClick={() => setExpand(true)}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
            <input
              type="text"
              id="search"
              name="search"
              placeholder="Search for articles, series...."
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <button
              type="button"
              className="header__action-exit"
              onClick={() => setExpand(false)}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </span>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    keyword: state.blogs.searchKeyword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setKeyword: (data) => {
      dispatch({ type: Actions.SET_BLOGS_SEARCH_KEYWORD, payload: data });
    },
    setShowSearchMenu: (data) => {
      dispatch({ type: Actions.SET_BLOGS_SEARCHMENU, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogsHeaderSearch);
