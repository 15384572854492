import { library } from "@fortawesome/fontawesome-svg-core";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowUp,
  faChartBar,
  faChevronDown,
  faChevronRight,
  faCircleNotch,
  faEdit,
  faHeart,
  faQuestionCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faSearch,
  faCircleNotch,
  faQuestionCircle,
  faChartBar,
  faChevronDown,
  faChevronRight,
  faHeart,
  faArrowUp,
  faTwitter,
  faEdit
);
