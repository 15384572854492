import React from "react";

import thrillerVerifiedIcon from "../../assets/product/thrillerVerifiedIcon.svg";
import startSellingAsab from "../../assets/product/startSellingAsab.svg";
import globalAccessIcon from "../../assets/product/globalAccessIcon.svg";

export default function ProductEndBoxes() {
  return (
    <>
      <div className="col-lg-6 col-md-12 mt-2">
        <div className="productEndBox">
          <div>
            <img src={thrillerVerifiedIcon} alt="Thriller Verified" />
          </div>
          <div className="pl-3">
            <div className="productEndBoxHeading">Thriller Verified</div>
            <div className="productEndBoxDescription mt-1">
              Thriller Verified is our own designation and means that we inspect
              every item, every time.
            </div>
            <div className="productEndBoxLearnMore mt-1">Learn More</div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 mt-2">
        <div className="productEndBox">
          <div>
            <img src={globalAccessIcon} alt="Global Access" />
          </div>
          <div className="pl-3">
            <div className="productEndBoxHeading">Global Access</div>
            <div className="productEndBoxDescription mt-1">
              Our millions of customers from over 200 countries allow you to
              easily secure those hard-to-find items.
            </div>
            <div className="productEndBoxLearnMore mt-1">Learn More</div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 mt-2">
        <div className="productEndBox">
          <div>
            <img src={startSellingAsab} alt="Start Selling ASAP" />
          </div>
          <div className="pl-3">
            <div className="productEndBoxHeading">Start Selling ASAP</div>
            <div className="productEndBoxDescription mt-1">
              You can start selling on Thriller in just a few clicks, no
              application process necessary.
            </div>
            <div className="productEndBoxLearnMore mt-1">Learn More</div>
          </div>
        </div>
      </div>
    </>
  );
}
