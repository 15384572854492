import React, { Fragment, useEffect, useState } from "react";
import "./shoe-size-list.styles.scss";
import { Row, Col } from "react-simple-flex-grid";
import "react-simple-flex-grid/lib/main.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import ShoeSize from "../shoe-size/shoe-size.component";
import SHOE_DATA from "../../temporary-data/shoe-data";
import axios from "axios";
import { BASE_URL } from "../../Constants/Global";

export const ShoeSizeList = ({
  shoe,
  displayShoeSize,
  toggleShoeDisplay,
  parentCallBack,
}) => {
  const [sizes, setSizes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSizes();
  }, []);

  const getSizes = async () => {
    const { data } = await axios.get(
      `${BASE_URL}shoes/shoeCost/${shoe.shoe_id}`
    );
    setSizes(data.data);
    setLoading(false);
  };

  const skeletonStyle = {
    width: 120,
    height: 35,
    margin: 10,
  };

  return (
    <Fragment>
      <div className="size-title">
        <h1>Select Size</h1>
        <p>U.S Men Sizes</p>
      </div>
      <Row>
        <Col offset={2} span={8}>
          <div className="shoe-size-container">
            {loading ? (
              <div className="row">
                <div className="col-6">
                  <Skeleton style={skeletonStyle} />
                </div>
                <div className="col-6">
                  <Skeleton style={skeletonStyle} />
                </div>
                <div className="col-6">
                  <Skeleton style={skeletonStyle} />
                </div>
                <div className="col-6">
                  <Skeleton style={skeletonStyle} />
                </div>
              </div>
            ) : (
              sizes.map((size, index) => (
                <ShoeSize
                  key={index}
                  shoe={shoe}
                  size={size}
                  displayShoeSize={displayShoeSize}
                  toggleShoeDisplay={toggleShoeDisplay}
                  parentCallBack={parentCallBack}
                />
              ))
            )}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
