import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import * as Actions from "../../../Redux/Actions";

import OffCanvas from "../../off-canvas/OffCanvas";

import "./BlogsHeaderOffCanvas.scss";

function BlogsHeaderOffCanvas({ show, hide, loading, categories, tags }) {
  return (
    <OffCanvas show={show} hide={hide} className="blogs-header-offcanvas">
      <div className="content container">
        <div className="row justify-content-center">
          <Link to="/blogs" className={`header__blog`}>
            <p className="header__blog-title">Thriller Digest</p>
            <p className="header__blog-description">
              Blogs and Editorials from the World of Thrillerme
            </p>
          </Link>
          <div className="col-md-8 col-sm-12">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <ul className="brands-menu">
                  {loading ? (
                    <LoadingList />
                  ) : (
                    categories.map((category) => (
                      <li key={category.id}>
                        <Link
                          to={`/blogs/${category.attributes.slug}`}
                          onClick={hide}
                          className="menu-link"
                        >
                          {category.attributes.title}
                          <div className="symbol-icons__list">
                            <p className="symbol-icons__item">+</p>
                            <p className="symbol-icons__item">+</p>
                            <p className="symbol-icons__item">+</p>
                          </div>
                        </Link>
                      </li>
                    ))
                  )}
                </ul>
              </div>
              <div className="col-6">
                <ul className="tags-menu">
                  {loading ? (
                    <LoadingList height={27} />
                  ) : (
                    <>
                      {tags.map((tag) => (
                        <li key={tag.id}>
                          <Link
                            className="menu-link"
                            to={`/blogs/${tag.attributes.slug}`}
                            onClick={hide}
                          >
                            {tag.attributes.title}
                          </Link>
                        </li>
                      ))}

                      <li>
                        <Link
                          className="menu-link"
                          to={"/collections/0/"}
                          onClick={hide}
                        >
                          Shop All
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <p className="all-right-reserved">
          ©2024 Thrillerme. All Rights Reserved.
        </p>
      </div>
    </OffCanvas>
  );
}

const mapStateToProps = (state) => {
  return {
    show: state.blogs.showSideMenu,
    loading: state.blogs.loadingCategories,
    categories: state.blogs.categories.slice(0, 5),
    tags: state.blogs.categories.slice(5, 9),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hide: () => {
      dispatch({ type: Actions.SET_BLOGS_SIDEMENU, payload: false });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BlogsHeaderOffCanvas));

function LoadingList({ height = 40 }) {
  return [1, 2, 3, 4, 5].map((item) => (
    <li key={item}>
      <Link to="#" className="menu-link">
        <img
          src="/images/loading_image.svg"
          width="100%"
          height={height}
          style={{ opacity: 0.25 }}
        />
      </Link>
    </li>
  ));
}
