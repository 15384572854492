import React from "react";
import ContentLoader from "react-content-loader";

export default function BlogSectionLoader({
  width = 640,
  height = 480,
  duration = 1,
  ...props
}) {
  return (
    <ContentLoader
      speed={duration}
      width={width}
      height={height}
      viewBox="0 0 640 480"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="380" y="180" rx="3" ry="3" width="213" height="6" />
      <rect x="30" y="120" rx="0" ry="0" width="327" height="300" />
      <rect x="380" y="95" rx="3" ry="3" width="172" height="30" />
      <rect x="379" y="130" rx="3" ry="3" width="93" height="30" />
      <rect x="380" y="195" rx="3" ry="3" width="213" height="6" />
      <rect x="380" y="225" rx="3" ry="3" width="112" height="6" />
      <rect x="380" y="210" rx="3" ry="3" width="213" height="6" />
    </ContentLoader>
  );
}
