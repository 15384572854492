import React from "react";
import "./NewCustomRecentcs.scss";

import cardImg6 from "../../temporary-data/6.png";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

function CustomRecentcs({ tag, brands, from, to }) {
  return (
    <>
      <div className="row flex-row">
        <div className="col-12 mt-5 mb-3">
          <h1 className="slidersHeading">{tag}</h1>
        </div>
      </div>
      <div className="row">
        <div className="productShowDivBrnadsWala">
          {brands?.slice(from, to).map((elem, index) => {
            return (
              <Link
                to={`/collections/${elem.title
                  .replace(/\s+/g, "-")
                  .toLowerCase()}/`}
                key={index}
              >
                <div className="CustomcardDiv2">
                  <img
                    className="cardImg"
                    style={{ height: "150px" }}
                    src={elem.imageURL ? elem.imageURL : cardImg6}
                  />
                  <div className="textCont">
                    <h6>{elem.title}</h6>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default CustomRecentcs;
