import React, { useState } from "react";
import "./signup.styles.scss";
import { Row, Col } from "react-simple-flex-grid";
import "react-simple-flex-grid/lib/main.css";
import { CustomButton } from "../custom-button/custome-button.component";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

import { SIGNUP, BASE_URL } from "../../Constants/Global";
import { withRouter } from "react-router";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useHistory,
} from "react-router-dom";
import {
  validateEmail,
  validatePassword,
  validateName,
} from "../../Constants/Functions";
import * as Actions from "../../Redux/Actions";
import { connect } from "react-redux";
import swal from "sweetalert";
import facebook from "../../assets/Home/facebook.jpg";
import insta from "../../assets/Home/insta.png";
import twitter from "../../assets/Home/twitter.png";
import {
  getPayout,
  getRegistrations,
  getSellers,
  registrations,
  signup,
} from "../../utils/api";

const Signup = ({ user, setUser }) => {
  let history = useHistory();
  let sellVar = useParams().sell;

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasShipping, setHasShipping] = useState(false);
  const [hasPayout, setHasPayout] = useState(false);

  const [userCredentials, setUserCredentials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [serverRequest, setServerRequest] = useState(false);
  const [fieldStatus, setFieldStatus] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserCredentials({ ...userCredentials, [name]: value });
  };

  const activateField = (event) => {
    const { name } = event.target;
    setFieldStatus({ ...fieldStatus, [name]: true });
  };

  const disableField = (event) => {
    const { name } = event.target;
    setFieldStatus({ ...fieldStatus, [name]: false });
  };

  const initialSetup = async () => {
    return Promise.all([
      getRegistrations(user.id)
        .then((response) => {
          if (response.isAuthenticated === 1) {
            setIsAuthenticated(true);
          }
        })
        .catch((error) => {
          console.error(error);
        }),

      getSellers(user.id)
        .then((response) => {
          if (response !== "") {
            setHasShipping(true);
          }
        })
        .catch((error) => {
          console.error(error);
        }),

      getPayout(user.id)
        .then((response) => {
          if (response !== "") {
            setHasPayout(true);
          }
        })
        .catch((error) => {
          console.error(error);
        }),
    ]);
  };

  const setupChecks = async () => {
    await initialSetup();

    getRegistrations(user.id)
      .then((response) => {
        if (!response.isAuthenticated) {
          history.push({
            pathname: "/twoFactorAuth",
            state: {
              hasShipping: hasShipping,
              hasPayout: hasPayout,
              historyShoe: true,
            },
          });
        } else if (!hasShipping) {
          history.push({
            pathname: "/shippingInfo/1",
            state: {
              hasPayout: hasPayout,
              historyShoe: true,
            },
          });
        } else if (!hasPayout) {
          history.push({
            pathname: "/payoutInfo",
            state: {
              historyShoe: true,
            },
          });
        } else {
          history.push("/sell");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const signUpUser = async () => {
    const { firstName, lastName, email, password } = userCredentials;

    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === ""
    ) {
      swal({
        title: "Error!",
        text: "Please Fill All the details",
        icon: "error",
      });
      return;
    }

    if (!validateEmail(email)) {
      swal({
        title: "Error!",
        text: "Please Enter the Correct Email Address",
        icon: "error",
      });
      return;
    }
    if (password.length < 6) {
      swal({
        title: "Error!",
        text: "Your Password Must have atleast six digits",
        icon: "error",
      });
      return;
    }
    if (!validateName(firstName)) {
      swal({
        title: "Error!",
        text: "Please Enter the Correct First Name",
        icon: "error",
      });
      return;
    }
    if (!validateName(lastName)) {
      swal({
        title: "Error!",
        text: "Please Enter the Correct Last Name",
        icon: "error",
      });
      return;
    }

    let data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      verification_code: 2128,
      isAuthenticated: 1,
      isApproved: 0,
      isSuspended: 0,
      suspendedTill: null,
      defaultSize: 4,
      userInfo: "",
      user_role: 2,
      business_name: "",
      contact: null,
      paypal: null,
      address: null,
    };

    setLoading(true);

    registrations(data)
      .then((response) => {
        const { message, status } = response;
        if (status == "error") {
          setLoading(false);
          swal({
            title: "Error!",
            text: response.message,
            icon: "error",
          });
          return;
        }

        // extracting user id from response message
        let user_id = parseInt(message.replace(/ /g, "").split("=")[1]);
        setUser({
          user_id,
          ...data,
        });

        // Send Email
        signup({
          emailType: "signup",
          emailTo: data.email,
        })
          .then((signupResponse) => {
            try {
              if (sellVar === "1") {
                setupChecks();
              } else {
                if (
                  history.location.state !== undefined &&
                  (history.location.state.historyProduct !== undefined ||
                    history.location.state.historyProduct !== null) &&
                  history.location.state.historyProduct
                ) {
                  let size = history.location.state.size;
                  let product = history.location.state.productId;

                  history.push(`/buy/${product}/${size}/0`);
                } else {
                  history.goBack();
                }
              }
            } catch (error) {
              console.error("Unable to set signin");
              setLoading(false);
            }
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error(error);
        alert("Something went Wrong");
        setLoading(false);
      });
  };

  return (
    <div className="signup-container">
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className="main-wrapper">
          <div className="form-container">
            <label
              className={
                fieldStatus.firstName || userCredentials.firstName
                  ? "float-label"
                  : null
              }
              htmlFor={1}
            >
              First Name
            </label>
            <input
              id={1}
              name="firstName"
              type="text"
              onChange={handleChange}
              onFocus={activateField}
              onBlur={disableField}
              value={userCredentials.firstName}
            />
          </div>
          <div className="form-container">
            <label
              className={
                fieldStatus.lastName || userCredentials.lastName
                  ? "float-label"
                  : null
              }
              htmlFor={2}
            >
              Last Name
            </label>
            <input
              id={2}
              name="lastName"
              type="text"
              onChange={handleChange}
              onFocus={activateField}
              onBlur={disableField}
              value={userCredentials.lastName}
            />
          </div>
          <div className="form-container">
            <label
              className={
                fieldStatus.email || userCredentials.email
                  ? "float-label"
                  : null
              }
              htmlFor={3}
            >
              Email Address
            </label>
            <input
              id={3}
              name="email"
              type="text"
              onChange={handleChange}
              onFocus={activateField}
              onBlur={disableField}
              value={userCredentials.email}
            />
          </div>
          <div className="form-container">
            <label
              className={
                fieldStatus.password || userCredentials.password
                  ? "float-label"
                  : null
              }
              htmlFor={4}
            >
              Password
            </label>
            <input
              id={4}
              name="password"
              type="password"
              onChange={handleChange}
              onFocus={activateField}
              onBlur={disableField}
              value={userCredentials.password}
            />
          </div>

          <CustomButton
            size="large"
            onClick={signUpUser}
            disabled={serverRequest}
          >
            Sign Up
          </CustomButton>
          <Row align="middle">
            <Col span={12}>
              <a href="https://www.facebook.com/ThrillerMiddleEast/?__tn__=-UC*F">
                {/* <i className="fab fa-facebook-square fa-2x mx-3 logos"></i> */}
                <img src={facebook} style={{ width: 40 }} />
              </a>
              <a href="https://twitter.com/THRILLER_ME">
                {/* <i className="fab fa-twitter fa-2x mx-3 logos"></i> */}
                <img src={twitter} style={{ width: 40 }} />
              </a>
              <a href="https://www.instagram.com/thrillerme/?hl=en">
                {/* <i className="fab fa-instagram-square fa-2x mx-3 logos"></i> */}
                <img src={insta} style={{ width: 33 }} />
              </a>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (data) => {
      dispatch({ type: Actions.SAVE_USER, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Signup));
