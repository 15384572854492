import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";

import * as Actions from "../../Redux/Actions";

import BlogsHeaderOffCanvas from "./blogs-header-off-canvas/BlogsHeaderOffCanvas";
import BlogsHeaderSearch from "./blogs-header-search/BlogsHeaderSearch";
import BlogsSearchResultOffCanvas from "./blogs-search-result-off-canvas/BlogsSearchResultOffCanvas";

import "./BlogsHeader.scss";

function BlogsHeader({ showMenu, setShowMenu, keyword, setKeyword }) {
  const [scrollOffset, setScrollOffset] = useState(0);

  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    const onScroll = () => setScrollOffset(window.scrollY);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    setShowResults(keyword.trim().length > 0);
  }, [keyword]);

  return (
    <>
      <header
        className={`blogs-header ${scrollOffset > 0 ? "shrink" : ""} ${
          showMenu && !showResults ? "offcanvas" : ""
        } ${showMenu && !showResults ? "dark" : ""}`}
      >
        <div className="header__container">
          <div className="header__content">
            <Link to="/" className="header__brand">
              <div className="header__brand-placeholder">
                <img
                  src="/images/logo.svg"
                  className="navbrand"
                  alt="Thrillerme"
                />
              </div>
            </Link>

            <Link to="/blogs" className={`header__blog`}>
              <p className="header__blog-title">Thriller Digest</p>
              <p className="header__blog-description">
                Blogs and Editorials from the World of Thrillerme
              </p>
            </Link>

            <div className="header__actions">
              <BlogsHeaderSearch />
              <button
                className="header__action-btn btn-nav"
                onClick={toggleMenu}
              >
                {showMenu ? (
                  <FontAwesomeIcon icon={faClose} fixedWidth />
                ) : (
                  <FontAwesomeIcon icon={faBars} fixedWidth />
                )}
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    showMenu: state.blogs.showSideMenu,
    keyword: state.blogs.searchKeyword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowMenu: (data) => {
      dispatch({ type: Actions.SET_BLOGS_SIDEMENU, payload: data });
    },
    setKeyword: (data) => {
      dispatch({ type: Actions.SET_BLOGS_SEARCH_KEYWORD, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogsHeader);
