import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import SettingItem from "../setting-item/setting-item.component";
import { getFav } from "../../utils/api";

import "./favourite-table.styles.scss";

const FavouriteTable = ({ user }) => {
  const [favData, setFavData] = useState([]);
  useEffect(() => {
    getFav(user.id)
      .then((response) => {
        setFavData(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return width;
  }

  var widthScreen = getWindowDimensions();

  return (
    <div className="favourite-table-container">
      <div className="table-header">
        {widthScreen <= 481 ? (
          <p className="heading-titlex">Item</p>
        ) : (
          <Fragment>
            <p className="heading-title">Item</p>
            <p className="heading-title tier-two">Size</p>
            <p className="heading-title">Market Value</p>
            <p className="heading-title tier-two">Lowest Ask</p>
            <p className="heading-title tier-two">Last Sale</p>
          </Fragment>
        )}
      </div>
      {favData.map((shoe) => (
        <SettingItem key={shoe.id} shoe={shoe} favourite={1} linkCheck={true} />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(FavouriteTable);
