import {
  ArrowRight,
  ArrowRightAlt,
  KeyboardArrowRight,
} from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import localStorage from "redux-persist/es/storage";
import { makingValidName } from "../../Constants/Functions";
import cardImg6 from "../../temporary-data/6.png";
import "./NewCustomRecentcs.scss";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import questionIcon from "../../assets/Home/questionIcon.svg";
import ProductCard from "../product-card/product-card";
import { connect } from "react-redux";
import * as Actions from "../../Redux/Actions";

export function QuestionSign({ toolTip }) {
  if (!toolTip) return null;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {toolTip}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
      transition={false}
    >
      <button style={{ background: "none", border: "none" }}>
        <img src={questionIcon} alt={"questionIcon"} />
      </button>
    </OverlayTrigger>
  );
}

const NewCustomRecentcs = ({
  title = "",
  type,
  tag,
  productData,
  toolTip,
  setSelectedSection,
}) => {
  const history = useHistory();

  const recentSlider = productData?.filter((iem) => {
    if (
      iem?.tag.toLowerCase().includes(tag.toLowerCase()) ||
      iem?.tag.toLowerCase().includes(title.toLowerCase())
    ) {
      return true;
    }
    return false;
  });

  const setLocalStorage = (attr) => {
    if (type == "tag") {
      history.push(`/collections/${makingValidName(tag)}`);
    } else {
      const collection_id = recentSlider.length
        ? recentSlider[0].collection_id
        : 0;

      history.push(`/collections/${collection_id}`);
    }
  };

  return (
    <>
      <div className="row flex-row">
        <div className="col-md-10 col-sm-12 mt-5 mb-3">
          <h1 className="slidersHeading">
            {title} {toolTip && <QuestionSign toolTip={toolTip} />}
          </h1>
        </div>
        <div className="col-md-2 col-sm-12 hideInMobile">
          <button onClick={() => setLocalStorage(title)} className="nextButton">
            <span className="mr-1">SEE ALL</span>
            <KeyboardArrowRight />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="productShowDiv">
          {recentSlider?.slice(0, 6).map((elem, index) => {
            const newname = makingValidName(`${elem.name}`);
            const newshoeid = makingValidName(`${elem.shoe_id}`);

            return (
              <ProductCard
                key={`shoe-id-${newshoeid}-${index}`}
                newname={newname}
                newshoeid={newshoeid}
                cover_image={elem.cover_image ? elem.cover_image : cardImg6}
                name={elem.name}
                lowestAsk={elem.lowestAsk}
                lastSold={elem.lastSold}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedSection: (data) => {
      dispatch({ type: Actions.SET_SELECTED_SECTION, payload: data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomRecentcs);
