import React, { useState } from "react";

export default function ProductDescription({ product }) {
  const [readMore, setReadMore] = useState(false);

  return (
    <>
      <span className="mb-3">
        <b>Product Description</b>
      </span>
      <span
        className={`text-justify ${
          !readMore ? "productDescriptionTextLimiter" : ""
        }`}
      >
        {product?.summary ? product?.summary : "---"}
      </span>

      {!readMore && (
        <span>
          <b
            style={{ color: "#01633F", cursor: "pointer", fontSize: 14 }}
            onClick={() => setReadMore(true)}
          >
            READ MORE
          </b>
        </span>
      )}
    </>
  );
}
