import * as Actions from "../Actions";

const initialState = {
  loading: true,
  products: [],
  categories: [],
  collectionData: {
    collection_id: 0,
    title: "Shop All",
    description: "",
    imageURL: "",
  },
  showFilter: true,
  showMobileFilter: false,
};

const BrowseReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.SET_SHOP_PRODUCTS:
      return {
        ...state,
        products: payload,
      };
    case Actions.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case Actions.SET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case Actions.SET_COLLECTION_DATA:
      return {
        ...state,
        collectionData: payload,
      };
    case Actions.SET_SHOW_FILTER:
      return {
        ...state,
        showFilter: payload,
        showMobileFilter: false,
      };
    case Actions.SET_SHOW_MOBILE_FILTER:
      return {
        ...state,
        showMobileFilter: payload,
        showFilter: false,
      };

    default:
      return state;
  }
};

export default BrowseReducer;
