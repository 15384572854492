import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import "./CategoryBar.scss";
import categories from "../../temporary-data/categories";
import axios from "axios";
import { BASE_URL } from "../../Constants/Global";

const CategoryBar = ({ allBrands = [], selectCategory }) => {
  const items = allBrands.map((collection) => ({
    href: `/collections/${collection.title
      .replace(/\s+/g, "-")
      .toLowerCase()}/`,
    label: collection.title,
  }));

  return (
    <ul className="nav-menu-items">
      <li className="navbar-toogle">
        <Link to="#" className="menu-bars"></Link>
      </li>
      {allBrands.map((item, index) => {
        return (
          <li
            style={{ border: "5px", borderColor: "black" }}
            key={index}
            onClick={() => {
              selectCategory(item);
            }}
          >
            <div className="nav-text">
              <span>
                <div className="hover">{item.title}</div>
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default withRouter(CategoryBar);
