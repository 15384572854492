import React from "react";
import { WHATSAPP_CONTACT } from "../../Constants/Global";
import aboutUsContactDMUS from "../../assets/aboutUs/aboutUsContactDMUS.svg";
import aboutUsContactEmail from "../../assets/aboutUs/aboutUsContactEmail.svg";
import aboutUsContactWhatsapp from "../../assets/aboutUs/aboutUsContactWhatsapp.svg";

export default function ContactCustomerCare() {
  return (
    <div
      className="row py-4 w-100 mx-auto overflow-hidden"
      style={{ background: "black", marginTop: 30 }}
    >
      <div className="col-md-9 col-sm-10 my-5 mx-auto">
        <h1 className="text-center mt-3 text-white footerHeading">
          Contact Customer Care
        </h1>
        <div className="row">
          <div className="col-md-2 mb-2"></div>
          <div className="col-md-8 col-sm-12">
            <div className="row pt-3 px-2">
              <div className="col-4 mb-2 px-1">
                <a
                  href="https://www.instagram.com/thrillerme/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="contactCardsAbout">
                    <img src={aboutUsContactDMUS} alt="Instagram" />
                    <h1 className="text-center text-white">DM US</h1>
                  </div>
                </a>
              </div>
              <div className="col-4 mb-2 px-1">
                <a
                  href={`https://wa.me/${WHATSAPP_CONTACT}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="contactCardsAbout">
                    <img src={aboutUsContactWhatsapp} alt="Whatsapp" />
                    <h3 className="text-center">WHATSAPP</h3>
                  </div>
                </a>
              </div>
              <div className="col-4 mb-2 px-1">
                <a
                  href="mailto:thefuture@thrillerme.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="contactCardsAbout p13">
                    <img src={aboutUsContactEmail} alt="Email" />
                    <h3 className="text-center">EMAIL</h3>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
