import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import "./pay.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import ApplePay from "./ApplePay";
import { BASE_URL } from "../../Constants/Global";
import { connect } from "react-redux";
import { postOrders } from "../../utils/api";

const Pay = ({ user, size, id, offer }) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const [err, setErr] = useState(true);

  let history = useHistory();

  useEffect(() => {
    if (succeeded) {
      AcceptOffer();
    }
  }, [succeeded]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    try {
      axios
        .post(`${BASE_URL}stripe`, {
          amount: Math.floor(parseFloat(offer.totalBill) * 100),
        })
        .then(function (response) {
          setErr(false);
          setClientSecret(response.data.clientSecret);
        });
    } catch (error) {
      setErr(true);
    }
  }, [offer.totalBill]);

  const AcceptOffer = () => {
    //show loader here
    var url = `${BASE_URL}listing/buynow/${id}/${size}`;
    //console.log(url);
    var shipping = 22.5;
    axios
      .get(`${BASE_URL}shippings/${user.user_id}`)
      .then((res) => {
        if (res.data.city !== undefined) {
          if (
            res.data.city !== "Dubai" &&
            res.data.city !== "دبي" &&
            res.data.city !== null
          ) {
            //console.log("update shipping");
            window.localStorage.setItem("shippingFee", 20);
            shipping = 20; //next_day
          } else {
            window.localStorage.setItem("shippingFee", 22.5);
            shipping = 22.5; //same_day
          }
        } else {
          window.localStorage.setItem("shippingFee", 22.5);
          shipping = 22.5; //same_day
        }

        axios
          .get(url)
          .then((res) => {
            var offer = res.data;
            var offerData = {
              productID: offer.shoe_id,
              size: offer.size,
              buyerID: user.user_id,
              sellerID: offer.seller_id,
              price: offer.totalBill,
              isAuthentic: 0,
              notes: null,
              status: "Pending",
              quiqupJobID: null,
              pickupState: null,
              dropOffstate: null,
              pickupTrackingURL: null,
              dropOffTrackingURL: null,
            };

            postOrders({
              offerData: offerData,
              listing_id: offer.listing_id,
              offer_id: 0,
              soldTo: user.user_id,
              shipping: shipping,
              vat: offer.processingFee,
              processing: offer.transactionFee,
              offerAmount: offer.askingPrice,
            }).then(
              (response) => {
                const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
                const options = {
                  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                  debug: true, // enable logs
                };
                ReactPixel.init("3098857153686189", advancedMatching, options);

                ReactPixel.pageView(); // For tracking page view
                ReactPixel.trackSingleCustom(
                  "3098857153686189",
                  "Purchase",
                  offerData
                ); // For tracking custom events.

                window.localStorage.setItem("history", "1");
                history.push("/buying-section");
              },
              (error) => {
                //console.log(error);
              }
            );
          })
          .catch((err) => {
            console.error("order error", err);
          });
      })
      .catch((err) => {
        console.error("shipping cost error", err);
      });
  };

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  return (
    <div>
      {!err && (
        <form id="payment-form" onSubmit={handleSubmit}>
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
          <button
            className="button"
            disabled={processing || disabled || succeeded}
            id="submit"
          >
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay now"
              )}
            </span>
          </button>
          {/* Show any error that happens when processing the payment */}
          {error && (
            <div className="card-error" role="alert">
              {error}
            </div>
          )}
          {/* Show a success message upon completion */}
          <p className={succeeded ? "result-message" : "result-message hidden"}>
            Payment succeeded! see the result in your
            <a href={`/buying`}> Buying section.</a>{" "}
          </p>
          <br />
          <ApplePay offer={offer} />
        </form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Pay);
