import React from "react";

export default function BlogIntroWrapper({ summary }) {
  return (
    <div className="intro-wrapper">
      <div className="layout-1__intro layout-1__intro--uiblock">
        <div className="container">
          <p className="headline headline--3">{summary}</p>
        </div>
      </div>
      <div className="layout-1__intro">
        <div className="container">
          <p className="headline headline--3">{summary}</p>
        </div>
      </div>
    </div>
  );
}
