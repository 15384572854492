import React, { useState, useEffect } from "react";
import { AccountSettingHeader } from "../account-settings-header/account-settings-header.component";
import { connect } from "react-redux";
import { getSellers } from "../../utils/api";

import "./seller-info.styles.scss";

const SellerInfo = ({ user }) => {
  const [sellersData, setSellersData] = useState({});

  useEffect(() => {
    if (!!user && "user_id" in user) {
      getSellers(user.user_id)
        .then((response) => {
          setSellersData(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [user]);

  return (
    <div className="selling-info-section">
      <AccountSettingHeader
        headerTitle="Seller Information"
        pageRoute="shippingInfo/1"
        toolTip={true}
      />
      <div className="selling-info-content">
        {sellersData === "" ? (
          <p>You do not have any sellers information saved.</p>
        ) : (
          <div>
            {sellersData.firstName} <br></br>
            {sellersData.lastName}
            {sellersData.email} <br></br>
            {sellersData.phone} <br></br>
            {sellersData.country} <br></br>
            {sellersData.city} <br></br>
            {sellersData.address} <br></br>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SellerInfo);
