import * as Actions from "./../Actions";

import banner3 from "../../assets/Home/banners/banner3.png";
import banner4NewYear from "../../assets/Home/banners/banner4NewYear.png";

const initialState = {
  banners: [],
  slideShowBanners: [],
  banner1: { imageURL: banner3 },
  banner2: { imageURL: banner4NewYear },
};

const BannerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === Actions.SET_BANNER) {
    let slideShowBanners = [];
    let banner1 = { imageURL: banner3 };
    let banner2 = { imageURL: banner4NewYear };

    if (payload.length >= 3) {
      slideShowBanners = payload[0].map((banner) => ({
        ...banner,
        buttonURL: banner.buttonURL.replace(/.*\/\/[^\/]*/, ""),
      }));
      banner1 = {
        ...payload[1][0],
        buttonURL: payload[1][0].buttonURL.replace(/.*\/\/[^\/]*/, ""),
      };
      banner2 = {
        ...payload[2][0],
        buttonURL: payload[2][0].buttonURL.replace(/.*\/\/[^\/]*/, ""),
      };
    }

    return {
      ...state,
      banners: payload,
      slideShowBanners,
      banner1,
      banner2,
    };
  }

  return state;
};

export default BannerReducer;
