import React, { useState, useEffect } from "react";
import "./favourites-modal.styles.scss";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import SearchResult from "../../components/search-result/search-result.component";
import { BASE_URL } from "../../Constants/Global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchItemComponent from "../search-item/search-item.component";
import { makingValidName } from "../../Constants/Functions";
import { connect } from "react-redux";

const FavouriteModal = ({
  allProducts,
  refreshComponent,
  modalStatus,
  setModalStatus,
  setRefreshCompenent,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchCollection, setSearchCollection] = useState([]);

  const [filterProducts, setFilterProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    getData();
  }, [searchValue]);

  const getData = async () => {
    const { data } = await axios.get(
      `${BASE_URL}shoes/getByName/${searchValue}`
    );
    setSearchCollection(data);
  };

  useEffect(() => {
    setItems(filterProducts.slice(0, 20));
  }, [filterProducts]);

  const handleChange = async (e) => {
    setSearchValue(e.target.value);

    var enterdValue = makingValidName(`${e.target.value}`);

    setFilterProducts(
      allProducts?.filter(
        (dat, index) =>
          makingValidName(`${dat.name}`)?.includes(enterdValue) ||
          makingValidName(`${dat.name}`) === enterdValue ||
          makingValidName(`${dat.colorway}`)?.includes(enterdValue) ||
          makingValidName(`${dat.colorway}`) === enterdValue ||
          makingValidName(`${dat.sku_number}`)?.includes(enterdValue) ||
          makingValidName(`${dat.sku_number}`) === enterdValue
      )
    );
  };

  const fetchMoreData = () => {
    if (items.length >= filterProducts.length) {
      setHasMore(false);
    }

    setTimeout(() => {
      setItems((prevItems) => [
        ...prevItems,
        ...filterProducts.slice(prevItems.length, prevItems.length + 20),
      ]);
    }, 500);
  };

  return (
    <>
      <Modal
        className="favModalComponentMain"
        show={modalStatus}
        onHide={() => setModalStatus(!modalStatus)}
        dialogClassName="modal-80w"
      >
        <Modal.Header className="modal-title">
          <Modal.Title>Favourite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="search-container">
            <input
              name="search"
              value={searchValue}
              onChange={handleChange}
              placeholder="Search name, order no."
            />
            <FontAwesomeIcon icon={["fa", "search"]} color="#3a4b3c" />
          </div>
          <div
            id="favSearchResultWrapper"
            style={{ maxHeight: "50vh", overflowY: "auto" }}
          >
            {searchValue.trim().length > 0 && (
              <InfiniteScroll
                dataLength={items.length}
                next={fetchMoreData}
                hasMore={hasMore}
                height={400}
                loader={
                  <div className="d-flex justify-content-center bg-white align-items-center py-4">
                    <FontAwesomeIcon icon={["fa", "circle-notch"]} spin />
                  </div>
                }
              >
                {items.map((item, index) => (
                  <SearchItemComponent
                    key={item.shoe_id}
                    shoe={item}
                    linkCheck={true}
                  />
                ))}
              </InfiniteScroll>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allProducts: state.products.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteModal);
