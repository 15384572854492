import React, { useState, useEffect } from "react";
import SizeBox from "../size-box-mobile/CustomSizeBox";
import CancelIcon from "@material-ui/icons/Cancel";

import "./SizeSelectorMobile.scss";

function CustomMobileSizeSelector({
  loading,
  sizes = [],
  closeSizeChart,
  selectSize,
}) {
  return (
    <div className="size-selector-mob">
      <div className="d-flex flex-row justify-content-between">
        <span style={{ fontSize: "19px", margin: "7px", fontWeight: "600" }}>
          Select A US Men's Size
        </span>
        <i
          onClick={closeSizeChart}
          style={{
            margin: "10px",
            marginBottom: "0px",
            marginTop: "5px",
            cursor: "pointer",
          }}
        >
          <CancelIcon />
        </i>
      </div>
      <div className="sizeboxes-container">
        {loading ? (
          "Loading..."
        ) : sizes.length > 0 ? (
          sizes.map((size, index) => {
            return (
              <SizeBox
                key={index}
                size={size}
                closeSizeChart={closeSizeChart}
                selectSize={selectSize}
              />
            );
          })
        ) : (
          <p>There are no sizes for this item.</p>
        )}
      </div>
      <div className="scroll-indicator"></div>
    </div>
  );
}

export default CustomMobileSizeSelector;
