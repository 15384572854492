import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import "./List.css";
import { makingValidName } from "../../Constants/Functions";
import { BASE_URL } from "../../Constants/Global";
const List = ({ title, collection_id }) => {
  const [items, setItems] = useState([]);
  const history = useHistory();

  // useEffect(() => {
  //   var url = BASE_URL + `shoes/footerItem/${collection_id}/7`;
  //   axios
  //     .get(url)
  //     .then((res) => {
  //       if (Array.isArray(res.data)) {
  //         setItems(res.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [collection_id]);

  useEffect(() => {
    setItems(collection_id);
  }, [collection_id]);

  return (
    <>
      <div className="h6">{title}</div>
      <ul className="listULs">
        {items.map((item, index) => {
          return (
            <li key={index}>
              <Link to={`/${item.url}`}>{item.name}</Link>
            </li>
          );
        })}
        {/* {items.map((item, index) => {
          const newname = makingValidName(`${item.name}`);
          const newshoeid = makingValidName(`${item.shoe_id}`);

          return (
            <li key={index}>
              <Link to={`/${newname}_id_${newshoeid}`}>{item.name}</Link>
            </li>
          );
        })} */}
      </ul>
    </>
  );
};

export default List;
