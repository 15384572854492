import React from "react";
import { Helmet } from "react-helmet";

export default function BlogCategoryHelmet({ meta, slug }) {
  const { title = "", description = "", keywords = "" } = meta;
  console.log("meta:", slug);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta
        property="og:url"
        content={`https://thrillerme.com/blogs/${slug}`}
      />
      <link rel="canonical" href="https://thrillerme.com/blogs" />
    </Helmet>
  );
}
