import React, { useEffect } from "react";
import "./Footer.css";
import { Row, Col } from "react-simple-flex-grid";
import "react-simple-flex-grid/lib/main.css";
import facebook from "../../assets/Home/facebook.svg";
import insta from "../../assets/Home/insta.svg";
import twitter from "../../assets/Home/twitter.svg";
import youtube from "../../assets/Home/youtube.svg";
import tiktok from "../../assets/Home/tiktok.svg";
import tabby from "../../assets/Home/tabby.svg";
import visa from "../../assets/Home/visa.svg";
import master from "../../assets/Home/mastercard.svg";
import apple from "../../assets/Home/icons8-apple-pay-50.png";

const Footer = () => {
  return (
    <footer className="footerMain">
      <Row
        justify="center"
        align="middle"
        className="centerr footer mt-3"
        style={{ background: "white" }}
      >
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="footerLogos">
          <a href="https://www.facebook.com/ThrillerMiddleEast/?__tn__=-UC*F">
            <img src={facebook} style={{ width: 27, marginRight: 12 }} alt="" />
          </a>
          <a href="https://www.youtube.com/channel/UCt_7I2MuOndimxSYdJW-Trw/videos">
            <img src={youtube} style={{ width: 27, marginRight: 12 }} alt="" />
          </a>
          <a href="https://www.instagram.com/thrillerme/?hl=en">
            <img src={insta} style={{ width: 27, marginRight: 12 }} alt="" />
          </a>
          <a href="https://twitter.com/THRILLER_ME">
            <img src={twitter} style={{ width: 27, marginRight: 12 }} alt="" />
          </a>
          <a href="https://www.tiktok.com/@thrillermiddleeast">
            <img src={tiktok} style={{ width: 27 }} alt="" />
          </a>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="d-flex flex-column flex-md-row align-items-center justify-content-md-between px-3 mt-md-2 mt-4"
        >
          <div className="order-1 order-md-0 mt-md-0 mt-3">
            &copy; {new Date().getFullYear()} Thriller. All Rights Reserved
          </div>
          <div>
            <img src={visa} style={{ width: 45, marginRight: 12 }} alt="" />
            <img src={master} style={{ width: 45, marginRight: 12 }} alt="" />
            <img src={apple} style={{ marginRight: 12 }} alt="" />
            <img src={tabby} style={{ width: 60 }} alt="" />
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
